import { Button, Form, Popconfirm, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineClose } from 'react-icons/ai';
import { getSortOrder, groupsOptions, oneAlert, renderDate } from '../../helpers/utils';

import { api } from '../../services/api';
import { SelectInput } from '../../components/LateralFilter/SelectInput';
import { useModal } from '../../components/Modal/useModal';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { RoutesPath } from '../../routes/routesPath';

const initialParams = {
  order: '',
  orderBy: '',
  page: 0,
  limit: 25,
  name: '',
  email: '',
};

export function useUsers() {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isNewItem, setIsNewItem] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [visibleModalUser, openModalUser, closeModalUser] = useModal();
  const [userEdit, setUserEdit] = useState(null);

  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const fetchUsers = async () => {
    setIsLoading(true);

    try {
      const { data: dataApi } = await api.get(
        `${import.meta.env.VITE_API_COGNITO}${RoutesPath.users}`
      );
      setData(dataApi);
    } catch (error) {
      console.log('Error on the fetch Users', error);
    }
    setIsLoading(false);
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const save = async (id) => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const row = await form.getFieldValue();

      if (isNewItem) {
        const response = await api.post('users', { ...row });
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(`users/${id}`, { ...row });
        if (response.status !== 200) throw Error();
      }

      await fetchUsers();
      setEditingKey('');
      setIsNewItem(false);

      oneAlert('success', t('toast.successOnSave'));
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      name: '',
      email: '',
      phone: '',
      group: '',
      enabled: true,
    };

    setIsNewItem(true);
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
  };

  const edit = (record) => {
    setUserEdit(record);
    openModalUser();
  };

  const changeUserActiveStatus = async (record) => {
    setIsLoading(true);

    try {
      const status = record.enabled !== 'true';

      const payload = {
        id: record.id,
        name: record.name,
        email: record.email,
        password: record.password,
        phone: record.phone,
        group: record.group.id,
        enabled: status,
      };

      const response = await api.put(
        `${import.meta.env.VITE_API_COGNITO}${RoutesPath.users}/${record.id}`,
        payload
      );

      if (response.status !== 200) throw Error();

      await fetchUsers();
      oneAlert('info', t('toast.successOnUserStatus'));
    } catch (error) {
      oneAlert('error', t('toast.errorOnUserStatus'));
    }

    setIsLoading(false);
  };

  const isEditing = (record) => {
    return record.id === editingKey;
  };

  const cancel = () => {
    if (isNewItem) {
      const newData = data.filter((item) => item.id !== editingKey);
      setData(newData);
      setIsNewItem(false);
    }
    setEditingKey('');
  };

  const searchFilter = async (newFilters) => {
    setParams({
      ...initialParams,
      ...newFilters,
    });
  };

  const clearFilter = async () => {
    setParams({
      ...initialParams,
      name: null,
      email: null,
      phone: null,
      group: null,
    });
  };

  const handleCloseFilteredTag = (field, idx) => {
    let newValue;

    if (Array.isArray(params[field])) {
      newValue = [...params[field]];
      newValue.splice(idx, 1);
    } else {
      newValue = field === 'group' ? null : '';
    }

    setParams({
      ...params,
      [field]: newValue,
      page: 0,
    });
  };

  const columns = [
    {
      title: t('pages.user.fields.name'),
      label: t('pages.user.fields.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      editable: true,
    },
    {
      title: t('pages.user.fields.email'),
      label: t('pages.user.fields.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: false,
      editable: true,
    },
    {
      title: t('pages.user.fields.phone'),
      label: t('pages.user.fields.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: false,
      editable: true,
    },
    {
      title: t('pages.user.fields.group'),
      label: t('pages.user.fields.group'),
      dataIndex: ['group', 'name'],
      key: 'name',
      sorter: false,
      editable: true,
    },
    {
      title: t('pages.user.fields.status'),
      label: t('pages.user.fields.status'),
      dataIndex: 'enabled',
      key: 'enabled',
      sorter: false,
      editable: true,
      render: (value) => {
        return value === 'true' ? t('pages.user.fields.enabled') : t('pages.user.fields.disabled');
      },
    },
    {
      title: t('pages.user.fields.created'),
      label: t('pages.user.fields.created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: false,
      editable: true,
      render: (value) => renderDate(value, '2-digit'),
    },
    {
      title: t('pages.user.fields.updated'),
      label: t('pages.user.fields.updated'),
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      sorter: false,
      editable: true,
      render: (value) => renderDate(value, '2-digit'),
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 130,
      render: (_, record) => {
        const editable = isEditing(record);

        const ActivateOrDeactivateMessage =
          record.enabled === 'true' ? t('common.deactivateMessage') : t('common.activateMessage');

        const ActivateOrDeactivateAction = record.enabled === 'true' ? 'disable' : 'enable';

        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                data-cy="edit"
              >
                {t('common.edit')}
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={ActivateOrDeactivateMessage}
                onConfirm={() => changeUserActiveStatus(record)}
              >
                <Button type="link" disabled={editingKey !== ''} data-cy="enable-disable">
                  {t(`common.${ActivateOrDeactivateAction}`)} {t(`common.user`)}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={() => save(record.id)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        required: true,
        newInputNode: col.dataIndex === 'group' && (
          <SelectInput
            placeholder={t('pages.user.fields.group')}
            allowClear
            options={groupsOptions()}
          />
        ),
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable && isEditing(record),
        editing: isEditing(record),
        limit: null,
      }),
    };
  });

  return {
    data,
    params,
    pageControl,
    fetchUsers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    columns,
    mergedColumns,
    visibleModalUser,
    openModalUser,
    closeModalUser,
    userEdit,
    setUserEdit,
  };
}

/* eslint-disable no-undefined */
import { Col, Dropdown, Form, Row } from 'antd';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownload, BiPlus } from 'react-icons/bi';
import { DownOutlined } from '@ant-design/icons';
import { ActionButton } from '../../components/ActionButton';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { useForecasts } from './useForecasts';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import ForecastsFilter from './ForecastsFilter';
import { CsvExport } from '../../components/Common/CsvExport';
import * as SC from '../../styles/common';
import { Loading } from '../../components/Loading';
import ProjectsSelect from '../../components/forms/ProjectsSelect';
import { transformDataForecast, getURI } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledSelect from '../../components/Common/StyledSelect';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import { UpdateDate } from '../../components/Common/UpdateDate';
import { StyledPagination } from '../../components/Common/StyledPagination';
import { Container } from './styles';

export function ForecastsPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.demands.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchScenarios,
    updateProject,
    updateProduct,
    scenariosOptions,
    selectedScenario,
    fetchForecast,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    onValuesChange,
    searchFilter,
    clearFilter,
    canUpdate,
    handleCloseFilteredTag,
    isBaseScenario,
    csvHeaders,
    mergedColumns,
  } = useForecasts(dataModal);

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';

  const showTagsFilter = params?.header_sku;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchForecast();
    }
  }, [params]);

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          icon={<BiPlus />}
          disabled={isNewItem || isBaseScenario || isLoading}
          onClick={handleAddItem}
          buttonText={`${t('common.add')} ${t('common.item')}`}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
          <CsvExport
            url={getURI(`scenarios/${params.scenario}/forecasts`, params)}
            filename={['demands', selectedScenario?.label]}
            limit={pageControl.totalElements}
            headers={csvHeaders}
            transformData={transformDataForecast}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        {view ? null : (
          <ForecastsFilter
            title={t('pages.demands.title')}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Container className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledHeader
                  title={t('pages.demands.title')}
                  subtitle={scenarioName}
                  items={pageControl.totalElements > 0 ? pageControl.totalElements : undefined}
                  actions={
                    <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                      <StyledButton variant="primary">
                        {t('common.action')}
                        <DownOutlined />
                      </StyledButton>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <Row style={{ gap: '1rem' }}>
                  <StyledSelect
                    style={{ width: '7rem' }}
                    label={t('filter.product')}
                    placeholder={t('filter.product')}
                    value={params?.product}
                    options={[
                      { label: 'Show All', value: '' },
                      { label: 'Desktop', value: 'Desktop' },
                      { label: 'Notebook', value: 'Notebook' },
                    ]}
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProduct(value)}
                  />
                  <ProjectsSelect
                    value={params?.project}
                    // valueAsId
                    intable="intable"
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProject(value)}
                  />
                </Row>
                <Col span={18} className="col-filters">
                  <SC.ContainerFilteredTags>
                    {params.product && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('product')}>
                        <div className="tag-title">{t('filter.product')}</div>
                        <div className="tag-value">{params.product}</div>
                      </SC.TagFilter>
                    )}
                    {params.project && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('project')}>
                        <div className="tag-title">{t('filter.project')}</div>
                        <div className="tag-value">{params.project}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_sku && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_sku')}>
                        <div className="tag-title">{t('pages.demands.fields.sku')}</div>
                        <div className="tag-value">{params.header_sku}</div>
                      </SC.TagFilter>
                    )}
                  </SC.ContainerFilteredTags>
                </Col>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <UpdateDate date={modifiedDate} />
                <Form
                  form={form}
                  component={false}
                  onValuesChange={onValuesChange}
                  validateMessages={validateMessages}
                >
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    size="small"
                    striped
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                    columns={mergedColumns}
                    dataSource={data}
                    pagination={false}
                    onChange={handleChangeTable}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePageValue}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </Container>
        )}
      </Row>
    </main>
  );
}

import { UserOutlined } from '@ant-design/icons';
import { Col, Flex, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { useBidSupplierView } from './useBidSupplierView';

import CardCountDown from '../../components/Common/CardCountdown';
import StyledBidCardList from '../../components/Common/StyledBidCardList';
import StyledButton from '../../components/Common/StyledButton';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledSelect from '../../components/Common/StyledSelect';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function BidSupplierView() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.rfq.title'));
  const {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    GetSupplierUserName,
    FetchStatus,
    getBidId,
    FetchData,
    setIdBid,
  } = useBidSupplierView();
  const history = useHistory();

  useEffect(() => {
    getBidId();
  }, []);

  useEffect(() => {
    FetchData();
    FetchStatus();
  }, [idBid]);
  return (
    <>
      <Flex justify="space-between" align="center">
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SpanTile>{t('pages.bidSupplierRFQ.Title')}</S.SpanTile>
            <S.BidComponents>| {bidData.rfq}</S.BidComponents>
          </Row>
        </Col>
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{GetSupplierUserName()?.supplier}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{GetSupplierUserName()?.user}</span>
            </S.UserContainer>
          </Row>
        </Col>
      </Flex>
      <Flex justify="flex-end" align="center">
        <StyledSelect
          label="BID"
          options={bidOptions}
          value={idBid || ''}
          onChange={(value: string) => setIdBid(value)}
          styles={{ width: '17.5rem' }}
        />
      </Flex>
      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <S.StepsContainer noBorder>
          <S.StepsComponent items={steps} />
        </S.StepsContainer>
      )}

      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <S.ContentContainer>
          <S.ContentLeftContainer>
            <S.HeaderContentLeft>
              <S.TitleCard>{t('pages.bidSupplierRFQ.itemsTitle')}</S.TitleCard>
              <StyledButton
                variant="primary"
                disabled={steps.find((item) => item.title === 'Round Open')?.status !== 'finish'}
                onClick={() => {
                  history.push(`/bid-response?bidId=${idBid}`);
                }}
              >
                {t('pages.bidSupplierRFQ.editBid')}
              </StyledButton>
            </S.HeaderContentLeft>
            <S.CardsBidContainer>{StyledBidCardList(bidData.itemsQuotation)}</S.CardsBidContainer>
          </S.ContentLeftContainer>
          <S.ContentRightContainer>
            <S.HeaderContentRight>
              <S.TitleCard>{t('pages.bidSupplierRFQ.roundDetails')}</S.TitleCard>
            </S.HeaderContentRight>
            <S.RoundContenteContainer>
              <Flex vertical>
                <S.PotentialLabel>{t('pages.bidSupplierRFQ.potentialRevenue')}</S.PotentialLabel>
                <S.PotentialValue>$ {bidData.potentialRevenue}</S.PotentialValue>
              </Flex>
            </S.RoundContenteContainer>
            <S.RoundContenteContainer>
              {bidData.respondDate && (
                <CardCountDown
                  showTime
                  endDate={bidData.respondDate}
                  stop={steps.find((item) => item.title === 'Round Open')?.status !== 'finish'}
                />
              )}
            </S.RoundContenteContainer>
          </S.ContentRightContainer>
        </S.ContentContainer>
      )}
    </>
  );
}

/* eslint-disable no-undefined */
import { Divider, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { CompressedComponent } from '../components/CompressedComponents';
import * as S from './styles';
import { useStep4 } from './useStep4';
import StyledSelect from '../../../../components/Common/StyledSelect';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import StyledButton from '../../../../components/Common/StyledButton';
import { IStep4 } from './types';
import { DeleteCategoryModal } from '../../DeleteCategoryModal';
import { StyledLoading } from '../../../../components/Common/StyledLoading';

export function Step4({ nextStep, onCancel, previousStep, bidId }: IStep4) {
  const { t } = useTranslation();
  const {
    columns,
    setSupplierOpen,
    supplierOpen,
    suppliersInformations,
    deletePartNumber,
    setOpenDeleteModal,
    openDeleteModal,
    saveSummary,
    openDeleteSupplierModal,
    setOpenDeleteSupplierModal,
    deleteSupplier,
    handleChangeTable,
    isLoading,
    isLoadingTable,
    tableInformations,
    categories,
    specs,
    handleFilter,
    params,
    clearFilters,
  } = useStep4(bidId);

  const renderSupplierInformation = () => {
    return (
      <>
        <S.Content>
          <Flex gap={16}>
            <S.SummaryContent span={20}>
              <Row style={{ marginBottom: '0.75rem' }} align="middle">
                <S.LeftLabel>{t('pages.newRfq.steps.fourthStep.fields.categories')}:</S.LeftLabel>
                <S.LeftValue>
                  <b>
                    {supplierOpen?.categories.map((category, index) => {
                      return (
                        <>
                          {category}{' '}
                          {index < supplierOpen.categories.length - 1 && (
                            <Divider type="vertical" />
                          )}
                        </>
                      );
                    })}
                  </b>
                </S.LeftValue>
              </Row>
              <Row style={{ flexWrap: 'nowrap' }} align="top">
                <S.LeftLabel>
                  {t('pages.newRfq.steps.fourthStep.fields.specification')}:
                </S.LeftLabel>
                <S.LeftValue>
                  {supplierOpen?.specs.map((spec, index) => {
                    return (
                      <>
                        {spec}{' '}
                        {index < supplierOpen.specs.length - 1 && <Divider type="vertical" />}
                      </>
                    );
                  })}
                </S.LeftValue>
              </Row>
            </S.SummaryContent>
            <S.SummaryContent>
              <S.RightLabel>{t('pages.newRfq.steps.fourthStep.fields.components')}: </S.RightLabel>
              <S.RightLabel>
                <b>{supplierOpen?.componentes}</b>
              </S.RightLabel>
              <S.RightLabel>{t('pages.newRfq.steps.fourthStep.fields.totalDemand')}</S.RightLabel>
              <S.RightLabel>
                <b> {Number(supplierOpen?.totalDemand).toLocaleString(t('common.localeMoney'))}</b>
              </S.RightLabel>
            </S.SummaryContent>
            </Flex>
          <Row style={{ gap: '1.5rem', flexWrap: 'nowrap', margin: '1.875rem 0' }}>
            <StyledSelect
              onChange={(value: string) => {
                handleFilter('category', value);
              }}
              label={t('pages.newRfq.steps.fourthStep.fields.selectCategory')}
              placeholder={t('pages.newRfq.steps.fourthStep.fields.selectCategoryPlaceholder')}
              showSearch
              styles={{ width: '10rem' }}
              suffixIcon={<SearchOutlined />}
              options={categories}
              value={params.category}
              allowClear
            />
            <StyledSelect
              onChange={(value: string) => {
                handleFilter('specification', value);
              }}
              label={t('pages.newRfq.steps.fourthStep.fields.selectSpecification')}
              placeholder={t('pages.newRfq.steps.fourthStep.fields.selectSpecificationPlaceholder')}
              showSearch
              styles={{ width: '10rem' }}
              suffixIcon={<SearchOutlined />}
              options={specs}
              value={params.specification}
              allowClear
            />
          </Row>
          <SimpleTable
            dataSource={tableInformations}
            isLoading={isLoadingTable}
            columns={columns}
            rowKey="project"
            scroll={{ x: 'max-content' }}
            pagination={false}
            striped={false}
            onChange={handleChangeTable}
          />
        </S.Content>
        <S.Footer justify="end">
          <StyledButton variant="primary" onClick={saveSummary}>
            {t('common.save')}
          </StyledButton>
        </S.Footer>
      </>
    );
  };

  return (
    <>
      <DeleteCategoryModal
        isOpen={openDeleteSupplierModal.isModalOpen || openDeleteModal.isModalOpen}
        onDelete={() => {
          if (openDeleteSupplierModal.isModalOpen && openDeleteSupplierModal.supplier) {
            deleteSupplier();

            setOpenDeleteSupplierModal({
              isModalOpen: false,
              supplier: undefined,
            });
            setSupplierOpen(undefined);
          } else if (openDeleteModal.isModalOpen && openDeleteModal.componentInformation) {
            deletePartNumber();

            setOpenDeleteModal({
              isModalOpen: false,
              componentInformation: undefined,
            });
          }
        }}
        onCloseModal={() => {
          setOpenDeleteSupplierModal({
            isModalOpen: false,
            supplier: undefined,
          });
          setOpenDeleteModal({
            isModalOpen: false,
            componentInformation: undefined,
          });
        }}
        message={
          openDeleteSupplierModal.isModalOpen
            ? t('pages.newRfq.steps.fourthStep.removeSupplierMessage')
            : t('pages.newRfq.steps.fourthStep.removePartNumberMessage')
        }
        loading={isLoading}
      />
      {isLoading ? (
        <StyledLoading height={20} />
      ) : (
        <S.Container>
          {suppliersInformations?.map((supplier) => {
            return (
              <CompressedComponent
                step="4"
                components={supplier.componentes}
                totalDemand={Number(supplier.totalDemand)}
                key={supplier.supplier}
                status={supplier.reviewStatus ? 'OK' : 'WARNING'}
                isOpen={supplier.supplier === supplierOpen?.supplier}
                onEdit={() => {
                  setSupplierOpen(supplier);
                  clearFilters();
                }}
                onDelete={() =>
                  setOpenDeleteSupplierModal({ isModalOpen: true, supplier: supplier.supplier })
                }
                onClose={() => setSupplierOpen(undefined)}
                title={supplier.supplier}
              >
                {renderSupplierInformation()}
              </CompressedComponent>
            );
          })}
        </S.Container>
      )}
      <S.Footer justify="space-between">
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <Row style={{ gap: '1rem' }}>
          <StyledButton
            variant="secondary"
            onClick={() => {
              previousStep();
            }}
          >
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              nextStep();
            }}
            htmlType="submit"
            disabled={
              suppliersInformations.some((supplier) => supplier.reviewStatus !== true) || isLoading
            }
          >
            {t('common.next')}
          </StyledButton>
        </Row>
      </S.Footer>
    </>
  );
}

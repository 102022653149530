import { Form, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { EyeOutlined, WarningOutlined } from '@ant-design/icons';
import { getSortOrder, debounce, oneAlert, getURIWithFalse } from '../../helpers/utils';
import { api } from '../../services/api';
import * as SC from '../../styles/common';
import * as S from './styles';
import { AVLCItem, AVLCParams, AVLCExport } from './types';
import { ActionButton } from '../../components/ActionButton';
import StyledIndicationTag from '../../components/IndicationTag';
import { QStatusField } from './styles';
import theme from '../../styles/theme';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { formatDataToSelect } from '../../helpers/formatDataToSelect';

export function useAVLC(dataModal: { projectName: string } | null = null) {
  const initialParams: AVLCParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 10,
    spec: null,
    partType: null,
    partNumber: null,
    projectName: null,
    cm: 'Foxconn',
    location: null,
    vendor: null,
    qStatus: null,
    hasComponent: null,
  };

  const CMs = [
    { value: 'Compal', label: 'Compal' },
    { value: 'Foxconn', label: 'Foxconn' },
  ];

  const products = [
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  //Modal must replace all others params
  if (dataModal && dataModal?.projectName) {
    initialParams.projectName = dataModal.projectName;
  }

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState<AVLCItem[]>([]);

  const [modalData, setModalData] = useState<AVLCItem | null>(null);
  const [componentModalData, setComponentModalData] = useState<AVLCItem | null>(null);

  const [params, setParams] = useState(initialParams);
  const [spec, setSpec] = useState<string>('');
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[] | null>(null);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [lastDate, setLastDate] = useState(new Date().toDateString());
  const [projects, setProjects] = useState<StyledSelectOptions[]>([]);
  const [categories, setCategories] = useState<StyledSelectOptions[]>([]);
  const [specs, setSpecs] = useState<StyledSelectOptions[]>([]);
  const [partNumbers, setPartNumbers] = useState<StyledSelectOptions[]>([]);
  const [sources, setSources] = useState<StyledSelectOptions[]>([]);
  const [manufacturers, setManufacturers] = useState<StyledSelectOptions[]>([]);
  const [status, setStatus] = useState<StyledSelectOptions[]>([]);

  function getQueryParams(qParams = params) {
    const getParams = { ...qParams };

    if (!qParams.spec?.length) {
      getParams.spec = null;
    } else {
      getParams.spec = qParams.spec;
    }

    if (!qParams.partType?.length) getParams.partType = null;
    if (!qParams.partNumber?.length) getParams.partNumber = null;
    if (qParams.hasComponent === true) getParams.hasComponent = false;

    return getParams;
  }

  const getLastProcessDate = async () => {
    const { data: lastData } = await api.get('/arquivo/lastfile/avlc');

    if (lastData.modifiedDate) setLastDate(lastData.modifiedDate);
  };

  const fetchProjects = async () => {
    try {
      const { data: dataProjects, status: statusProject } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'projects',
        })
      );
      if (statusProject === 200) {
        const convertedProjects = formatDataToSelect(dataProjects, true);

        setProjects(convertedProjects);
        return;
      }
      setProjects([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchCategories = async () => {
    try {
      const { data: dataCategories, status: statusCategory } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'category',
        })
      );
      if (statusCategory === 200) {
        const convertedCategories = formatDataToSelect(dataCategories, true);

        setCategories(convertedCategories);
        return;
      }
      setCategories([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchSpecs = async () => {
    try {
      const { data: dataSpecs, status: statusSpec } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'specification',
        })
      );
      if (statusSpec === 200) {
        const convertedSpecs = formatDataToSelect(dataSpecs, true);

        setSpecs(convertedSpecs);
        return;
      }
      setSpecs([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchPartNumbers = async () => {
    try {
      const { data: dataPartNumbers, status: statusPartNumber } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'partNumber',
        })
      );
      if (statusPartNumber === 200) {
        const convertedPartNumbers = formatDataToSelect(dataPartNumbers, true);

        setPartNumbers(convertedPartNumbers);
        return;
      }
      setPartNumbers([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchSource = async () => {
    try {
      const { data: dataManufacturers, status: statusManufacturers } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'source',
        })
      );
      if (statusManufacturers === 200) {
        const convertedSources = formatDataToSelect(dataManufacturers, true);

        setSources(convertedSources);
        return;
      }
      setManufacturers([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchManufacturers = async () => {
    try {
      const { data: dataManufacturers, status: statusManufacturers } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'manufacturer',
        })
      );
      if (statusManufacturers === 200) {
        const convertedManufacturers = formatDataToSelect(dataManufacturers, true);

        setManufacturers(convertedManufacturers);
        return;
      }
      setManufacturers([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchStatus = async () => {
    try {
      const { data: dataStatus, status: statusQStatus } = await api.get(
        getURIWithFalse('/avlc/filter', {
          filterToReturn: 'qStatus',
        })
      );
      if (statusQStatus === 200) {
        const convertedStatus = formatDataToSelect(dataStatus, true);

        setStatus(convertedStatus);
        return;
      }
      setStatus([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const fetchAVLC = async () => {
    if (params) {
      //change filter params

      const getParams = getQueryParams();
      try {
        if (!isLoading) setIsLoading(true);
        const {
          data: { content, totalElements, totalPages },
        } = await api.get(getURIWithFalse('avlc', { ...getParams }));

        const dataContent: AVLCItem[] = content?.length ? content : [];
        setData(dataContent);

        if (dataContent?.length) {
          setExpandedRowKeys([dataContent[0].id]);
          await getLastProcessDate();
        }

        setPageControl({
          pageLabel: `${params.page + 1}`,
          totalPages,
          totalElements,
        });

        setIsLoading(false);
      } catch (error) {
        setData([]);
        setPageControl({
          pageLabel: '1',
          totalPages: 0,
          totalElements: 0,
        });

        setIsLoading(false);
      }
    }
  };

  const saveComponent = async () => {
    setIsLoading(true);
    try {
      const row = await form.getFieldValue();

      const body = {
        spec: row.type,
        partType: row.partType,
        partNumber: row.partNumber,
        description: row.description,
        local: row.location === 'FOB' ? '1' : '0',
        subComponent: row.subComponent,
        cm: row.cm,
        product: row.product,
      };

      let response;

      if (!form.getFieldValue('hasComponent')) {
        response = await api.post('components', body);
      } else {
        const id = `${row.componentId}$${row.partType}$${row.type}$${row.partNumber}`;
        response = await api.put(`components/${id}`, body);
      }
      if (response.status !== 201) throw Error();

      await fetchAVLC();
      oneAlert('success', t('toast.successOnSave'));
      setComponentModalData(null);
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const updateProject = (value: string) => {
    localStorage.setItem('project', value);
    setParams({ ...params, projectName: value, page: 0 });
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: `${page}` });
  };

  const handleChangeTable = (_: unknown, __: unknown, sorter: any) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const debouncedOnCell = useMemo(
    () =>
      debounce((fn: () => void) => {
        fn();
      }, 0),
    []
  );

  const handleParams = (type: keyof AVLCParams, value: AVLCParams[typeof type]) => {
    setParams((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const onModalClose = () => {
    setModalData(null);
    setComponentModalData(null);
  };

  const columns = [
    {
      title: t('pages.avlc.fields.projectName'),
      label: t('pages.avlc.fields.projectName'),
      dataIndex: 'projectName',
      key: 'projectName',
      editable: false,
      required: true,
      render: (value: string) => {
        return {
          props: { colSpan: 1 },
          children: <div>{value}</div>,
        };
      },
    },
    {
      title: t('pages.avlc.fields.partType'),
      label: t('pages.avlc.fields.partType'),
      dataIndex: 'partType',
      key: 'partType',
      editable: false,
    },
    {
      title: t('pages.avlc.fields.spec'),
      label: t('pages.avlc.fields.spec'),
      dataIndex: 'type',
      key: 'type',
      editable: true,
      required: true,
      render: (value: string | number) => {
        return (
          value && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip placement="left" title={value}>
                <SC.TextElipsed>{value}</SC.TextElipsed>
              </Tooltip>
            </div>
          )
        );
      },
    },
    {
      title: t('pages.avlc.fields.partNumber'),
      label: t('pages.avlc.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      editable: false,
      align: 'center',

      render: (_: number, record: AVLCItem) => {
        if (record.cm === 'Foxconn') {
          return (
            <QStatusField>
              <ActionButton
                style={{ marginLeft: record.hasComponent ? '1.5rem' : 0 }}
                icon={
                  !record.hasComponent && (
                    <Tooltip title={t('pages.avlc.fields.partNumberTooltip')}>
                      <WarningOutlined
                        style={{ fontSize: '1rem', color: theme.colorsDesignSystem.red_secondary }}
                      />
                    </Tooltip>
                  )
                }
                buttonText={record.partNumber}
                onClick={() => {
                  setComponentModalData(record);
                  form.setFieldsValue({
                    ...record,
                    cm: record.cm ? record.cm : 'Foxconn',
                    product: record.product ? record.product : 'Notebook',
                  });
                }}
              />
            </QStatusField>
          );
        }
        return <span>{record.partNumber}</span>;
      },
    },
    {
      title: t('pages.avlc.fields.location'),
      label: t('pages.avlc.fields.location'),
      dataIndex: 'location',
      key: 'location',
      editable: false,
      align: 'center',
      width: 50,
      render: (value: string) => {
        return <StyledIndicationTag text={value || ''} />;
      },
    },
    {
      title: t('pages.avlc.fields.vendor'),
      label: t('pages.avlc.fields.vendor'),
      dataIndex: 'vendor',
      key: 'vendor',
      editable: false,
    },
    {
      title: t('pages.avlc.fields.qStatus'),
      label: t('pages.avlc.fields.qStatus'),
      dataIndex: 'qStatus',
      key: 'qStatus',
      width: 110,
      align: 'center',
      editable: false,
      render: (value: string) => {
        return <StyledIndicationTag text={value} />;
      },
    },
    {
      title: t('common.action'),
      label: t('common.action'),
      key: 'action',
      width: 70,
      align: 'center',
      render: (_: unknown, record: AVLCItem) => {
        return (
          <S.ActionEye>
            <ActionButton
              icon={<EyeOutlined />}
              onClick={() => {
                setModalData(record as AVLCItem);
              }}
            />
          </S.ActionEye>
        );
      },
    },
  ];

  const headerCsv = [
    { label: t('pages.avlc.fields.projectName'), key: 'projectName' },
    { label: t('pages.avlc.fields.partNumber'), key: 'partNumber' },
    { label: t('pages.avlc.fields.registeredComponent'), key: 'hasComponent' },
    { label: t('pages.avlc.fields.description'), key: 'description' },
    { label: t('pages.avlc.fields.partType'), key: 'partType' },
    { label: t('pages.avlc.fields.spec'), key: 'type' },
    { label: t('pages.avlc.fields.qStatus'), key: 'qStatus' },
    { label: t('pages.avlc.fields.family'), key: 'family' },
    { label: t('pages.avlc.fields.modelNumber'), key: 'modelNumber' },
    { label: t('pages.avlc.fields.odm'), key: 'odm' },
    { label: t('pages.avlc.fields.odmSample'), key: 'odmReceiveDate' },
    { label: t('pages.avlc.fields.odmActual'), key: 'odmActualReceiveDate' },
    { label: t('pages.avlc.fields.location'), key: 'location' },
    { label: t('pages.avlc.fields.vendor'), key: 'vendor' },
    { label: t('pages.avlc.fields.priority'), key: 'priority' },
    { label: t('pages.avlc.fields.avlcType'), key: 'avlctype' },
    { label: t('pages.avlc.fields.purpose'), key: 'partPurpose' },
    { label: t('pages.avlc.fields.remark'), key: 'remark' },
    { label: t('pages.avlc.fields.lock'), key: 'lock' },
    { label: t('pages.avlc.fields.firstPlanDate'), key: 'firstPlanDate' },
    { label: t('pages.avlc.fields.rdPlanFinishDate'), key: 'rdPlanFinishDate' },
    { label: t('pages.avlc.fields.rdActualFinishDate'), key: 'rdFinishDate' },
    { label: t('pages.avlc.fields.adjustDate'), key: 'adjustPlanFinishDate' },
    { label: t('pages.avlc.fields.uploadTime'), key: 'uploadTime' },
    { label: t('pages.avlc.fields.uploadUser'), key: 'uploadUser' },
  ];

  const transformData = (dataSource: AVLCItem[]) => {
    const rows: AVLCExport[] = [];
    dataSource.forEach((itens) => {
      rows.push({
        projectName: itens.projectName,
        partType: itens.partType,
        type: itens.type,
        partNumber: itens.partNumber,
        hasComponent: itens.hasComponent ? 'TRUE' : 'FALSE',
        qStatus: itens.qStatus,
        family: itens.family,
        description: itens.description,
        modelNumber: itens.modelNumber,
        odm: itens.odm,
        odmReceiveDate: itens.odmReceiveDate,
        odmActualReceiveDate: itens.odmActualReceiveDate,
        location: itens.location,
        vendor: itens.vendor,
        priority: itens.priority,
        avlcType: itens.avlcType,
        partPurpose: itens.partPurpose,
        remark: itens.remark,
        lock: itens.lock,
        firstPlanDate: itens.firstPlanDate,
        adjustPlanFinishDate: itens.adjustPlanFinishDate,
        rdPlanFinishDate: itens.rdPlanFinishDate,
        rdFinishDate: itens.rdFinishDate,
        uploadTime: itens.uploadTime,
        uploadUser: itens.uploadUser,
      });
    });
    return rows;
  };

  const mergedColumns: ColumnsType<object> = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: AVLCItem) => {
        return {
          record,
          editable: col.editable,
          inputType: col.dataIndex === 'amount' ? 'number' : 'text',
          limit: col.dataIndex === 'description' ? '255' : '100',
          required: false,
          dataIndex: col.dataIndex,
          title: col.title,
        };
      },
    } as object;
  });

  useEffect(() => {
    fetchAVLC();
  }, [params.page, params.orderBy, params.order, params.limit]);

  useEffect(() => {
    Promise.all([
      fetchProjects(),
      fetchCategories(),
      fetchSpecs(),
      fetchPartNumbers(),
      fetchManufacturers(),
      fetchStatus(),
      fetchSource(),
      fetchAVLC(),
    ]);
  }, []);

  return {
    updateProject,
    fetchAVLC,
    handleChangeTable,
    debouncedOnCell,
    setExpandedRowKeys,
    setParams,
    getQueryParams,
    onModalClose,
    saveComponent,
    clearAllFilters,
    handleParams,
    transformData,
    mergedColumns,
    headerCsv,
    data,
    params,
    pageControl,
    isLoading,
    form,
    expandedRowKeys,
    modalData,
    lastDate,
    componentModalData,
    projects,
    categories,
    specs,
    partNumbers,
    manufacturers,
    status,
    sources,
    CMs,
    products,
    changePage,
    spec,
    setSpec,
  };
}

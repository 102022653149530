import { Row, message as antMessage } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

import theme from '../styles/theme';
import { StyledSelectOptions } from '../components/Common/StyledSelect/types';

interface IOneAlert {
  type: 'success' | 'error' | 'info' | 'loading';
  message: string;
}

export function oneAlert({ type, message }: IOneAlert) {
  if (type === 'success') {
    return antMessage.success(message);
  }
  if (type === 'error') {
    return antMessage.error(message);
  }
  if (type === 'info') {
    return antMessage.info(message);
  }
  if (type === 'loading') {
    return antMessage.open({
      content: (
        <Row>
          <SyncOutlined
            style={{ color: theme.colors.blue, fontSize: '0.875rem', marginRight: '0.875rem' }}
          />
          <span>{message}</span>
        </Row>
      ),
    });
  }
  antMessage.error('NO_ALERT_TYPE');
}

export const handleFormatValueFields = (
  number: string | number,
  minFractional = 2,
  maxFactional = 2
): string => {
  try {
    if (number) {
      return Number(number).toLocaleString(i18n.t('common.localeMoney'), {
        minimumFractionDigits: minFractional,
        maximumFractionDigits: maxFactional,
      });
    }
    return '-';
  } catch (error) {
    console.log(error);
    return '-';
  }
};

export const handleFormatDate = (date: string) => {
  try {
    if (dayjs(date).isValid()) {
      return dayjs(date).format('MM/DD/YYYY');
    }
    return '-';
  } catch {
    return '-';
  }
};

export const removeDiplicated = (suppliers: string[]) => {
  const removeDuplicated = suppliers.filter((current, i) => suppliers.indexOf(current) === i);
  return removeDuplicated;
};

export const replaceLineBreaks = (str: string) => {
  try {
    return str.replace(/\n/g, '\n');
  } catch (error) {
    console.log(error);
    return str;
  }
};

export const getUserNameAndEmail = (IdToken: string | null) => {
  if (!IdToken) return;

  const UserToken: any = jwtDecode(IdToken);

  return {
    name: UserToken.name,
    email: UserToken.email,
  };
};

export const addClassification = (position: string) => {
  const lastCaracter = position.slice(-1);
  const lastTwoCaracter = Number(position.slice(-2));

  if (lastTwoCaracter >= 11 && lastTwoCaracter <= 13) {
    return `${position}th`;
  }

  switch (lastCaracter) {
    case '1':
      return `${position}st`;
    case '2':
      return `${position}nd`;
    case '3':
      return `${position}rd`;
    default:
      return `${position}th`;
  }
};

export const formatDataToSelect = (data: string[], hasEmptyValue: boolean) => {
  if (data.length > 0) {
    const formattedData: StyledSelectOptions[] = data.map((item) => {
      return {
        label: item,
        value: item,
      };
    });

    if (hasEmptyValue) {
      formattedData.unshift({ label: i18n.t('common.showAll'), value: '' });
    }

    return formattedData;
  }
  return [];
};

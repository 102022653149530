import { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import { EditableCellProps } from './types';

export const EditableTableCellRFQ: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  maxWidth,
  messageRequired,
  required,
  typeRule,
  maxLength,
  onExit,
  ...restProps
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      (inputRef.current as any).focus();
    }
  }, [editing]);

  const inputNode = (
    <Input
      ref={inputRef}
      style={{ maxWidth }}
      maxLength={maxLength}
      onBlur={(event) => onExit && onExit(record, event.target.value)}
    />
  );

  function getRule(): any {
    switch (inputType) {
      case 'numberWithZero':
        return {
          pattern: /^[0-9]*$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'numberWithoutZero':
        return {
          pattern: /^(0*[1-9]\d*)$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'number':
        return {
          pattern: /^[1-9][0-9]*$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'money':
        return {
          pattern: /^[0-9]{1,8}[.]*[0-9]{0,5}$/,
          required,
          message: `${messageRequired}   ${title}`,
        };

      case 'stringOnly':
        return {
          type: 'string',
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'stringAndSpecial':
        return {
          type: 'string',
          message: `${messageRequired}   ${title}`,
          pattern: /.+/,
          required,
        };
      default:
        return {
          pattern: /[\w]+[\s\w]?$/,
          type: 'string',
          message: `${messageRequired}   ${title}`,
          required,
        };
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[getRule()]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

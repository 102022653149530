import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.75rem;
  gap: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.shape};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

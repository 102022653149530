import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Spin } from 'antd';
import { Row } from 'antd/lib';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Formatter } from 'antd/es/slider';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import StyledSelect from '../../../components/Common/StyledSelect';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import * as S from './styles';
import { dataType } from './types';
import theme from '../../../styles/theme';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { CustomTooltip } from '../../../components/Common/RechartsTooltips/FinanceDashboardTableTooltip';
import { useProcurementDashboard } from './useProcurementDashboard';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { Loading } from '../../../components/Loading';
import PriceTableChart from './ChartPriceTableForMonth';
import UnitPriceTotalInvoiceChart from './ChartUnitPriceTotalInvoice';
import SupplierPricesCostTrend from './ChartSuppliersPricesCostTrend';
import { capitalizeFirstLetter } from '../../../helpers/utils';
import { StyledHeader } from '../../../components/Common/StyledHeaderPage';

export const ProcurementDashboardPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.finance.dashboard.titleProcurement'));
  const {
    financeLevels,
    columns,
    bodyData,
    isLoading,
    params,
    optionsProjectName,
    optionsSku,
    selectedData,
    CMs,
    products,
    isTableLoading,
    totalUSD,
    FILTER_VIEW_ALL_SLIDER,
    avgForecastForMonth,
    negotiatedExecuted,
    optionsComponentGroup,
    optionsSpec,
    optionsPartNumber,
    optionsSupplier,
    optionsMonth,
    sources,
    unitPriceData,
    totalInvoiceData,
    tooltipDataUnitPrice,
    tooltipDataTotalInvoice,
    months,
    isLoadingChart,
    calculatePercentage,
    selectedTotalIsEmptyOrZero,
    updateParams,
    handleChangeTable,
    totalCheckedUSD,
    fetchBySlider,
    formatDateForCard,
    setShowClearTag,
    handleClearTag,
    setSelectedData,
    fetchContent,
    sliderTooltipFormater,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    colorsGenerator,
    calculateAvgForecastPerMonthAndSkus,
    handleFetchHeaders,
    handleFetchHeadersNegotiatedExecuted,
    fetchtNegotiatedExecutedContent,
    handleRenderClearAllFiltersChart,
  } = useProcurementDashboard();

  // useEffect(() => {
  //   fetchContent();
  //   // form.setFieldValue('date', dayjs(new Date()));
  //   // form.setFieldValue('level', 'L10');
  //   // form.setFieldValue('product', 'Notebook');
  // }, []);

  useEffect(() => {
    params.topBy !== FILTER_VIEW_ALL_SLIDER ? fetchBySlider() : fetchContent();
  }, [params.topBy]);

  useEffect(() => {
    handleFetchHeaders();
  }, [params.cm, params.projectName, params.sku]);

  useEffect(() => {
    handleFetchHeadersNegotiatedExecuted();
    fetchtNegotiatedExecutedContent();
  }, [
    params.cm,
    params.projectName,
    params.sku,
    params.level,
    params.componentGroup,
    params.spec,
    params.partNumber,
    params.source,
    params.supplier,
    params.month,
  ]);

  useEffect(() => {
    updateParams('month', dayjs(params.date).format('MMM').toUpperCase());
  }, [params.date]);

  const COLORS = colorsGenerator(bodyData.length);

  return (
    <>
      <StyledHeader title={t('pages.finance.dashboard.titleProcurement')} />

      {isLoading ? (
        <Loading />
      ) : (
        <S.BodyContainer>
          <S.FiltersContainer>
            <StyledSelect
              styles={{ minWidth: '6.25rem' }}
              options={CMs}
              value={params.cm}
              label={t('pages.finance.dashboard.labelCM')}
              placeholder={t('pages.finance.dashboard.placeholderCM')}
              onChange={(value: string) => {
                updateParams('cm', value);
              }}
            />
            <StyledSelect
              styles={{ minWidth: '7.5rem' }}
              options={products}
              value={products[0]}
              disable
              label={t('pages.finance.dashboard.labelProduct')}
              placeholder={t('pages.finance.dashboard.placeholderProduct')}
              onChange={() => {
                console.log('empty');
              }}
            />

            <StyledDatePicker
              styles={{ minWidth: '5.813rem' }}
              picker="month"
              mode="month"
              format="MM/YYYY"
              defaultValue={params.date}
              allowClear={false}
              onChange={(date, _) => {
                updateParams('date', date);
              }}
              label={t('pages.finance.dashboard.labelPeriod')}
            />

            <StyledSelect
              styles={{ minWidth: '8.625rem' }}
              placeholder={t('pages.finance.dashboard.table.placeholders.projectName')}
              label={`${t('pages.finance.dashboard.table.labels.projectName')}`}
              showSearch
              options={optionsProjectName}
              value={params.projectName}
              onChange={(value: string) => {
                updateParams('projectName', value);
              }}
              suffixIcon={
                <S.SearchButton>
                  <SearchOutlined className="icon" />
                </S.SearchButton>
              }
            />

            <StyledSelect
              styles={{ minWidth: '9.875rem' }}
              placeholder={t('pages.finance.dashboard.table.placeholders.sku')}
              label="SKU"
              showSearch
              value={params.sku}
              options={optionsSku}
              onChange={(value: string) => {
                updateParams('sku', value);
              }}
              suffixIcon={
                <S.SearchButton>
                  <SearchOutlined className="icon" />
                </S.SearchButton>
              }
            />

            <StyledSelect
              styles={{ minWidth: '4rem' }}
              options={financeLevels}
              value={params.level}
              onChange={(value: string) => {
                updateParams('level', value);
              }}
              label={t('pages.finance.dashboard.labelLevel')}
              placeholder={t('pages.finance.dashboard.placeholderLevel')}
            />

            <div>
              <Checkbox
                checked={params.withForecast}
                style={{ padding: '0.375rem' }}
                onChange={() => {
                  updateParams('withForecast', !params.withForecast);
                }}
              >
                {t('pages.quotes.labels.withDemand')}
              </Checkbox>
            </div>

            {/* {showClearTag && (
              <div style={{ marginBottom: '1.5rem' }}>
                <StyledClearTag onClick={handleClearTag} />
              </div>
            )} */}

            <S.ApplyButton
              variant="primary"
              text={t('pages.finance.dashboard.applyButton')}
              onClick={() => {
                setShowClearTag(true);
                fetchContent();
              }}
            />
          </S.FiltersContainer>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.Card>
            <S.CardHeader>
              <strong>{t('pages.finance.dashboard.titleCards.firstCard')}</strong> |{' '}
              <S.BlueText>{params.projectName ? params.projectName : 'All Projects'} </S.BlueText>|{' '}
              <S.BlueText>{formatDateForCard()}</S.BlueText>
            </S.CardHeader>
            <Row style={{ justifyContent: 'space-evenly' }}>
              <Col span={50} className="grafic">
                <ResponsiveContainer width="100%" height={410}>
                  <PieChart>
                    {selectedTotalIsEmptyOrZero() && <Tooltip content={<CustomTooltip />} />}
                    <Pie
                      dataKey="total"
                      innerRadius={95}
                      outerRadius={130}
                      cx="50%"
                      cy="58%"
                      data={
                        selectedTotalIsEmptyOrZero()
                          ? (calculatePercentage(selectedData) as any)
                          : [{ total: 1 }]
                      }
                    >
                      <Label
                        position="center"
                        value={totalCheckedUSD()}
                        style={{
                          color: `${theme.colorsDesignSystem.text}`,
                          fontWeight: 700,
                          fontSize: '1.5rem',
                        }}
                      />
                      {selectedTotalIsEmptyOrZero() ? (
                        selectedData.map((entry, index) => (
                          <Cell key={`cell-${entry.sku}`} fill={COLORS[index % COLORS.length]} />
                        ))
                      ) : (
                        <Cell key="cell-1" fill="#F1F2F4" />
                      )}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <p style={{ textAlign: 'center' }}>Total USD</p>
              </Col>

              <S.CardLateralDiv>
                <S.CustomSlider
                  onChange={(value) => {
                    updateParams('topBy', value);
                  }}
                  value={params.topBy}
                  min={0}
                  max={4}
                  tooltip={{
                    open: true,
                    getPopupContainer: (trigger) => {
                      return trigger;
                    },
                    formatter: sliderTooltipFormater as Formatter,
                  }}
                />

                <SimpleTable
                  dataSource={bodyData}
                  rowKey="id"
                  onChange={handleChangeTable}
                  pagination={false}
                  columns={columns}
                  scroll={{ y: 350 }}
                  isLoading={isTableLoading}
                  rowSelection={{
                    setSelectedData,
                    defaultSelectedRowKeys: selectedData.map((row) => row.id),
                    selectedRowKeys: selectedData.map((row) => row.id),
                    selectedData,
                    onChange: (selectedRowKeys: number, selectedRows: Array<dataType>) => {
                      const formatedRows: any = selectedRows.map((row: dataType) => {
                        return {
                          ...row,
                          total: Number(row.total),
                        };
                      });
                      setSelectedData(formatedRows);
                      calculateAvgForecastPerMonthAndSkus(formatedRows);
                    },
                  }}
                />
              </S.CardLateralDiv>
            </Row>
          </S.Card>
          <S.SecondCard>
            <S.CardHeader>
              <strong>{t('pages.finance.dashboard.titleCards.secondCard')}</strong> |{' '}
              <S.BlueText>{params.projectName ? params.projectName : 'All Projects'} </S.BlueText>|{' '}
              <S.BlueText>{formatDateForCard()}</S.BlueText>
            </S.CardHeader>
            <PriceTableChart data={avgForecastForMonth} />
          </S.SecondCard>
          <S.ThirdCard>
            <S.CardHeader>
              <S.TitleCadsWithFilter>
                <S.TitleCards>
                  <strong>{t('pages.finance.dashboard.titleCards.thirdCard')}</strong> |{' '}
                  <S.BlueText>
                    {params.projectName ? params.projectName : 'All Projects'}{' '}
                  </S.BlueText>
                  |{' '}
                  <S.BlueText>
                    {capitalizeFirstLetter(params.month)} {dayjs(params.date).format('YY')}
                  </S.BlueText>
                </S.TitleCards>
                <S.CardsFiltersContainer>
                  <StyledSelect
                    styles={{ minWidth: '8.625rem', textAlign: 'left' }}
                    placeholder={t('pages.procurement.chart.placeHolder.componentGroup')}
                    showSearch
                    options={optionsComponentGroup}
                    value={params.componentGroup || null}
                    onChange={(value: string) => {
                      updateParams('componentGroup', value);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    styles={{ minWidth: '9.188rem', textAlign: 'left' }}
                    placeholder={t('pages.procurement.chart.placeHolder.spec')}
                    showSearch
                    options={optionsSpec}
                    value={params.spec || null}
                    onChange={(value: string) => {
                      updateParams('spec', value);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    styles={{ minWidth: '8.438rem', textAlign: 'left' }}
                    placeholder={t('pages.procurement.chart.placeHolder.partNumber')}
                    showSearch
                    options={optionsPartNumber}
                    value={params.partNumber || null}
                    onChange={(value: string) => {
                      updateParams('partNumber', value);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    styles={{ minWidth: '5.938rem', textAlign: 'left' }}
                    placeholder={t('pages.procurement.chart.placeHolder.source')}
                    options={sources}
                    value={params.source || null}
                    onChange={(value: string) => {
                      updateParams('source', value);
                    }}
                  />
                  <StyledSelect
                    styles={{ minWidth: '6.813rem', textAlign: 'left' }}
                    placeholder={t('pages.procurement.chart.placeHolder.supplier')}
                    showSearch
                    options={optionsSupplier}
                    value={params.supplier || null}
                    onChange={(value: string) => {
                      updateParams('supplier', value);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    styles={{ minWidth: '6.25rem', textAlign: 'left' }}
                    options={months}
                    value={params.month}
                    placeholder={t('pages.procurement.chart.placeHolder.month')}
                    onChange={(value: string) => {
                      updateParams('month', value);
                    }}
                  />
                </S.CardsFiltersContainer>
              </S.TitleCadsWithFilter>
            </S.CardHeader>
            <S.CardsFiltersTagContainer>
              {handleRenderClearAllFiltersChart()}
            </S.CardsFiltersTagContainer>
            <S.BodyGraphContainer>
              {isLoadingChart ? (
                <S.SpinContainer>
                  <Spin />
                </S.SpinContainer>
              ) : (
                <>
                  <S.GraphContainer>
                    <S.TitleGraphContainer>
                      <S.TitleGraph>
                        {t('pages.procurement.chart.chartTitle.unitPrice')}
                      </S.TitleGraph>
                      <S.TitleGraph>|</S.TitleGraph>
                      <S.TitleGraph>USD</S.TitleGraph>
                    </S.TitleGraphContainer>
                    <S.SubTitleGraphContainer>
                      <S.SubTitleGraph>{negotiatedExecuted?.componentGroup}</S.SubTitleGraph>
                      {negotiatedExecuted?.supplier && <S.SubTitleGraph>|</S.SubTitleGraph>}
                      <S.SubTitleGraph>{negotiatedExecuted?.supplier}</S.SubTitleGraph>
                      {negotiatedExecuted?.spec && <S.SubTitleGraph>|</S.SubTitleGraph>}
                      <S.SubTitleGraph>{negotiatedExecuted?.spec}</S.SubTitleGraph>
                    </S.SubTitleGraphContainer>
                    <S.AverageIndication>
                      {negotiatedExecuted?.average && '*Average'}
                    </S.AverageIndication>
                    <UnitPriceTotalInvoiceChart
                      dataChart={unitPriceData}
                      tooltipData={tooltipDataUnitPrice}
                    />
                  </S.GraphContainer>
                  <S.GraphContainer>
                    <S.TitleGraphContainer>
                      <S.TitleGraph>
                        {t('pages.procurement.chart.chartTitle.totalInvoice')}
                      </S.TitleGraph>
                      <S.TitleGraph>|</S.TitleGraph>
                      <S.TitleGraph>USD</S.TitleGraph>
                    </S.TitleGraphContainer>
                    <S.SubTitleGraphContainer>
                      <S.SubTitleGraph>{negotiatedExecuted?.componentGroup}</S.SubTitleGraph>
                      {negotiatedExecuted?.supplier && <S.SubTitleGraph>|</S.SubTitleGraph>}
                      <S.SubTitleGraph>{negotiatedExecuted?.supplier}</S.SubTitleGraph>
                      {negotiatedExecuted?.spec && <S.SubTitleGraph>|</S.SubTitleGraph>}
                      <S.SubTitleGraph>{negotiatedExecuted?.spec}</S.SubTitleGraph>
                    </S.SubTitleGraphContainer>
                    <S.AverageIndication />
                    <UnitPriceTotalInvoiceChart
                      dataChart={totalInvoiceData}
                      tooltipData={tooltipDataTotalInvoice}
                      legend
                    />
                  </S.GraphContainer>
                </>
              )}
            </S.BodyGraphContainer>
            <SupplierPricesCostTrend
              project={params.projectName || ''}
              isLoading={isLoading}
              level={params.level}
              date={params.date.format('MM/YYYY')}
            />
          </S.ThirdCard>
        </S.BodyContainer>
      )}
    </>
  );
};

import { ExpandableCardProps } from './types';
import * as S from './styles';
import ExpandableTable from '../../../pages/ComponentsPrices/ExpandableTable';

export default function ExpandableCard({
  headerCard,
  children,
  onExpandableContent,
  isOpen,
}: ExpandableCardProps) {
  return (
    <S.Container>
      <S.header>
        <ExpandableTable isOpen={isOpen} onClick={onExpandableContent} />
        {headerCard}
      </S.header>
      {isOpen && children}
    </S.Container>
  );
}

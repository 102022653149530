import { DatePicker } from 'antd';
import * as S from './styles';
import StyledRagePickerProps from './types';
import StyledLabel from '../StyledLabel';

export default function StyledRangePiker({
  label,
  value,
  picker,
  changeOnBlur,
  disabled,
  placeholder,
  required = false,
  disabledDate,
  onCalendarChange,
  onChange,
  onOpenChange,
}: StyledRagePickerProps) {
  const { RangePicker } = DatePicker;
  return (
    <S.Container>
      <StyledLabel title={label || ''} />
      <RangePicker
        picker={picker}
        value={value}
        changeOnBlur={changeOnBlur}
        disabledDate={disabledDate}
        onCalendarChange={onCalendarChange}
        onChange={onChange}
        onOpenChange={onOpenChange}
        disabled={disabled}
        placeholder={placeholder}
      />
      {required && <S.ContainerRequired>Required</S.ContainerRequired>}
    </S.Container>
  );
}

import styled from 'styled-components';
import { Table as AntTable, Col as AntdCol, Button as AntButton } from 'antd';
import { Button } from '../../components/Button';
import { ActionButton } from '../../components/ActionButton';

export const Compare = styled.div`
  font-weight: bold;
  padding-left: 14px;
`;

export const RefreshAction = styled.div`
  button {
    margin-left: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const MessageIsLoading = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.prim}!important;
  padding: 1.5rem;
  font-size: 1rem;

  div {
    display: flex;
    gap: 0.25rem;
  }
`;

export const MessageIsRefreshing = styled.div`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.prim}!important;
  color: ${({ theme }) => theme.colors.prim}!important;
  border-radius: 0 !important;
  background: transparent;
  max-width: 190px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  //margin-left: 3px;
  font-size: 16px;

  svg {
    //width: 24px;
    //height: 24px;
    //margin-right: 10px;
  }
`;

export const MessageRefresh = styled.div`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary}!important;
  color: ${({ theme }) => theme.colors.secondary}!important;
  border-radius: 0 !important;
  background: transparent;
  max-width: 420px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

export const ActionButtonPontuacao = styled(ActionButton)`
  //padding-right: 5px;
  //padding-left: 5px;
  &:hover {
    text-decoration: none;
  }
`;

export const Pontuacao = styled.div`
  color: ${({ theme }) => theme.colors.text}!important;
  display: flex;

  flex-direction: row;
  font-size: 16px;
  font-weight: 500 !important;
  gap: 6px;
  text-align: center;
`;

export const ButtonTransfer = styled(Button)`
  &:disabled,
  &:disabled:hover {
    background-color: inherit;
    color: inherit;
    opacity: 0.7;
  }
`;

export const ColHidden = styled(AntdCol)`
  padding-right: 0;
  margin-right: 0;

  button {
    padding-right: 0;
  }
`;

export const ColSettings = styled(AntdCol)`
  padding-right: 0;
  margin-right: 4px;
  margin-left: 18px;
  width: auto;
  max-width: none;
  flex: 0;
`;

export const ActionButtonSettings = styled(ActionButton)`
  padding-right: 0;
  margin-right: 0;
`;

export const ButtonSpec = styled(Button)`
  width: 112px;
  border-radius: 4px !important;
  background-color: ${({ theme }) => theme.colors.link};
  border-color: ${({ theme }) => theme.colors.link};
  //border-color: ${(props) => props.theme.colors.text}!important;

  &.ant-btn {
    color: ${({ theme }) => theme.colors.shape};

    &:hover {
      background: ${({ theme }) => theme.colors.shape}!important;
      color: ${(props) => props.theme.colors.sec}!important;
      border-color: ${(props) => props.theme.colors.text}!important;
    }
  }
`;

export const TableSimulationDiv = styled.div`
  .table-row-button {
    background-color: ${({ theme }) => theme.colors.dark_row} !important;
  }

  .light-row:not(.table-row-button) {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  .dark-row:not(.table-row-button) {
    background-color: ${({ theme }) => theme.colors.dark_row} !important;
  }
`;
export const TableSimulation = styled(AntTable)`
  .ant-table {
    background-color: transparent;
  }
  .ant-table-container {
    border-left: 0 !important;
  }

  tr:hover {
    .ant-table-cell {
      background-color: ${({ theme })=> theme.colorsDesignSystem.tableHover} !important;
    }
  }

  //.table-row-disabled:nth-of-type(7n),
  //.table-row-disabled:last-child,
  //.table-row-disabled:last-of-type {
  //  background-color: #000 !important;
  //}
  .ant-table-content {
    table {
      border: 1px solid ${({ theme }) => theme.colors.border};
      margin-bottom: 32px;
      thead,
      th {
        border-top: 1px solid ${({ theme }) => theme.colors.border}!important;
        border-bottom: 1px solid ${({ theme }) => theme.colors.border}!important;
        border-right: none !important;
        font-weight: 700;
        font-size: 16px;
        padding: 8px 16px;
        &.table-divisor {
          border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
        }
        &::before {
          display: none;
        }
      }
      thead tr:last-child {
        th {
          border: none !important;
        }
      }
      thead tr:first-child {
        th {
          border-bottom: none !important;
          padding: 8px 0 0 0;
          &:first-child {
            width: 5% !important;
          }
          &:nth-of-type(2) {
            width: 6% !important;
          }
        }
      }
      thead > tr > .table-divisor {
        border: 0px !important;
      }
      .ant-table-cell.ant-table-cell-with-append {
        width: 143px;
      }
      .ant-table-thead > tr > td {
        background: #f8f8f8 !important;
        border-bottom: none !important;
      }
      .ant-table-row {
        cursor: pointer;
      }
      .ant-table-row-indent + .ant-table-row-expand-icon {
        margin-top: 3px;
      }
      .ant-table-row-level-1 {
        td:first-child {
          border-right: 0 !important;
        }
        td:nth-of-type(2) {
          padding: 0 0 0 0;
        }
        .ant-table-row-indent + .ant-table-row-expand-icon {
          margin-top: 0;
          margin-right: 0;
        }
      }
      .tr-parttype {
        vertical-align: top;
      }
      .table-row-button.row-parttype {
        td:not(:first-of-type) {
          padding: 24px;
        }
      }
      .month-december {
        .editable-cell-value-wrap {
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          min-height: 40px !important;
        }
      }
      tbody > tr:first-child {
        td {
          border-right: none !important;
          border-top: 1px solid rgba(0, 0, 0, 0.4);
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        }
      }
      .table-row-button {
        td {
          border-right: none !important;
          border-top: 1px solid ${({ theme }) => theme.colors.border};
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          &:first-child {
            border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-bottom: none;
          }
        }
      }
      .table-row-componentType {
        td {
          border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
          &:first-child {
            //text-transform: uppercase;
          }
          &:last-child {
            border-right: none !important;
          }
        }
        .td-user-fob,
        .td-user-br {
          border-bottom: none;
          padding: 0;
          .editable-cell-value-wrap {
            // border-left: 1px solid ${({ theme }) => theme.colors.link};
            // border-right: 1px solid ${({ theme }) => theme.colors.link};
            // border-top: 1px solid ${({ theme }) => theme.colors.link};
            padding: 0 !important;
            min-height: 39px;
            justify-content: center;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            top: -1px;
            position: absolute;
          }
          .ant-form-item {
            input {
              border: 1px solid ${({ theme }) => theme.colors.link};
            }
          }
        }
        .td-user-br {
          border-left: none;
          .editable-cell-value-wrap {
            border-left: none;
          }
        }
      }
      
      tr.table-row-disabled {
        background-color: #e4e4e4;
        // &:last-of-type,
        // &:last-child,
        // &:first-child,
        // &:first-of-type {
        //   background-color: #000 !important;
        //   border: 1px solid ${({ theme }) => theme.colors.link}!important;
        // }
        & > td,
        & > td .span-gap {
          color: #9e9e9e !important;
        }
        & > td > span {
          color: #666666 !important;
          opacity: 0.45;
        }
        &:hover > td,
        &:hover > td .span-gap {
          background-color: #f0f2f5;
          color: ${({ theme }) => theme.colors.title}!important;
        }
        
        .td-user-fob,
        .td-user-br {
          border-color: ${({ theme }) => theme.colors.border};
          //border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          //border-right: 1px solid ${({ theme }) => theme.colors.border} !important;
        }
        .td-user-costTotal {
          border-left-color: ${({ theme }) => theme.colors.border};
        }
      }
      .table-row-componentType {
        //border-top: 1px solid ${({ theme }) => theme.colors.border}!important;
        //border-bottom: 1px solid ${({ theme }) => theme.colors.border}!important;
        background-color: #f5f5f5;
        td {
          color: ${({ theme }) => theme.colors.title};
          font-size: 16px;
          font-weight: 500;
          padding: 12px;
        }
        &:hover > td {
          background-color: #f5f5f5;
        }
        //.td-user-fob,
        //.td-user-br {
        //  border: inherit;
        //}
      }
      .table-row-componentSpec {
        background-color: #fff;
        td {
          color: #444444;
          font-size: 16px;
          font-weight: 500;
          padding: 10px;
        }
        &:hover > td {
          background-color: #fff;
        }
        //.td-user-fob,
        //.td-user-br {
        //  border: inherit;
        //}
      }
      tbody > tr {
        background-color: ${({ theme }) => theme.colors.shape};
        td {
          color: #444444;
          //border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          padding: 8px 16px;
          //font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
`;


export const TagQualifyOngoing = styled(AntButton)`
  width: 3.375rem;
  height: 1.375rem;
  margin: 0;
  padding-inline-start: 0;
  padding: 0;
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue} !important;
  border-radius: 0.25rem !important;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue} !important;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlueBackground} !important;
  &:hover {
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue} !important;
    border-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue} !important;
  }
  div {
    font-weight: 600 !important;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    padding-bottom: 2px;
  }
  span {
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const CriticalScore = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
export const HighScore = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorOrange};
`;
export const GoodScore = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const NotApplicable = styled.span`
  padding-left: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;
export const TotalScoreContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.2rem;
`;

export const FilterTagsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

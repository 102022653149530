import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Flex, Form, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledMultipleSelect from '../../../../components/Common/StyledMultipleSelect';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { TableTransfer } from '../../../../components/Common/StylesTableTransfer';
import { removeDiplicated } from '../../../../helpers/nUtils';
import { states } from '../../../../helpers/utils';
import { DeleteCategoryModal } from '../../DeleteCategoryModal';
import { CompressedComponent } from '../components/CompressedComponents';
import * as S from '../styles';
import { ContainerComponents, FormContent, FormFooter } from './styles';
import { IStep2, ITransferPartNumber, ITransferProjet } from './types';
import { useStep2 } from './useStep2';

const { RangePicker } = DatePicker;

export function Step2({
  //form,
  handleSubmit,
  nextStep,
  previousStep,
  onCancel,
  bidId,
}: IStep2) {
  const { t } = useTranslation();
  const {
    categories,
    projects,
    specs,
    targetProjects,
    onChangeProject,
    partNumbers,
    onChangePartNumber,
    targetPartNumbers,
    partNumbersColumns,
    projectsColumns,
    componentIdOpen,
    setComponentIdOpen,
    isNewComponentOpen,
    setIsNewComponentOpen,
    selectedProjects,
    selectedPartNumbers,
    setSelectedCategory,
    setSelectedSpecs,
    onEditComponent,
    selectedSpecs,
    openDeleteModal,
    setOpenDeleteModal,
    setSelectedPartNumbers,
    setSelectedProjects,
    setTargetPartNumbers,
    setTargetProjects,
    components,
    deleteComponent,
    form,
    isLoading,
    isLoadingSubmit,
    isLoadingData,
    fetchComponents,
  } = useStep2(bidId);

  const filterPartNumbers = (inputValue: string, item: ITransferPartNumber) => {
    return (
      item.partNumber.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.qstatus.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.supplier.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.project.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1
    );
  };

  const validatePrices = (value: string) => {
    if (!value || /^\d+\.\d{2}$/.test(value)) {
      return true;
    }
    return false;
  };

  const validateValue = (value: string) => {
    if (!value || /^\d+(\.\d+)?$/.test(value)) {
      return true;
    }
    return false;
  };

  const renderForm = () => {
    return (
      <S.StyledForm layout="vertical" requiredMark form={form} removePadding removeBorder>
        <FormContent>
          <Form.Item noStyle>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label={t('pages.newRfq.steps.secondStep.fields.altGroup')}
                  name="altGroup"
                  rules={[
                    {
                      required: true,
                      message: `${t('pages.newRfq.steps.secondStep.fields.altGroup')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      min: 2,
                      message: t('pages.newRfq.steps.secondStep.fields.altGroupMinCharacter'),
                    },
                    {
                      max: 25,
                      message: t('pages.newRfq.steps.secondStep.fields.altGroupMaxCharacter'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('pages.newRfq.steps.secondStep.fields.placeholderAltGroup')}
                    minLength={2}
                    maxLength={70}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('pages.newRfq.steps.secondStep.fields.category')}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: `${t('pages.newRfq.steps.secondStep.fields.category')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                  ]}
                >
                  <StyledSelect
                    options={categories}
                    showSearch
                    placeholder={t('pages.newRfq.steps.secondStep.fields.placeholderCategory')}
                    onChange={(e: string) => {
                      setSelectedCategory(e);
                      setSelectedSpecs(null);
                      form.setFieldValue('specifications', []);
                      setTargetProjects([]);
                      setSelectedProjects([]);
                      setSelectedPartNumbers([]);
                      setTargetPartNumbers([]);
                    }}
                    loading={isLoadingData.loadingCategories}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {() => {
                    return (
                      <Form.Item
                        label={t('pages.newRfq.steps.secondStep.fields.specification')}
                        name="specifications"
                        rules={[
                          {
                            required: true,
                            message: `${t(
                              'pages.newRfq.steps.secondStep.fields.specification'
                            )} ${t(`common.validate.required`)}`,
                          },
                        ]}
                      >
                        <StyledMultipleSelect
                          value={selectedSpecs}
                          options={specs}
                          showSearch
                          placeholder={t(
                            'pages.newRfq.steps.secondStep.fields.placeholderSpecification'
                          )}
                          onChange={(e: string) => {
                            setSelectedSpecs([e]);
                          }}
                          loading={isLoadingData.loadingSpecs}
                          allowClear
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={t('pages.newRfq.steps.secondStep.fields.projects')}
            name="projects"
            rules={[
              {
                required: true,
                message: `${t('pages.newRfq.steps.secondStep.fields.projects')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <TableTransfer<ITransferProjet>
              dataSource={projects}
              targetKeys={targetProjects}
              showSelectAll={false}
              onChange={onChangeProject}
              leftColumns={projectsColumns}
              loading={isLoadingData.loadingProjects}
              showSearch
              filterOption={(inputValue, item) =>
                item.project.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
                item.cm.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1
              }
              rightColumns={projectsColumns}
              titles={[
                t('pages.newRfq.steps.secondStep.fields.all'),
                t('pages.newRfq.steps.secondStep.fields.selected'),
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.newRfq.steps.secondStep.fields.partNumbers')}
            name="partNumbers"
            rules={[
              {
                required: true,
                message: `${t('pages.newRfq.steps.secondStep.fields.partNumbers')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <TableTransfer<ITransferPartNumber>
              dataSource={partNumbers}
              targetKeys={targetPartNumbers}
              showSelectAll={false}
              onChange={onChangePartNumber}
              leftColumns={partNumbersColumns}
              loading={isLoadingData.loadingPartNumber}
              rightColumns={partNumbersColumns}
              showSearch
              filterOption={filterPartNumbers}
              titles={[
                t('pages.newRfq.steps.secondStep.fields.all'),
                t('pages.newRfq.steps.secondStep.fields.selected'),
              ]}
            />
          </Form.Item>
          <Form.Item noStyle>
            <Row gutter={24}>
              <Col>
                <Form.Item
                  label={t('pages.newRfq.steps.secondStep.fields.demandPeriod')}
                  name="demandPeriod"
                  rules={[
                    {
                      required: true,
                      message: `${t('pages.newRfq.steps.secondStep.fields.demandPeriod')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                  ]}
                >
                  <RangePicker
                    placeholder={[
                      t('pages.newRfq.steps.secondStep.fields.startDatePlaceholder'),
                      t('pages.newRfq.steps.secondStep.fields.endDatePlaceholder'),
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label={t('pages.newRfq.steps.secondStep.fields.from')} name="from">
                  <StyledSelect
                    options={states('')}
                    placeholder={t('pages.newRfq.steps.secondStep.fields.from')}
                    onChange={() => null}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label={t('pages.newRfq.steps.secondStep.fields.to')} name="to">
                  <StyledSelect
                    options={states('')}
                    placeholder={t('pages.newRfq.steps.secondStep.fields.to')}
                    onChange={() => null}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item noStyle>
            <Flex gap={24}>
              <Form.Item
                label={t('pages.newRfq.steps.secondStep.fields.startingPrice')}
                name="startingPrice"
                rules={[
                  { required: false },
                  {
                    validator: (_, value) =>
                      validatePrices(value)
                        ? Promise.resolve()
                        : Promise.reject(t('pages.newRfq.steps.secondStep.fields.priceError')),
                  },
                  {
                    validator: (_, value) =>
                      validateValue(value)
                        ? Promise.resolve()
                        : Promise.reject(t('toast.invalidField')),
                  },
                ]}
                style={{ width: '12.5rem' }}
              >
                <Input
                  placeholder={t('pages.newRfq.steps.secondStep.fields.placeholderStartingPrice')}
                  minLength={2}
                  maxLength={70}
                />
              </Form.Item>
              <Form.Item
                label={t('pages.newRfq.steps.secondStep.fields.fobPrice')}
                name="fobPrice"
                rules={[
                  { required: false },
                  {
                    validator: (_, value) =>
                      validatePrices(value)
                        ? Promise.resolve()
                        : Promise.reject(t('pages.newRfq.steps.secondStep.fields.priceError')),
                  },
                  {
                    validator: (_, value) =>
                      validateValue(value)
                        ? Promise.resolve()
                        : Promise.reject(t('toast.invalidField')),
                  },
                ]}
                tooltip={{
                  title: t('pages.newRfq.steps.secondStep.fields.fobPriceTooltip'),
                  icon: <InfoCircleOutlined />,
                }}
                style={{ width: '12.5rem' }}
              >
                <Input
                  placeholder={t('pages.newRfq.steps.secondStep.fields.placeholderStartingPrice')}
                  minLength={2}
                  maxLength={6}
                />
              </Form.Item>
              <Form.Item
                label={t('pages.newRfq.steps.secondStep.fields.lastFOBPrice')}
                name="lastFobPrice"
                rules={[
                  { required: false },
                  {
                    validator: (_, value) =>
                      validatePrices(value)
                        ? Promise.resolve()
                        : Promise.reject(t('pages.newRfq.steps.secondStep.fields.priceError')),
                  },
                  {
                    validator: (_, value) =>
                      validateValue(value)
                        ? Promise.resolve()
                        : Promise.reject(t('toast.invalidField')),
                  },
                ]}
                tooltip={{
                  title: t('pages.newRfq.steps.secondStep.fields.lastFOBPriceTooltip'),
                  icon: <InfoCircleOutlined />,
                }}
                style={{ width: '12.5rem' }}
              >
                <Input
                  placeholder={t('pages.newRfq.steps.secondStep.fields.placeholderStartingPrice')}
                  minLength={2}
                  maxLength={6}
                />
              </Form.Item>
            </Flex>
          </Form.Item>
        </FormContent>
        <FormFooter>
          <Form.Item shouldUpdate>
            {() => {
              return (
                <StyledButton
                  variant="primary"
                  loading={isLoadingSubmit}
                  onClick={() => {
                    form.validateFields().then(async () => {
                      handleSubmit({
                        ...form.getFieldsValue(true),
                        projects: selectedProjects,
                        partNumbers: selectedPartNumbers,
                      }).then(() => {
                        isNewComponentOpen
                          ? setIsNewComponentOpen(false)
                          : setComponentIdOpen(null);
                        form.resetFields();
                        fetchComponents();
                      });
                    });
                  }}
                  disabled={isLoadingData.loadingPartNumber || isLoadingData.loadingProjects}
                  htmlType="submit"
                >
                  {t('common.save')}
                </StyledButton>
              );
            }}
          </Form.Item>
        </FormFooter>
      </S.StyledForm>
    );
  };

  return (
    <>
      <DeleteCategoryModal
        isOpen={openDeleteModal.isModalOpen}
        onDelete={() => {
          if (openDeleteModal.componentId) {
            deleteComponent(openDeleteModal.componentId);
          } else {
            form.resetFields();
            setComponentIdOpen(null);
            setIsNewComponentOpen(false);
          }
          setOpenDeleteModal({
            isModalOpen: false,
            // eslint-disable-next-line no-undefined
            componentId: undefined,
          });
          setComponentIdOpen(null);
        }}
        onCloseModal={() =>
          setOpenDeleteModal({
            isModalOpen: false,
            // eslint-disable-next-line no-undefined
            componentId: undefined,
          })
        }
        loading={isLoading}
      />
      {isLoadingData.loadingComponents ? (
        <StyledLoading height={50} />
      ) : (
        <ContainerComponents>
          <S.ContentForm>
            {components.map((component) => {
              return (
                <CompressedComponent
                  step="2"
                  key={component.stepComponentId}
                  index={component.stepComponentId}
                  title={`${component.category ?? ''} | ${component.altGroup}`}
                  isOpen={
                    (componentIdOpen && componentIdOpen === component.stepComponentId) || false
                  }
                  onDelete={() => {
                    setOpenDeleteModal({
                      isModalOpen: true,
                      componentId: component.stepComponentId,
                    });
                  }}
                  disableDelete={isLoadingSubmit}
                  disableEdit={isLoadingSubmit}
                  onClose={() => {
                    form.validateFields().then(() => {
                      form.resetFields();
                      setComponentIdOpen(null);
                    });
                  }}
                  onEdit={() => {
                    onEditComponent(component);
                    form.setFieldsValue({
                      ...component,
                      demandPeriod: [
                        dayjs(component.demandPeriod[0]),
                        dayjs(component.demandPeriod[1]),
                      ],
                    });
                    component.stepComponentId && setComponentIdOpen(component.stepComponentId);
                    setIsNewComponentOpen(false);
                  }}
                  demandPeriod={
                    component && component.demandPeriod
                      ? `${dayjs(component?.demandPeriod[0])?.format('YYYY/MM/DD')}  - ${dayjs(
                          component?.demandPeriod[1]
                        )?.format('YYYY/MM/DD')}`
                      : '-'
                  }
                  staringPrice={component.startingPrice}
                  suppliers={removeDiplicated(
                    component.partNumbers.map((partNumber) => partNumber.supplier)
                  )}
                >
                  {renderForm()}
                </CompressedComponent>
              );
            })}
            {isNewComponentOpen && (
              <CompressedComponent
                step="2"
                isOpen={isNewComponentOpen}
                onDelete={() => {
                  if (Object.keys(form.getFieldsValue(true)).length > 0) {
                    setOpenDeleteModal({ isModalOpen: true });
                  } else {
                    form.resetFields();
                    setComponentIdOpen(null);
                    setIsNewComponentOpen(false);
                  }
                }}
                disableDelete={isLoadingSubmit}
                disableEdit={isLoadingSubmit}
                onClose={() => {
                  if (Object.keys(form.getFieldsValue(true)).length > 0) {
                    form.validateFields().then(() => {
                      handleSubmit({
                        ...form.getFieldsValue(true),
                        sketch: true,
                        projects: selectedProjects,
                        partNumbers: selectedPartNumbers,
                      }).then(() => {
                        form.resetFields();
                        setIsNewComponentOpen(false);
                        fetchComponents();
                      });
                    });
                  } else setIsNewComponentOpen(false);
                }}
                onEdit={() => {
                  setIsNewComponentOpen(false);
                }}
              >
                {renderForm()}
              </CompressedComponent>
            )}
            <StyledButton
              variant="dashed"
              style={{ width: '100%' }}
              disabled={!!componentIdOpen || isNewComponentOpen || isLoadingSubmit}
              onClick={() => setIsNewComponentOpen(true)}
            >
              + {t('pages.newRfq.steps.secondStep.addComponent')}
            </StyledButton>
          </S.ContentForm>
        </ContainerComponents>
      )}
      <S.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <Row style={{ gap: '1rem' }}>
          <StyledButton
            variant="secondary"
            onClick={() => {
              if (Object.keys(form.getFieldsValue(true)).length > 0) {
                form.validateFields().then(() => {
                  handleSubmit(form.getFieldsValue(true)).then(() => {
                    previousStep();
                  });
                });
              } else previousStep();
            }}
            disabled={
              isLoadingData.loadingPartNumber ||
              isLoadingData.loadingProjects ||
              isLoadingData.loadingComponents
            }
          >
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              form.validateFields().then(() => {
                handleSubmit({
                  ...form.getFieldsValue(true),
                  projects: selectedProjects,
                  partNumbers: selectedPartNumbers,
                }).then(() => {
                  nextStep();
                });
              });
            }}
            disabled={
              components.length === 0 ||
              isLoadingData.loadingPartNumber ||
              isLoadingData.loadingProjects ||
              isLoadingData.loadingComponents
            }
            htmlType="submit"
          >
            {t('common.next')}
          </StyledButton>
        </Row>
      </S.StepFooter>
    </>
  );
}

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api } from '../../services/api';
import { getQueryString, oneAlert } from '../../helpers/utils';
import { getSession, setAccessTokens } from '../../services/auth';
import { RoutesPath } from '../../routes/routesPath';

export function useRegisterPassword() {
  const history = useHistory();
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState({});

  const handleNavigateHome = () => {
    history.push(RoutesPath.scenarios);
    setIsLoading(false);
  };

  const onFinish = async (values) => {
    const { password } = values;
    const userFirstPassword = getQueryString('first');
    const session = getSession();

    try {
      if (session && userFirstPassword) {
        const { data } = await api.post(`${import.meta.env.VITE_API_COGNITO}/first-login`, {
          newPassword: password,
          username: userFirstPassword,
          session,
        });

        if (data?.accessToken) {
          setAccessTokens(data);
          handleNavigateHome();
        }
      }
    } catch (errorData) {
      oneAlert('error', t('toast.errorOnLogin'));
    }
  };

  return {
    isLoading,
    error,
    onFinish,
    location: history.location.pathname,
    locationData,
    setLocationData,
  };
}

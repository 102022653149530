export const TRANSLATIONS_PT_BR = {
  common: {
    send: 'enviar',
    dateFormat: 'DD/MM/YYYY',
    confirm: 'Confirmar',
    days: 'Dias',
    upTo: 'Até',
    localeMoney: 'pt',
    total: 'Total',
    active: 'Ativo',
    items: 'Itens',
    inactive: 'Inativo',
    activate: 'Ativar',
    deactivate: 'Inativar',
    enable: 'Habilitar',
    disable: 'Desabilitar',
    activateMessage: 'Tem certeza que deseja habilitar este usuário?',
    deactivateMessage: 'Tem certeza que deseja desabilitar este usuário?',
    user: 'Usuário',
    settings: 'Configurações',
    report: 'Relatório',
    reports: 'Relatórios',
    action: 'Ações',
    add: 'Adicionar',
    select: 'Selecione',
    unselect: 'Desmarcar',
    selected: 'Selecionado',
    search: 'Buscar',
    none: 'Nenhum',
    showAll: 'Mostrar todos',
    state: 'Estado',
    compareWith: 'Comparar com',
    auto: 'Automático',
    manual: 'Manual',
    all: 'Todos',
    apply: 'Aplicar',
    by: 'Por',
    cancel: 'Cancelar',
    close: 'Fechar',
    cancelMessage: 'Tem certeza que deseja cancelar?',
    characters: 'caracteres',
    copyMessage: 'Tem certeza que deseja copiar?',
    clear: 'Limpar',
    clearFilters: 'Limpar Filtros',
    create: 'Criar',
    copy: 'Copiar',
    delete: 'Excluir',
    deleteMessage: 'Tem certeza que deseja deletar?',
    deleteMessageAll: 'Tem certeza que deseja deletar todos?',
    edit: 'Editar',
    export: 'Exportar',
    filter: 'Filtrar',
    filtering: 'Filtrando',
    filters: 'Filtros',
    in: 'em',
    import: 'Importar',
    item: 'Item',
    language: 'Idioma',
    loading: 'Carregando',
    login: 'Entrar',
    logout: 'Sair',
    lot: 'Lote',
    no: 'Não',
    ok: 'OK',
    register: 'Cadastrar',
    result: 'Resultado',
    rule: 'regra',
    save: 'Salvar',
    submit: 'Enviar',
    updated: 'Atualizado em',
    noRecords: 'Sem Registros',
    view: 'Ver',
    viewAll: 'Ver Tudo',
    yes: 'Sim',
    or: 'ou',
    to: 'a',
    and: 'e',
    of: 'de',
    previous: 'Anterior',
    next: 'Próxima',
    back: 'Voltar',
    page: 'Página',
    showing: 'Mostrando',
    selectAll: 'Selecionar todas',
    deleteAll: 'Apagar todos',
    moneyPlaceholder: '00.00',
    datePlaceHolder: 'Selecione o período',
    mandatoryField: 'Campo Obrigatório',
    partNumber: 'Part Number',
    specification: 'Especificação',
    description: 'Descrição',
    source: 'Source',
    manufacturer: 'Fabricante',
    quarter: 'Semestre',
    fields: {
      uploadFile: 'Carregar',
      uploadText: 'Clique ou araste um arquivo para fazer o upload.',
      uploadDescription: 'Suba um arquivo por vez.',
      uploadSuccess: 'Upload realizado com sucesso.',
      adItem: 'Adicionar Item',
    },
    results: {
      noDataTitle: 'Nenhum registro encontrado.',
      noDataSubtitle: 'Cadastre ou verifique os parâmetros de filtro.',
      noScenarioTitle: 'Sem cenário selecionado.',
      noScenarioSubtitle: 'Por favor, filtre um cenário para continuar.',
      noProjectSubtitle: 'Por favor, selecione um projeto para continuar.',
      noProject: 'Nenhum project selecionado.',
      noPpb: 'Nenhum produto selecionado.',
    },
    validate: {
      required: 'Obrigatório',
      isRequired: 'é obrigatório',
      number: 'não é um número válido',
      typeNumber: 'Digite um número',
      range: 'deve estar entre',
      invalid: 'Valor inválido',
      max: 'não deve ser maior que',
      min: 'não deve ser menor que',
    },
    months: {
      january: 'Jan',
      february: 'Fev',
      march: 'Mar',
      april: 'Abr',
      may: 'Mai',
      june: 'Jun',
      july: 'Jul',
      august: 'Ago',
      september: 'Set',
      october: 'Out',
      november: 'Nov',
      december: 'Dez',
    },
    pagination: {
      page: '/ página',
      showing: 'Exibindo',
      ofTotal: 'do total de',
      items: 'itens',
      jumpToPage: 'Ir para página',
    },
    permissions: 'Permissões',
    score: 'Pontuação',
    target: 'Meta',
    trend: 'Tendência',
    dayjs: 'pt-br',
  },
  notifications: {
    title: 'Notificações',
    clearAll: 'Limpar todas',
    seeAll: 'Ver todas',
    delete: 'Deletar Notificação',
    check: 'Verifique!',
    found: 'encontrada(s)',
    skuDesc: 'Atenção! Existe(m) {{data}} SKU(s) sem motherboard',
    qfDoneDesc: 'Atenção Existe(m) itens com qualificação concluída',
    qfFailDesc: 'Atenção Existe(m) itens com falha na qualificação',
    sku: "SKU's sem motherboard",
    copyMade: 'Copiado na área de transferência',
    noDataForRequest: 'O sistema atualmente não possui dados para esta solicitação.',
  },
  toast: {
    successOnSave: 'Item salvo com sucesso',
    dataProcessing:
      'Edição salva com sucesso! Estamos processando os dados e notificaremos você quando estiverem concluídos.',
    errorOnSave: 'Falha ao salvar item',
    errorOnList: 'Falha ao listar dados',
    successOnDelete: 'Item deletado com sucesso',
    errorOnDelete: 'Falha ao deletar item',
    successOnUpload: 'Upload realizado com sucesso',
    errorOnUpload: 'Erro ao fazer Upload',
    errorOnAuth: 'Por favor, faça login para continuar',
    errorOnLogin: 'Erro ao fazer login, verifique o usuário e a senha',
    missingComponent: 'Componente não encontrado',
    missingScenario: 'Cenário não encontrado',
    missingSupplier: 'Fornecedor não encontrado',
    hasEmptyKeys: 'Contém suppliers ou specs vazios',
    requiredPricesFactors: 'Os arquivos de preços e fatores são obrigatórios',
    exDuplication: 'Item Duplicado',
    skuFormat: 'SKU fora do formato',
    skuNotFound: 'não encontrado. Para completar essa ação cadastre esse SKU.',
    exporting: 'Exportando dados',
    successOnExporting: 'CSV exportado com sucesso',
    errorComponentMax: 'Valor máximo inválido',
    errorComponentMin: 'Valor mínimo inválido',
    errorOnUserStatus: 'Erro ao alterar status do usuário',
    successOnUserStatus: 'Status do usuário alterado com sucesso',
    errorOnGroupDelete:
      'Não é possível excluir este grupo! Para excluí-lo, vincule o (s) usuário (s) a seguir a outro grupo!',
    errorDuplicateComponent:
      'Não é possível criar agrupamento duplicado com o seguinte componente: ',
    errorDuplicateSpec: 'Não é possível criar agrupamento duplicado com a seguinte especificação: ',
    errorExporting: 'Falha ao exportar',
    invalidEmail: 'Email de contato inválido',
    noChanges: 'Nada foi alterado',
    invalidField: 'Valor inválido',
    uploadSuccessTitle: 'Arquivo importado com sucesso!',
    uploadSuccessBody: 'Os dados estão sendo processados e estarão disponíveis em alguns minutos',
    erroOnSendEmail: 'Erro ao enviar email',
  },
  filter: {
    user: 'Usuário',
    local: 'Local',
    scenario: 'Scenario',
    selectPlaceholder: 'Selecione múltiplos itens',
    sku: 'SKU',
    specShort: 'Espec',
    partType: 'Tipo de Componente',
    spec: 'Especificação',
    partNumber: 'Part Number',
    supplier: 'Fornecedor',
    project: 'Projeto',
    version: 'Versão',
    year: 'Ano',
    status: 'Status',
    qStatus: 'Q-Status',
    product: 'Produto',
    location: 'Fonte',
    vendor: 'Fabricante',
  },
  sort: {
    orderBy: 'Clique para ordenar',
    orderByAsc: 'Clique para ordenar em ordem crescente',
    orderByDesc: 'Clique para ordenar em ordem decrescente',
    cancelSort: 'Clique para cancelar a ordenaração',
  },
  pages: {
    logusers: {
      title: 'Log de Usuários',
      list: 'List',
      confirmMessage: 'Are you sure process this file again?',
      tooltipMessage: 'Process file',
      successMessage: 'File added to processing queue',
      successDeleteMessage: 'Logs deletados com sucesso',
      selectDate: 'Selecione uma data',

      fields: {
        modifiedDate: 'Data',
        user: 'Usuário',
        area: 'Área',
        message: 'Mensagem',
        startDate: 'Data inicial',
        endDate: 'Data final',
        chooseDate: 'Escolha o intervalo de datas',
      },

      registerForm: {
        title: 'Register',
      },
      editForm: {
        title: 'Edit component',
      },
      filter: {
        selectPlaceholder: 'Select multiple items',
      },
    },
    fileerrors: {
      title: 'Logs de arquivos',
      list: 'Listagem',
      confirmMessage: 'Tem certeza de processar este arquivo novamente?',
      tooltipMessage: 'Processar arquivo',
      successMessage: 'Arquivo adicionado na fila de processamento',
      successDeleteMessage: 'Logs deletados com sucesso',
      processMultipleMessage: 'Processar {{files}} arquivos',

      fields: {
        message: 'Mensagem',
        file: 'Arquivo',
        status: 'Status',
        modifiedDate: 'Data',
      },

      registerForm: {
        title: 'Cadastrar',
      },
      editForm: {
        title: 'Editar componente',
      },
      filter: {
        selectPlaceholder: 'Selecione múltiplos itens',
      },
      innventory: {
        title: 'Inventário',
        labels: {
          product: 'Produto',
          cm: 'CM',
          category: 'Categoria',
          specification: 'Especificação',
          partNumber: 'Part Number',
          source: 'Fonte',
          supplier: 'Fornecedor',
          date: 'Data',
          apply: 'Aplicar',
        },
        placeholders: {
          product: 'Selecione o produto',
          cm: 'Selecione CM',
          category: 'Add Categoria',
          specification: 'Add Especificação',
          partNumber: 'Add Part Number',
          source: 'Selecione Fonte',
          supplier: 'Add Fornecedor',
        },
      },
    },

    components: {
      title: 'Componentes',
      list: 'Listagem',

      fields: {
        spec: 'Especificação',
        subComponent: 'Subcomponentes',
        partType: 'Categoria',
        partNumber: 'Part Number',
        description: 'Descrição',
        local: 'Fonte',
        leadTime: 'Prazo de Entrega',
      },

      labels: {
        cm: 'CM',
        product: 'Produto',
        category: 'Categoria',
        specification: 'Especificação',
        partNumber: 'Part Number',
        description: 'Descrição',
        source: 'Fonte',
        leadTime: 'Prazo de Entrega',
      },
      placeHolders: {
        cm: 'Selecione CM',
        product: 'Selecione Produto',
        category: 'Selecione Categoria',
        specification: 'Add Especificação',
        partNumber: 'Add Part Number',
        description: 'Add Descrição',
        source: 'Selecione Fonte',
        leadTime: 'Selecionar intervalo de dias',
      },

      registerForm: {
        title: 'Cadastrar',
      },
      editForm: {
        title: 'Editar componente',
      },
      filter: {
        selectPlaceholder: 'Selecione múltiplos itens',
      },
    },
    componentsGroup: {
      title: 'Agrupamento de Componentes',
      fields: {
        partType: 'Categoria',
        groupName: 'Nome do Grupo',
        spec: 'Especificação',
        partNumber: 'Número de Componente',
        supplier: 'Fornecedor',
        components: 'Componentes',
        component: 'Componente',
        item: 'Item',
      },
      modalTax: {
        modalTaxTitle: 'Preço Liquído Componentes ',
        title: 'Definição de Taxas',
        tootip: 'Calculado pelo sistema',
        table: {
          componentType: 'Categoria',
          from: 'De',
          to: 'Para',
          ipi: 'IPI %',
          icms: 'ICMS %',
          pisCofins: 'PIS/COFINS %',
          rd: 'R&D %',
          recoverable: 'Recuperável',
          period: 'Período',
        },
      },
    },
    demands: {
      title: 'Demandas',
      description: 'Demand page',

      fields: {
        project: 'Projeto',
        model: 'Modelo',
        modelName: 'Nome Modelo',
        sku: 'SKU',
        january: 'Jan',
        february: 'Fev',
        march: 'Mar',
        april: 'Abr',
        may: 'Mai',
        june: 'Jun',
        july: 'Jul',
        august: 'Ago',
        september: 'Set',
        october: 'Out',
        november: 'Nov',
        december: 'Dez',
        total: 'Total',
      },
    },
    forgotPassword: {
      title: 'Recuperar senha',
      description: 'Recuperar senha do usuário',
      codeSentMessage: 'O código foi enviado para o seu e-mail',
      passwordChangedMessage: 'Senha alterada com sucesso',
      fields: {
        title: 'Recuperar senha',
        titleChange: 'Alterar senha',
        username: {
          label: 'Nome de usuario',
          messageRequired: 'Por favor, informe seu nome de usuário',
        },
        email: {
          label: 'Email',
          messageRequired: 'Por favor, informe seu e-mail',
          messageValidity: 'Por favor, informe um e-mail válido',
        },
        confirmationCode: {
          label: 'Código de confirmação',
          messageRequired: 'Por favor, digite o código que você recebeu em seu e-mail',
        },
        btnRetrievePW: 'Recuperar senha',
      },
    },
    home: {
      description: 'Página inicial',
      title: 'Home',
    },
    login: {
      docTitle: 'Login',
      title: 'Entre com login e senha',
      description: 'Página de login',
      fields: {
        title: 'Entrar',
        username: {
          label: 'Nome',
          messageRequired: 'Por favor, informe seu nome',
        },
        email: {
          label: 'E-mail',
          messageRequired: 'Por favor, informe seu e-mail',
          messageValidity: 'Por favor, informe um e-mail válido',
        },
        password: {
          label: 'Senha',
          messageRequired: 'Por favor, informe sua senha',
        },
        remember: {
          label: 'Lembre de mim',
        },
      },
      linkForgotPassword: 'Esqueci minha senha',
    },
    groups: {
      title: 'Grupos',
      addGroup: 'Adicionar Grupo',
      editGroup: 'Editar Grupo',
      fields: {
        name: 'Nome',
        created: 'Criado em',
        updated: 'Atualizado em',
      },
    },
    reports: {
      scenarios: {
        title: 'Relatório de Cenários',
        titleMenu: 'Cenários',
        fields: {
          sku: 'SKU',
          spec: 'Especificação',
          componentAmount: 'Quantidade',
          partType: 'Categoria',
          partNumber: 'Part Number',
          local: 'Fonte',
        },
      },
    },
    user: {
      title: 'Usuários',
      description: 'Página do Usuário',
      addUser: 'Adicionar Usuário',
      editUser: 'Editar Usuário',
      fields: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefone',
        group: 'Grupo',
        enabled: 'Habilitado',
        disabled: 'Desabilitado',
        status: 'Status',
        password: 'Senha',
        passwordTemp: 'Senha Temporária',
        passwordMessage:
          'A senha deve conter maiúsculas, minúsculas, número e pelo menos 8 caracteres',
        created: 'Criado em',
        updated: 'Atualizado em',
      },
      registerPasswordForm: {
        title: 'Cadastrar Senha',
        titleFirst: 'Registre uma nova senha <br /> para seu primeiro login',
        username: {
          label: 'Nome de Usuário',
        },
        current: {
          label: 'Antiga senha',
          messageRequired: 'Por favor, informe sua Antiga senha',
        },
        password: {
          label: 'Nova Senha',
          messageRequired:
            'A senha deve conter letra maiúscula, minúscula, número e pelo menos 8 caracteres',
        },
        confirm: {
          label: 'Confirme a senha',
          messageRequired: 'Por favor, confirme a senha',
          messageDontMatch: 'As novas senhas não conferem',
        },
      },
    },
    suppliers: {
      title: 'Fornecedores',
      fields: {
        name: 'Nome do Fornecedor',
        shortName: 'Nome Resumido',
        code: 'Código',
        state: 'Unidade Federal',
        action: 'Componentes',
      },
    },
    ppb: {
      fields: {
        description: 'Descrição',
        partTypes: 'Categoria',
        maxScore: 'Score Max',
        bonusStage: 'Bônus',
        calcBase: 'Base Cálculo',
        taxOrigins: 'Origem Taxa',
        ignoreSKUs: 'SKUs Ignorados',
        countSubComponent: 'Conta Subcomponente',
        notCountSubComponent: 'Não conta Subcomponente',
        variablesScore: {
          title: 'Variáveis de Pontuação',
          critical: 'Crítico',
          highRisk: 'Alto risco',
          good: 'Bom',
          min: 'Min',
          max: 'Max',
          minError: 'Min < Max',
          criticalMaxError: 'Max Critico < Min Alto risco',
          highRiscMaxError: 'Max Alto risco < Min Bom',
          saveError: 'Preencha uma Variável de pontuação com max e min!',
          fieldsError: 'Para continuar, corrija os valores incorretos!',
        },
      },
    },
    ppbProducts: {
      title: 'Produtos PPB',
      fields: {
        product: 'Produto',
        productName: 'Nome do Produto',
        description: 'Descrição',
        targetScore: 'Pontuação',
        inForceDate: 'Vigente a partir de',
        publishedDate: 'Data de Publicação',
        modified: 'Data de Atualização',
        note: 'Notas',
      },
    },
    prices: {
      title: 'Preços',
      showPrices: 'Mostrar Preços',
      hidePrices: 'Esconder Preços',
      showPricesAlert: 'Você tem certeza que deseja mostrar os preços?',
      hidePricesAlert: 'Você tem certeza que deseja esconder os preços?',
      checkPassword: 'Senha incorreta',
      fields: {
        applyMultiple: 'Editar Preço em Lote',
        price: 'Preço',
        partNumber: 'Part Number',
        supplier: 'Fornecedor',
        spec: 'Especificação',
        local: 'Local',
        password: 'Senha',
      },
    },
    factors: {
      title: 'Fatores',
      fields: {
        applyMultiple: 'Editar Fator em Lote',
        factor: 'Fator',
        supplier: 'Fornecedor',
        spec: 'Especificação',
        local: 'Local',
      },
      views: {
        view: 'Visualização',
        prices: 'Preços',
        factors: 'Fatores',
        priceFactorGap: 'Preços, Fatores e Gaps',
        priceFactor: 'Preços e Fatores',
        groupBySupplier: 'Agrupar por Fornecedor',
        groupBySpec: 'Agrupar por Especificação',
      },
    },
    bom: {
      editForm: 'Editar BOM',
      registerForm: 'Cadastrar BOM',
      title: 'BOM',
      noMotherboard: 'SEM MOTHERBOARD',
      fields: {
        project: 'Projeto',
        model: 'Modelo',
        modelName: 'Nome Modelo',
        sku: 'SKU',
        spec: 'Especificação',
        amount: 'Quantidade',
        partType: 'Categoria',
        CT: 'T.C',
        partNumber: 'Part Number',
        PN: 'P.N',
        local: 'Local',
        description: 'Descrição',
      },
    },
    scenarios: {
      title: 'Cenários',
      titleSingular: 'Cenário',
      subtitle1: 'Cenários criados pelo usuário',
      subtitle2: 'Cenários base - Automáticos de acordo com o Forecast',
      register: 'Criar Cenário',
      registerUpdate: 'Atualizar Cenário',
      registerUser: 'Criar Cenário de Usuário',
      registerUserUpdate: 'Atualizar Cenário de Usuário',
      tableTitle: 'Tabelas do Cenário',
      simulate: 'Simular',
      copySimple: 'Copiar',
      deleteSimple: 'Deletar',
      copy: 'Copiar Cenário',
      delete: 'Deletar Cenário',
      factor: 'Fator',
      step1: 'Passo 01 - Cenário Base',
      step2: 'Passo 02 - Preço + Fator',
      nextStep: 'Próximo Passo',
      prevStep: 'Passo Anterior',
      settings: {
        title: 'Configurações de Cenário',
        specificRules: 'Regras específicas',
        tooltip: 'Configurações de Cenário',
        details: 'Ver detalhes da regra específica.',
        componentPurchaseMaxLabel: 'Máximo - Componente x Fornecedor',
        componentPurchaseMax:
          'Estabelecer uma quantidade máxima de compra de determinado componente com determinado fornecedor.',
        componentPurchaseMinLabel: 'Mínimo - Componente x Fornecedor',
        componentPurchaseMin:
          'Estabelecer uma quantidade mínima de compra de determinado componente com determinado fornecedor.',
        componentPurchaseNotNationalLabel: 'Restrição Componentes Nacionais',
        componentPurchaseNotNational:
          'Definir quais componentes não são mais comprados no mercado nacional.',
        componentWithoutPriceLabel: 'Simular Sem Preço',
        componentWithoutPrice: 'Definir simulação sem preço.',
        componentWithoutQGLabel: 'Simular com componentes com Qualificação em andamento',
        componentWithoutQG: 'Set simulação com componentes com Qualificação em andamento',
        fields: {
          partType: 'Categoria',
          spec: 'Especificação',
          partNumber: 'Part Number',
          description: 'Descrição',
          supplier: 'Fornecedor',
          amount: 'Quantidade limite',
          amountMax: 'Quantidade Máx.',
          amountMin: 'Quantidade Mín.',
        },
      },
      fields: {
        targetYear: 'Ano Alvo',
        version: 'Versão',
        name: 'Nome do Cenário',
        base: 'Cenário Base',
        targetScore: 'Pontuação Alvo',
        currentScore: 'Pontuação Atual',
        selectPrice: 'Selecione a Tabela de Preço',
        selectFactor: 'Selecione a Tabela de Fator',
        addPrice: 'Adicionar Tabela de Preço',
        addFactor: 'Adicionar Tabela de Fator',
        modified: 'Última Atualização',
        created: 'Criação em',
        newScenario: 'Título Novo Cenário do Usuário',
        updateScenario: 'Título do Cenário do Usuário',
        ppbRule: 'Tabela PPB',
        titlePrice:
          'Escolha um <strong>cenário modelo</strong> <span>ou</span> inclua as tabelas de <strong>preços e fatores</strong> a serem usadas no novo cenário:',
        titlePriceUpdate:
          'Escolha um <strong>cenário modelo</strong> <span>ou</span> inclua as tabelas de <strong>preços e fatores</strong> a serem usadas neste cenário:',
      },
    },
    simulation: {
      title: 'Simulação',
      isRefreshing: 'Atualizando simulação...',
      loading: 'Carregando simulação',
      refreshMessage: 'Atualização de cenário de usuário disponível',
      refreshButton: 'Atualizar',
      refreshTooltip:
        'BOM, Preço ou Forecast foram atualizados. Clique em atualizar para verificar a última simulação disponível.',
      refreshConfirm: 'Deseja atualizar o cenário?',
      tableSummary:
        'Resumo com total e percentual em PPB, Quantidade e Simulação e por agrupamento',
      tooltipCurrentScore: 'Cálculo Atingido',
      tooltipMaxScore: 'Máximo Atingível',
      tooltipPartTypeMaxScore: 'Regra PPB',
      label: {
        product: 'Produto',
        cm: 'CM',
        project: 'Projeto',
        category: 'Categoria',
        targetYear: 'Ano Alvo',
        scenario: 'Cenário',
      },
      placeholder: {
        product: 'Selecionar Produto',
        cm: 'CM',
        project: 'Selecionar Projeto',
        category: 'Selecionar Categoria',
        targetYear: 'Selecionar Ano Alvo',
        scenario: 'Selecionar Cenário',
      },
      fields: {
        typeSpec: 'Tipo<br />Spec',
        month: 'Mês',
        points: 'Pontos',
        baseForecast: 'Forecast Base',
        baseScenario: '<span>Simulador Automático <br /> Cenário do Usuário</span>',
        userForecast: 'Forecast Usuario',
        userScenario: '<span>Simulador Manual <br /> Cenário do Usuário</span>',
        category: 'Categoria',
        costTotal: 'Custo Total',
        totalGap: 'Gap Total',
        costPerPoint: 'Custo Por Ponto',
        score: 'Pontos',
        scoreModal: 'Pontos<br />Total | Acc',
        titleUserScenario: '2021 teste <br /> (1.0)',
        titleBaseScenario: 'CEB <br /> (33.0)',
        titleInvest: 'Invest/Pontos ($)',
        titleCostGap: 'Gap de custo ($)',
        titleCostTotal: 'Custo Total ($)',
        kcTotal: 'KC Total ($)',
      },
      fieldsHeader: {
        partType: 'Categoria',
        month: 'Mês',
        fgDemand: 'FG Demanda',
        base: 'Base',
        user: 'Usuário',
        kc: 'KC',
        totalQty: 'Total Qtd',
        PPB: 'PPB (%)',
        currentScore: 'Score Total',
        totalCost: 'Custo Total ($)',
        totalGap: 'Gap Total ($)',
        score: 'Score',
      },
    },
    score: {
      title: 'Score',
    },
    sku: {
      title: 'SKU',
      fields: {
        sku: 'SKU',
        projectName: 'Nome do Projeto',
        modelName: 'Nome do Modelo',
        created: 'Criado em',
        createdBy: 'Criado por',
        updated: 'Atualizado em',
        updatedBy: 'Atualizado por',
      },
      filters: {
        product: 'Produto',
        productPlaceholder: 'Selecionar Produto',
        cm: 'CM',
        cmPlaceholder: 'Selecionar CM',
        project: 'Projeto',
        projectPlaceholder: 'Adicionar Nome Projeto',
        sku: 'SKU',
        skuPlaceholder: 'Selecionar SKU',
        modelName: 'Nome do Modelo',
        modelNamePlaceholder: 'Selecionar Nome do Modelo',
        otherProducts: 'Outro',
      },
    },
    avlc: {
      title: 'AVLC',
      fields: {
        projectName: 'Nome do Projeto',
        partType: 'Categoria',
        spec: 'Especificação',
        partNumber: 'Número do Componente',
        cm: 'CM',
        cmPlaceholder: 'Selecionar CM',
        product: 'Produto',
        productPlaceholder: 'Selecionar Product',
        registeredComponent: 'Componente Registrado',
        qStatus: 'Status Q',
        project: 'Projeto',
        description: 'Descrição',
        modelNumber: 'Número do Modelo',
        odm: 'ODM',
        odmSample: 'Amostra ODM Recebida',
        odmActual: 'Amostra ODM Recebida de Fato',
        location: 'Fonte',
        vendor: 'Fabricante',
        priority: 'Prioridade',
        avlcType: 'Tipo AVLC',
        purpose: 'Motivo da Peça',
        remark: 'Remarcar',
        lock: 'Travado',
        firstPlanDate: '1a Data Planejada',
        rdPlanFinishDate: 'Data de Finalização RD (planejada)',
        rdActualFinishDate: 'Data de Finalização RD de Fato',
        adjustDate: 'Data Final Ajustada',
        uploadTime: 'Hora do Upload',
        uploadUser: 'Usuário do Upload',
        family: 'Family',
        partNumberTooltip: 'Esse número do componente não está registrado como um componente',
        subComponents: 'Sub Componente',
        subComponentsPlaceholder: 'ADD QTY',
        dataRegistration: 'Registro de Dados',
        modalName: 'Add Componente',
      },
      filters: {
        project: 'Projeto',
        projectPlaceholder: 'Add Nome Projeto',
        category: 'Categoria',
        categoryPlaceholder: 'Add Categoria',
        specification: 'Especificação',
        specificationPlaceholder: 'Add Especificação',
        partNumber: 'Número do componente',
        partNumberPlaceholder: 'Add número do componente',
        source: 'Origem',
        sourcePlaceholder: 'Add Origem',
        manufacturer: 'Fabricante',
        manufacturerPlaceholder: 'Add Fabricante',
        status: 'Q-Status',
        statusPlaceholder: 'Selecionar Status',
        noRegistryComponent: 'Não registrado como componente',
      },
    },
    spot: {
      title: 'Estratégia Spot',
      fields: {
        groupName: 'Nome do Grupo',
        local: 'BR',
        import: 'FOB',
        currentScore: 'Pontuação atual',
        maxScore: 'Pontuação Máx.',
        points: 'pontos',
        totalScore: 'Pontuação total',
      },
    },
    alert: {
      title: 'Listagem de Alertas',
      menuTitle: 'Alertas',
      fields: {
        alerts: 'Alertas',
        alert: 'Alerta',
        day: 'dia',
        description: 'Descrição',
        activate: 'Ativar Alerta',
        date: 'Data',
        time: 'Hora',
        frequency: 'Frequência',
        frequencyImmediately: 'Sempre que ocorrer',
        actions: 'Ações',
        ShowFileRemarks: 'Mostrar comentários do arquivo',
        users: 'Selecione os usuários que irão receber a notificação',
        sendEmail: 'Enviar notificação por email?',
        editFrequency: 'Frequência (em dias) de envio do alerta',
        emailMsg: 'Mensagem do email (150 caracteres)',
        no: 'Não',
        yes: 'Sim',
        forAll: 'Para Todos',
        forSome: 'Para Alguns',
      },
      validate: {
        contact: 'Um dos contatos',
        contacts: 'Ao menos 1 contato',
        emailMsg: 'Mensagem do email',
        emailInvalid: 'não é um email válido',
      },
    },
    skuAlert: {
      title: "SKU's sem Motherboard",
      fields: {
        found: 'encontrado(s)',
        sku: 'SKU',
        projectName: 'Projeto',
        modelName: 'Modelo',
      },
    },
    notifications: {
      title: 'Notificações',
      fields: {
        alert: 'Alerta',
        dateTime: 'Data - Hora',
      },
    },
    dashboard: {
      title: 'Tendência de custo',
      titleSku: 'SKU | Demanda x Ocorrência',
      titleShareProportion: 'Proporção Ações | Quantidade',
      titleNavShareProportion: 'Proporção Ações',
      labelProduct: 'Produto',
      placeholderProduct: 'Selecionar Produto',
      labelCM: 'CM',
      placeholderCM: 'Selecionar CM',
      labelProject: 'Projeto',
      placeholderProject: 'Selecionar Projeto',
      labelComponents: 'Categorias',
      placeholderComponents: 'Selecionar Componentes',
      labelYear: 'Ano',
      fields: {
        title: 'Proporção Ações - Quantidade',
        costTrend: 'Demanda x Ocorrência',
        month: 'Mês',
        cost: 'Custo',
        occurred: 'Ocorrido',
        demands: 'Demandas',
        forecast: 'Demanda',
        scenario: 'Cenário',
        newProjects: 'Novos Projetos',
        finishedProjects: 'Projetos Finalizados',
        projectName: 'Projeto',
        modelName: 'Modelo',
        scorePerformed: 'Pontuação Realizada',
        accumulatedScore: 'Pontuação Acumulada',
        topList: 'Top',
        componentTypes: 'Tipos de Componente',
        category: 'Categoria',
        months: 'Meses',
        projects: 'Projetos',
        components: 'Categorias',
      },
    },
    finance: {
      dashboardNavbar: 'Painéis',
      title: 'Financeiro',
      uploadFiles: {
        menuTitle: 'Carregar Arquivos',
        title: 'Planilhas',
        newUpload: 'Novo Upload',
        from: 'De',
        labelDate: 'Data',
        labelPeriod: 'Período',
        labelListType: 'Tipo de lista',
        labelResposible: 'Responsável',
        labelStatus: 'Status',
        listPlaceHolder: 'Selecione o Tipo de Lista',
        datePlaceHolder: 'Selecione a data',
        periodPlaceHolder: 'Selecione o Período',
        uploadAgain: 'Carregar Novamente',
        notificationTitle: 'File import was successful!',
        notificationBody: 'The data is being processed and will be available in a few minutes',
        fileSuccessTitle: 'Processamento concluído!',
        fileSuccessTBody: 'O arquivo foi importado com sucesso!',
        fileProcessingTitle: 'Arquivo em processamento',
        fileProcessingBody:
          'Em alguns instantes os dados importados estarão disponíveis no sistema',
        layoutErrorTitle: 'Erro de Layout',
        layoutErrorBody:
          'Identificamos que o layout do seu arquivo não está alinhado com os padrões requeridos pelo sistema. Para continuar,  ajuste os dados no arquivo e faça o upload da sua versão atualizada.',
        formatErrorTitle: 'Incompatibilidade de Formato de Arquivo',
        formatErrorBody:
          'Parece que o formato do seu arquivo não é compatível com o padrão reconhecido pelo sistema. Para prosseguir, certifique-se de utilizar um dos formatos aceitos: .xls, .xlsx.',
        fields: {
          cm: 'CM',
          listType: 'Tipo de Lista',
          date: 'Data',
          period: 'Período',
          hour: 'Horário',
          importedDate: 'Data Importada',
          responsible: 'Responsável',
          uploadFile: 'Arquivos carregados',
          status: 'Status',
        },
        filterStatusOptions: {
          success: 'Sucesso',
          processing: 'Processando',
          error: 'Erro',
        },
      },
      dashboard: {
        titleFinance: 'Painel Finance',
        titleProcurement: 'Painel Procurement',
        labelProduct: 'Produto',
        placeholderProduct: 'Selecione um Produto',
        labelPeriod: 'Period',
        labelCM: 'CM',
        placeholderCM: 'Selecione um CM',
        labelDate: 'Data',
        placeholderLevel: 'Selecione o Nível',
        labelLevel: 'Nível',
        placeholderProject: 'Selecionar Projeto',
        labelProject: 'Projeto',
        applyButton: 'Aplicar',
        labelType: 'Tipo',
        placeholderType: 'Selecione um Tipo',
        labelListType: 'Tipo de Lista',
        placeholderListType: 'Selecione Tipo de Lista',
        headerTitles: {
          projectName: 'Nome do Projeto',
          unit: 'Unidade',
          monthDemand: 'Demanda do Mês',
        },
        table: {
          labels: {
            projectName: 'Nome do Projeto',
          },
          placeholders: {
            projectName: 'Adicionar Projeto',
            sku: 'Adicionar SKU',
          },
        },
        titleCards: {
          firstCard: 'Custos de SKU',
          secondCard: 'Demanda Por Mês',
          thirdCard: 'Negociado vs Executado',
        },
        skuComponents: {
          labels: {
            category: 'Selecione a Categoria',
            lowerPrice: 'Menor Preço',
            higherPrice: 'Preço Mais Alto',
            seeQuotes: 'Ver  Cotações',
          },
          table: {
            headers: {
              category: 'Categoria',
              source: 'Origem',
              cmQuote: 'CM Cotação',
            },
          },
        },
        supplierCostTrend: {
          titleCard: 'Tendência de Custos',
          filters: {
            placeholder: {
              category: 'Selecione Categoria',
            },
          },
        },
        startvsend: {
          start: 'Início',
          end: 'Fim',
          legendStart: 'Estoque inicial',
          legendEnd: 'Estoque final',
          selectCategory: 'Selecionar categoria',
        },
      },
    },
    procurement: {
      dashboard: {
        labelCM: 'CM',
        table: {
          labels: {
            projectName: 'Nome do Projeto',
          },
          placeholders: {
            projectName: 'Adicionar Projeto',
            sku: 'Adicionar SKU',
          },
        },
        headerTitles: {
          projectName: 'Nome do Projeto',
          unit: 'Unidade',
          monthDemand: 'Demanda do Mês',
        },
        labelsGraphDemandPerMonth: {
          before: 'Meses Anteriores',
          current: 'Este Mês',
          after: 'Próximos Meses',
        },
      },
      chart: {
        placeHolder: {
          componentGroup: 'Grupo Componentes',
          spec: 'Especificação',
          partNumber: 'Add N° Componente',
          source: 'Fonte',
          supplier: 'Fornecedor',
          month: 'Mês',
        },
        chartTitle: {
          unitPrice: 'PREÇO UNITÁRIO',
          totalInvoice: 'TOTAL FATURADO',
        },
        chartBarsLabel: {
          negotiated: 'Negociado',
          executed: 'Executado',
          gap: 'Gap',
        },
      },
      supplierPriceCostTrend: {
        cardTitle: {
          supplierPrices: 'Supplier Prices',
          costTrend: 'Cost Trend',
        },
        placeHolder: {
          specification: 'Especificação',
          projectName: 'Nome do Projeto',
        },
      },
      blended: 'BLENDED',
      average: 'Average',
      priceBook: 'Price Book',
      usd: 'USD',
      noLegend: 'Sem Legendas',
      shareProportion: 'Share Proportion',
    },

    taxes: {
      title: 'Configuração de Impostos',
      addTax: 'Adicionar taxa',
      labels: {
        linkTo: 'Link para',
        logistic: 'Logística',
      },
      fields: {
        componentType: 'Categoria',
        from: 'De',
        to: 'Para',
        ipi: 'IPI %',
        icms: 'ICMS%',
        pisCofins: 'PIS/COFINS %',
        periodFrom: 'Período Inicial',

        periodTo: 'Período Final To',
        recoveryPisCofins: ' Recuperação PIS/COFINS %',
        recoveryICMS: ' Recuperação IPI %',
        recoveryIpi: ' Recuperação ICMS%',
        recoveryRd: 'Recuperação R&D %',
        period: 'Período',
      },
      placeholders: {
        component: 'Todos os tipos de componente',
        from: 'De',
        to: 'Para',
      },
    },

    pricesTaxes: {
      title: 'Preços de impostos',
      fields: {
        cm: 'CM',
        cmPlaceholder: 'Selecionar CM',
        category: 'Categoria',
        categoryPlaceholder: 'Add Categoria',
        source: 'Origem',
        sourcePlaceholder: 'Selecionar Origem',
        spec: 'Especificação',
        specPlaceholder: 'Add Especificação',
        partNumber: 'Número do Componente',
        partNumberPlaceholder: 'Add Número do Componente',
        supplier: 'Fornecedor',
        supplierPlaceholder: 'Add Fornecedor',
        logistic: 'Logística',
        from: 'De',
        to: 'Para',
        grossPrice: 'Preço, Gross, $',
        ipi: 'IPI,%',
        icms: 'ICMS,%',
        pisCofins: 'PIS/COFINS,%',
        netPrice: 'Preço,Líquido,$',
        recovery: 'Recuperação',
        costAfterCredit: 'Custo,Após Crédito,$',
        action: 'Ações',
        period: 'Período',
      },
      fieldsCsv: {
        grossPrice: 'Preço Gross',
        ipi: 'IPI',
        icms: 'ICMS',
        pisCofins: 'PIS/COFINS',
        netPrice: 'Preço Líquido',
        costAfterCredit: 'Custo Após Crédito',
      },
    },

    quotes: {
      title: 'Cotações',
      tabTitles: {
        quotes: 'COTAÇÕES',
        stocks: 'AÇÕES',
      },
      titleFilters: 'Escolha as opções abaixo para trazer as informações especificadas',
      labels: {
        product: 'Produto',
        cm: 'CM',
        date: 'Período',
        project: 'Projeto',
        sku: 'SKU',
        level: 'Level',
        amount: 'Quantidade',
        quoteFiles: 'Arquivos de cotação',
        estimatedDemand: 'Demanda estimada',
        withDemand: 'Com Demanda',
        emptyTooltip: 'N/A',
      },
      tableHeaders: {
        projectModelName: 'Nome do Modelo do Projeto',
        materialFob: 'Total de Material FOB Importado                                    II',
        cpu: 'CPU',
        chipset: 'ChipSet',
        vga: 'VGA',
        vram: 'VRAM',
        memory: 'Memória',
        hdd: 'HDD',
        freightCkd: 'Frete marítimo para material CKD',
        insuranceMaterial: 'Seguro para material CKD',
        materialLanded: 'Total de Material Importado Desembarcado',
        importTax: 'Imposto de Importação',
        suframaTax: 'Imposto Suframa - TCIF',
        otherExpenses: 'Outras despesas de importação',
        localBuy: 'Local de compra',
        localBuyIpiRebate:
          'Local de compra                                                         IPI Rebate %',
        totalCost: 'Custo Total de Materiais',
        net: 'Rede',
        netSalesPrice: 'Priço Líquido de Venda',
        grossSellingPrice: 'Preço de Venda Bruto',
        emsManufacturingCost: 'Custo de fabricação EMS',
        mvaUsdPart: 'Parte MVA USD',
        finance: 'Finanças',
        hedgeDto: 'Cobertura',
        freigthJundiaiAcer: 'Freight from Manaus to Jundiaí',
        projectInvestiments: 'Investimento em Projeto / NR',
        nationalCost: 'Custo Nacional / Descontos / Compensações',
        PPBBenefit: 'Benefícios PPB',
        RD: 'R&D',
        adapter: 'Adaptador',
        ee: 'EE',
        me: 'ME',
        wifi: 'WIFI',
        ssd: 'SSD',
        lcm: 'LCM',
        battery: 'Bateria',
        icms: 'ICMS',
        icmsst: 'ICMS-ST',
        pis: 'PIS',
        cofins: 'Cofins',
        ipi: 'IPI',
        importCofins: 'Cofins de Importação ( 1% )',
        packingLocal: 'Embalagem Local',
        packingMaterial: 'Embalagem Material',
        packing: 'Embalagem',
        motherboard: 'Placa Mãe de Manaus',
        pisCofins: 'Crédito de PIS/Cofins do MB de Manaus',
        ipiCredit: 'Crédito de IPI do MB de Manaus',
        mvaBrlPart: 'MVA BRL Parte convertida em USD',
        mvaSuportCompalModels: 'Suporte MVA para modelos Compal',
        others: 'Investimentos  por Projeto Invest. / NRE',
        icmsRebate: 'Desconto de ICMS',
        pisCofinsIcmsRebate: 'PIS&COFINS Over ICMS Rebate',
      },

      placeholders: {
        product: 'Add Produto',
        cm: 'Add CM',
        sku: 'Add SKU',
        date: 'Selecione o Período',
        project: 'Add projeto',
        level: 'Add Level',
        foreCast: 'Com forecast',
      },
      titleButons: {
        apply: 'Aplicar',
      },
    },

    average: {
      title: 'Médias',
      requiredFieldMessages: {
        date: 'O período é obrigatório!',
        partNumber: 'O Part Number é obrigatório!',
      },
      filtersLabels: {
        date: 'Período',
        cm: 'CM',
        project: 'Projeto',
        sku: 'SKU',
        category: 'Categoria',
        level: 'Level',
        source: 'Fonte',
        partNumber: 'Número da Componente',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        qtyPurchase: 'Purchase',
        price: 'Price',
        invNo: 'Inv. No.',
        invDate: 'Inv. Date',
        weight: 'Weight',
        average: 'Average',
        quote: 'Quote',
        demand: 'Demand',
        total: 'Total',
        current: 'Current',
        before: 'Before',
        use: 'Use',
        inventory: 'Inventory',
      },
    },

    newDemand: {
      title: 'Demandas',
      titleModal: 'Detalhes do SKU',
      totalizer: 'Total do Mês',
      tableName: 'Demandas',
      modalFields: {
        components: 'Componentes',
        projectName: 'Nome do Projeto',
        cm: 'CM',
        description: 'Descrição',
        adapters: 'Adaptadores',
        batteries: 'Baterias',
        memory: 'Memorias',
        ssd: 'SSD',
        wirelessLan: 'Rede Sem Fio',
        vram: 'VRAM',
        modelName: 'Nome do Modelo',
        messageRequired: 'Valor',
      },
      filters: {
        product: 'Produto',
        cm: 'CM',
        year: 'Year',
        sku: 'SKU',
        project: 'Projeto',
      },
      placeholders: {
        product: 'Selecione o Produto',
        cm: 'Seleciono CM',
        year: 'Year',
        sku: 'Add SKU',
        project: 'Add Nome do Projeto',
      },
      titleButtons: {
        apply: 'Aplicar',
      },
      tableHeaders: {
        sku: 'SKU',
        model: 'Modelo',
        total: 'Total',
        project: 'Projeto',
      },
      noInformation: 'Não informado',
    },

    componentsPrices: {
      Title: 'Preços dos Componentes',
      alertDateError: 'Os trimestres devem ser diferentes!',
      modal: {
        title: 'Seus dados estão prontos!',
        message:
          'Você precisa atualizar a página para visualizá-la.Você gostaria de fazer isso agora?',
      },
      titleButtons: {
        apply: 'Aplicar',
      },
      tabTitles: {
        prices: 'Preços',
        packing: 'Embalagem',
      },
      labels: {
        category: 'Categoria',
        partNumber: 'Número do Componente',
        specification: 'Especificação',
        supplier: 'Fornecedor',
        source: 'Fonte',
        quarter: 'Semestre',
        period: 'Periodo',
        cm: 'CM',
      },
      placeHolders: {
        category: 'Selec. Categoria',
        partNumber: 'Add Part Number',
        specificaton: 'Add Especificação',
        supplier: 'Add Fornecedor',
        source: 'Selec. Fonte',
        quarter: 'Add Semestre',
        cm: 'Selec. CM',
        startQuarter: 'Trimestre Início',
        endQuarter: 'Trimestre Fim',
      },
      tableHeaders: {
        components: 'Componentes',
        partNumber: 'Número do Componente',
        specification: 'Especificação',
        supplier: 'Fornecedor',
        source: 'Fonte',
        subHeader: {
          gross: 'Bruto',
          netCost: 'Líquido',
        },
      },
    },

    packingPrices: {
      title: 'Embalagem',
      total: 'Totais',
      filters: {
        cm: 'CM',
        project: 'Projeto',
        PartNumber: 'Número da peça',
        spec: 'Especificação',
        quarter: 'Quarter',
      },
      tableHeaders: {
        logo: 'Logo',
        PartNumber: 'Número da peça',
        description: 'Descrição',
        qtyPer: 'QTY per',
        source: 'Fonte',
        netPrice: 'Líquido',
        gross: 'Bruto',
        taxes: 'Taxas',
      },
      placeholders: {
        cm: 'Selecione CM',
        project: 'Add Nome do Projeto',
        parNumber: 'Add Número da peça',
        specification: 'Add Especificação',
        quarter: 'Selecione Quarter',
      },
      error: {
        quarter: 'informe o quarter',
      },
      tooltips: {
        na: 'N/A',
        exRate: 'Total ExRate',
      },
    },

    financePage: {
      labels: {
        quuotesPrice: 'Preço de Cotações',
        monthYear: 'Mês | Ano',
        category: 'Categoria',
        cm: 'CM',
        source: 'Fonte',
        quotes: 'Cotações',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        demand: 'Demanda',
        qty: 'QTY',
        unitPrice: 'Preço Unitário',
        totalInvoiceFbr: `Fatura Total
        FBR
        `,
        ii: 'II%',
        cofins: 'COFINS%',
        cif: 'CIF',
        vendorName: 'Nome do Vendedor',
      },
    },

    inventory: {
      title: 'Inventário',
      mandatoryField: 'Apenas Números',
      noContent: 'Não há dados disponíveis para a data selecionada. Por favor, tente outra data.',
      labels: {
        product: 'Produto',
        cm: 'CM',
        category: 'Categoria',
        specification: 'Especificação',
        partNumber: 'Part Number',
        source: 'Fonte',
        supplier: 'Fornecedor',
        date: 'Data',
        apply: 'Aplicar',
      },
      placeholders: {
        product: 'Selecione Produto',
        cm: 'Selecione CM',
        category: 'Add Categoria',
        specification: 'Add Especificação',
        partNumber: 'Add Part Number',
        source: 'Selecione Fonte',
        supplier: 'Add Fornecedor',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        specification: 'Especificação',
        project: 'Projeto',
        cm: 'CM',
        source: 'Fonte',
        supplier: 'Fornecedor',
        totalOnHand: 'Total em Mãos',
        allocated: 'Alocado para ordens de produção',
        freeQty: 'Livre QTY',
        value: 'Valor',
        valueUsd: '(USD)',
      },
      titlesTab: {
        inventoy: 'Inventário',
        inOut: 'Entrada/Saída',
      },
    },

    inOut: {
      modalTitle: 'Gráficos IN/OUT',
      partNumbers: 'Part Numbers',
      labels: {
        cm: 'CM',
        product: 'Produto',
        category: 'Categoria',
        specification: 'Especificação',
        source: 'Fonte',
        partNumber: 'Part Number',
        month: 'Mês',
        description: 'Descrição',
      },
      placeholders: {
        cm: 'Selecione CM',
        product: 'Selecione Produto',
        category: 'Add Categoria',
        specification: 'Add Especificação',
        source: 'Selecione Fonte',
        partNumber: 'Add Part Number ',
      },
      tablesHeaders: {
        partNumber: 'Part Number',
        specification: 'Especificações',
        source: 'Fonte',
        cmPn: 'CM PN',
        mba: 'MBA',
        receive: 'Recebido',
        return: 'Retorno',
        consumed: 'Consumido',
        scrap: 'Danificado',
        endMonth: 'Mês Final',
        currentMonth: 'Mês Corrente',
        nextMonth: 'Próximo Mês ',
        variance: 'Variânce',
        creditDebit: 'Crédito/Débito',
        tax: 'Taxa',
        total: 'Total',
        totalQuarter: 'Total Quarter',
      },
    },

    shareProportion: {
      title: 'Proporção de Ações',
      noContent: 'Não há dados disponíveis para a data selecionada. Por favor, tente outra data.',
      labels: {
        product: 'Produto',
        cm: 'CM',
        category: 'Categoria',
        specification: 'Especificação',
        partNumber: 'Part Number',
        source: 'Font',
        supplier: 'Fornecedor',
        date: 'Período',
        apply: 'Aplicar',
        project: 'Projeto',
      },
      placeholders: {
        product: 'Selecione Produto',
        cm: 'Selecione CM',
        category: 'Add Categoria',
        specification: 'Add Especificação',
        partNumber: 'Add Part Number',
        source: 'Selecione Fonte',
        supplier: 'Add Fornecedor',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        specification: 'Especificação',
        project: 'Projeto',
        cm: 'CM',
        source: 'Fonte',
        supplier: 'Fornecedor',
        totalOnHand: 'Total em Mãos',
        allocated: 'Alocado para ordens de produção',
        freeQty: 'Livre QTY',
        value: 'Valor',
        valueUsd: '(USD)',
      },
      modalHeaders: {
        qty: 'Total QTY',
        unitcost: 'Unit Cost',
        qStatus: 'Q-Status',
        risk: 'Risk',
      },
    },

    rfq: {
      title: 'RFQ',
      titleMenu: 'RFQ Overview',
      titleTabs: {
        open: 'Abertas',
        concluded: 'Concluídas',
      },
      viewData: 'Ver Dados',
      labels: {
        addNew: 'Add Novo',
        bidName: 'Nome Bid',
        category: 'Categoria',
        suppliers: 'Fornecedores',
        supplier: 'Fornecedor',
        start: 'Início',
        end: 'Fim',
        status: 'Status',
        actions: 'Ações',
      },
      placeholders: {
        bidName: 'Adicione nome do BID',
        category: 'Adicione categoria',
        supplier: 'Adicione Fornecedor',
        status: 'Selecione o Status',
      },
    },

    newRfq: {
      title: 'Novo RFQ',
      steps: {
        firstStep: {
          title: 'Cadastro de BID',
          description: 'Fornecer detalhes do RFQ',
          fields: {
            nameBID: 'Nome do BID',
            nameBIDMinCharacter: 'Mínimo de 2 caracteres',
            nameBIDMaxCharacter: 'Máximo de 70 caracteres',
            placeholderNameBID: 'Defina um nome para o BID',
            description: 'Descrição',
            placeholderDescriptionBID: 'Add uma descrição sobre o BID',
            BIDPeriod: 'Período do BID',
            startBIDPeriodPlaceholder: 'Data de ínicio',
            startBIDPeriodError: 'Data inicial não pode ser menor que data atual.',
            endBIDPeriodPlaceholder: 'Data de fim',
            priceInterval: 'Intervalo de preços',
            priceIntervalTooltip:
              'Definir o intervalo mínimo de variação de preços que pode ser apresentado',
            priceIntervalError: 'O intervalo de preços deve conter duas casas decimais',
          },
        },
        secondStep: {
          title: 'Cadastro de Componentes',
          description: 'Add componentes necessários',
          addComponent: 'Add Componente',
          compressedComponent: {
            edit: 'Editar',
            delete: 'Excluir',
            suppliers: 'Fornecedores',
            demandPeriod: 'Período de Demanda',
            startingPrice: 'Preço Inicial',
            totalDemand: 'Demanda Total',
          },
          fields: {
            altGroup: 'Grupo Alternativo',
            altGroupMinCharacter: 'Mínimo 2 caracteres',
            altGroupMaxCharacter: 'Máximo 25 catacteres',
            altGroupPlaceholder: 'Alt Group',
            category: 'Categoria',
            placeholderCategory: 'Selecionar Categoria',
            specification: 'Especificação',
            placeholderSpecification: 'Selecionar Especificação',
            projects: 'Projetos',
            project: 'Projeto',
            cm: 'CM',
            all: 'Todos',
            selected: 'Selecionados',
            searchPlaceholder: 'Busque aqui',
            qtd: 'Itens',
            partNumbers: 'Part Numbers',
            parNumber: 'Part Number',
            qStatus: 'Q-Status',
            supplier: 'Fornecedor',
            demandPeriod: 'Período de Demanda',
            startDatePlaceholder: 'Data Inicial',
            endDatePlaceholder: 'Data Final',
            from: 'De',
            to: 'Para',
            startingPrice: 'Preço Inicial',
            priceError: 'O preço deve conter duas casas decimais',
            placeholderStartingPrice: 'Add Preço',
            fobPrice: 'Preço FOB',
            fobPriceTooltip: 'Preço FOB atual dos artigos importados deste grupo',
            lastFOBPrice: 'último Preço FOB',
            lastFOBPriceTooltip: 'Último preço FOB para os artigos importados deste grupo',
          },
        },
        thirdStep: {
          title: 'Add Quantidades',
          description: 'Especificar quantidade dos componentes',
          deleteItemMessage: 'Você quer apagar este projeto?',
          label: {
            from: 'DE',
            to: 'PARA',
            periodDemand: 'PERÍODO DE DEMANDA',
            startingPrice: 'PREÇO INICIAL',
          },
          table: {
            project: 'Projeto',
            specification: 'Espeficiação',
            demand: 'Demanda',
          },
        },
        fourthStep: {
          title: 'Sumário de Supplier',
          description: 'Confirmar dados para envio ao fornecedor',
          compressedComponent: {
            edit: 'Editar',
            delete: 'Excluir',
            totalDemand: 'DEMANDA TOTAL',
            components: 'COMPONENTS',
          },
          fields: {
            categories: 'CATEGORIAS',
            specification: 'ESPECIFICAÇÃO',
            components: 'Componentes',
            totalDemand: 'Demanda total',
            selectCategory: 'Categoria',
            selectCategoryPlaceholder: 'Add Categoria',
            selectSpecification: 'Especificação',
            selectSpecificationPlaceholder: 'Add Especificação',
            table: {
              project: 'Projeto',
              cm: 'CM',
              altGroup: 'Alt Group',
              partNumber: 'Part Number',
              qStatus: 'Q-Status',
              from: 'De',
              to: 'Para',
              demand: 'Demanda',
            },
          },
          removePartNumberMessage:
            'Você quer remover esse item? Todos os dados do grupo serão removidos.',
          removeSupplierMessage:
            'Você quer remover esse fornecedor? A Todos os dados do grupo serão removidos.',
        },
        fifthStep: {
          title: 'Enviar',
          description: 'Enviar RFQ aos fornecedores',
          cardSubtitle: 'Convidado para o BID',
          contactName: 'Nome do Contato',
          email: 'E-mail',
          message: 'Mensagem',
          messageRequired: 'Mensagem é obrigatória',
          invitedRequired: 'Ao menos um fornecedor deve ser convidado',
          saveMessageSucess:
            "Sua mensagem e informações de contato foram salvas com sucesso. Elas serão enviadas ao fornecedor assim que você clicar em 'Enviar'",
          invitedLimit: 'Você só pode convidar até 5 fornecedores',
          modal: {
            title: 'Confrimação de Envio',
            congratulations: 'Parabéns! Você criou seu BID com sucesso.',
            selectedItens:
              'Os itens selecionados foram enviados aos fornecedores. Você será notificado assim que as cotações forem adicionadas.',
          },
        },
      },
      deleteCategoryModalTitle: 'Deletar Categoria',
      deleteCategoryModalDescription:
        'Deseja deletar essa categoria? Todos os dados do grupo serão removidos.',
      exitModalTitle: 'Sair do registro de RFQ',
      exitModalDescription:
        'Você está prester a sair do seu registro de RFQ. Deseja salvar seu progresso como um rascunho ou apagar todas as informações?',
      saveDraft: 'Salvar como rascunho',
      deleteAll: 'Apagar',
    },
    bidResponse: {
      title: 'Responder à licitação',
      subTitle: 'Preencha os dados necessários para enviar sua proposta.',
      labels: {
        project: 'PROJETO:',
        spec: 'ESPECIFICAÇÃO:',
        partNumber: 'NÚMERO DA PEÇA:',
        from: 'DE',
        to: 'PARA',
        startPrice: 'PREÇO INICIAL',
        demandPeriod: 'PERÍODO DE DEMANDA',
        totalDemand: 'TOTAL DEMANDA',
        demand: 'TOTAL DEMANDA',
        decline: 'Recusar participação nesta licitação',
        gross: 'Preço Bruto',
        capacity: 'Capacidade',
        leadTime: 'Tempo de Espera',
        comments: 'Comentários',
      },
      buttons: {
        place: 'Fazer o Lance',
        details: 'Ver mais detalhes',
        updateBid: 'Atualizar a licitação',
      },
      hints: {
        gross: 'Insira o preço bruto (incluindo impostos, sem IPI)',
        capacity: 'A capacidade da empresa de atender à demanda do grupo',
        leadTime: 'Tempo necessário para adquirir e entregar componentes',
      },
      messages: {
        titleDeclined: 'Participação recusada',
        subTitleDeclined:
          'Você recusou a participação neste BID e, portanto, não possui posição no ranking.',
        titleFirstPlace: 'Parabéns pelo 1º Lugar!',
        subTitleFirstPlace:
          'Seu BID está atualmente em 1º lugar no ranking. Continue com o excelente trabalho!',
        titleSecondPlace: 'Quase lá!',
        subTitleSecondPLace:
          'Ótimo trabalho! Seu BID está atualmente em 2º lugar no ranking. Reduza seus preços para alcançar o 1º lugar',
        titleThirdPlace: 'Suba nas classificações!',
        subTitleThirdPlace:
          'Bom trabalho! Seu BID está atualmente em 3º lugar no ranking. Abaixe seus preços para subir ainda mais!',
        titleOtherPlaces: 'Sua proposta pode ser mais competitiva',
        subTitleOtherPlaces:
          'Para aumentar suas chances de ganhar este BID, sugerimos revisar e ajustar seus preços. Você não está entre as propostas mais competitivas no momento.',
        rankingForbid: 'Ranking for this Bid',
        saveSuccess:
          'Lance salvo com sucesso. Você só o enviará para a Acer quando clicar em “Fazer o BID”.',
        validatePriceInterval:
          'Valor inválido! Utilize valores inferiores a sua última oferta em pelo menos  {{value}} a menos',
      },
      modalColumnLabels: {
        title: 'Divisão por projeto',
        project: 'Projeto',
        cm: 'CM',
        specification: 'Especificação',
        qStatus: 'Q-Status',
        partNumber: 'Número da peça',
        demand: 'Demanda',
      },
      altImages: {
        firstPlace: 'Imagem primeiro colocado',
        secondPlace: 'Imagem segundo colocado',
        thirdPlace: 'Imagem terceiro colocado',
        exclamationBlue: 'Exclamação azul',
        fourthPlace: 'Imagem fora do podium',
      },
    },
    viewRfq: {
      backButton: 'Visão Geral RFQ',
      title: 'Resumo do BID',
      tabs: {
        overview: 'Visão Geral',
        items: 'Itens',
      },

      bidSupplierRFQ: {
        Title: 'Resumo do BID',
        itemsTitle: 'Itens para Cotação',
        editBid: 'Editar BID',
        roundDetails: 'Detalhes da Rodada',
        potentialRevenue: 'RECEITA POTENCIAL',
        grossPrice: 'PREÇO BRUTO',
      },

      summary: {
        title: 'Informações Cadastradas do BID',
        supplierParticiation: 'PARTICIPAÇÃO DOS FONECEDORES',
        componentsQuoted: 'COMPONENTES COTADOS',
        timeToCloseTheBid: 'TEMPO PARA ENCERRAR BID',
        createdBy: 'CRIADO POR',
        nameBid: 'NOME DO BID',
        createdOn: 'CRIADO EM',
        description: 'DESCRIÇÃO',
        bidPeriod: 'PERÍODO DO BID',
      },
      steps: {
        openBid: 'Bid Aberto',
        roundOpen: 'Round Aberto',
        receiveQuotes: 'Receber Cotações',
        bidClosed: 'Bid Fechado',
        inReview: 'Em revisão',
        result: 'Resultado',
      },
      components: {
        cm: 'CM',
        cmPlaceholder: 'Selecionar CM',
        project: 'Projeto',
        projectPlaceholder: 'Add Projeto',
        specification: 'Especificação',
        specificationPlaceholder: 'Add Especificação',
        supplier: 'Fornecedor',
        supplierPlaceholder: 'Add Fornecedor',
      },
      openCard: {
        specification: 'ESPECIFICAÇÃO',
        cm: 'CM',
        from: 'DE',
        to: 'PARA',
        startingPrice: 'PREÇO INICIAL',
        totalDemand: 'DEMAND TOTAL',
        supplier: 'FORNECEDOR',
        partNumber: 'PART NUMBER',
        qStatus: 'Q-STATUS',
        capacity: 'CAPACIDADE',
        leadTime: 'PRAZO DE ENTREGA',
        grossPrice: 'PREÇO GROSS',
        lastGross: 'ÚLTIMO GROSS',
        gapGross: 'GAP GROSS',
        netPrice: 'PREÇO NET',
        totalNet: 'TOTAL NET',
        moreDetails: 'Mais detalhes',
        comments: 'Comentários',
        award: 'Award',

        tags: {
          bestPrice: 'Melhor preço',
          moreExpensive: 'Mais caro',
        },
      },
    },
    awaitAuth: {
      title: 'Você está sendo redirecionado para o ',
      redirect: 'Redirecionando',
    },
    qualificationAlert: {
      title: 'Alertas de Qualificação',
      headerTitle: 'Q-status atualização de QG para {{data}}',
      found: 'encontrado',
      fields: {
        project: 'Projeto',
        category: 'Categoria',
        specification: 'Especificação',
        partNumber: 'Número da peça',
        qSatus: 'Q-Status',
        remarks: 'Observações',
      },
    },
  },
  components: {
    modals: {
      addNewTax: {
        title: 'Adicionar Nova Taxa',
        description: 'Registro de Taxa',
        partType: 'Categoria',
        taxesGroup: 'Grupo de Impostos',
        selectComponentType: 'Selecione a categoria',
        logistic: 'Logística',
        periodRangePicker: 'Período',
        from: 'De',
        to: 'Para',
        taxes: 'Taxas',
        recoveryTaxes: 'Imposto de Recuperação',
        ipi: 'IPI',
        icms: 'ICMS',
        pisConfins: 'PIS/CONFINS',
        rd: 'R&D',
        period: 'Escolha o período',
        formatTaxe: 'A taxa deve conter duas casas decimais',
        cancel: 'Cancelar',
        add: 'Adicionar',
        save: 'Salvar',
      },
      uploadSheets: {
        title: 'Importar um Arquivo',
        cancel: 'Cancelar',
        upload: 'Importar',
        uploadSuccess: 'Carregamento realizado com sucesso',
        uploadError: 'Arquivo deve ser Excel ou CSV',
        labels: {
          listType: 'Tipo de Lista',
          date: 'Data',
          responsible: 'Responsável',
        },
        placeholders: {
          CM: 'Selecione um CM',
          listType: 'Selecione um tipo',
          date: 'Selecione uma data',
          responsible: 'Responsável',
        },
        dragAndDrop: {
          title: 'Arraste e solte um arquivo ou clique para adicionar',
          subTitle: 'Somente arquivos .xls ou .xlsx podem ser importados',
          fileError: 'Voce só pode enviar arquivo Excel!',
          requiredFile: 'É necessário carregar um arquivo no formato xls ou xlsx!',
          maxFileSizeError:
            'O tamanho máximo do arquivo suportado para upload de arquivos é {{maxSize}}',
        },
        listTypeOptions: {
          quotes: 'Quotes',
          componentsPrice: 'Imports Components Prices',
          localComponentsPrice: 'Local Components Prices',
          packingPrice: 'Packing Prices',
          average: 'Prices Average',
          demand: 'Demands',
          shareProportion: 'Share Proportion',
          inOut: 'In Out',
        },
        CmOption: 'Ambos',
      },
    },
    cards: {
      dollarExchange: {
        title: 'Taxa de Câmbio do Dólar',
        toolTipInformation: 'Informação atualizada com dados do BCB',
        months: {
          jan: 'jan',
          feb: 'fev',
          mar: 'mar',
          apr: 'abr',
          may: 'mai',
          jun: 'jun',
          jul: 'jul',
          aug: 'ago',
          sep: 'set',
          oct: 'out',
          nov: 'nov',
          dec: 'dez',
        },
      },
    },
    navigation: {
      Cost: 'Custos',
      Dashboards: 'Painéis',
      Finance: 'Financeiro',
      FinanceDash: 'Visão Financeira',
      ProcurementDash: 'Visão de Compras',
      DemandScenarios: 'Cenários de Demandas',
      PricesScenarios: 'Cenários de Preços',
      ComponentsPrices: 'Preços de Componentes',
      MaterialData: 'Dados de materiais',
      Strategies: 'Estratégias',
      Planning: 'Planejamento',
      Demand: 'Demandas',
      Rfq: 'RFQ',
      SettingTaxes: 'Taxas',
      ppbRules: 'Regras PPB',
    },
    CardCountDown: {
      respond: 'Responder por',
      timeClose: 'Hora de fechamento do bid',
    },
  },
};

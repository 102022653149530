/* eslint-disable react/require-default-props */
import {
  DownOutlined,
  UpOutlined,
  EditOutlined,
  DeleteOutlined,
  PieChartOutlined,
  GoldOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Divider, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import { ICollapsedComponent } from './types';
import DollarImg from '../../../../../assets/dollar-rfq.svg';
import TimeImg from '../../../../../assets/time.svg';
import theme from '../../../../../styles/theme';
import StyledButton from '../../../../../components/Common/StyledButton';
import { formatValueToCash } from '../../../../../helpers/formatValueToCash';

export function CompressedComponent(props: ICollapsedComponent) {
  const { t } = useTranslation();

  const renderSummary = () => {
    switch (props.step) {
      case '4':
      case '5':
        return (
          <S.Content>
            <S.Sumary>
              <S.Label>
                <Row align="middle" style={{ gap: '0.5rem' }}>
                  <PieChartOutlined />
                  <span>{t('pages.newRfq.steps.fourthStep.compressedComponent.totalDemand')}:</span>
                </Row>
              </S.Label>
              <S.Description hasIcon>
                {props.totalDemand?.toLocaleString(t('common.localeMoney')) || '-'}
              </S.Description>
            </S.Sumary>
            <S.Sumary>
              <S.Label>
                <Row align="middle" style={{ gap: '0.5rem' }}>
                  <GoldOutlined />
                  <span>{t('pages.newRfq.steps.fourthStep.compressedComponent.components')}:</span>
                </Row>
              </S.Label>
              <S.Description hasIcon>
                {props.components ? `${Number(props.components)}` : '-'}
              </S.Description>
            </S.Sumary>
          </S.Content>
        );
      default:
        return (
          <S.Content>
            <S.Sumary>
              <S.Label>
                <Flex align="middle" gap={'0.5rem'}>
                  <StarOutlined />
                  <span>
                    {t('pages.newRfq.steps.secondStep.compressedComponent.suppliers').toUpperCase()}
                    :
                  </span>
                </Flex>
              </S.Label>
              <S.Description hasIcon>{props.suppliers?.join(', ') || '-'}</S.Description>
            </S.Sumary>
            <S.Sumary>
              <S.Label>
                <Flex align="middle" gap={'0.5rem'}>
                <img src={TimeImg} alt="Dollar"/>
                  <span>
                    {t(
                      'pages.newRfq.steps.secondStep.compressedComponent.demandPeriod'
                    ).toUpperCase()}
                    :
                  </span>
                </Flex>
              </S.Label>
              <S.Description hasIcon>{props.demandPeriod || '-'}</S.Description>
            </S.Sumary>
            <S.Sumary>
              <S.Label>
                <Flex align="middle" gap={'0.5rem'}>
                  <img src={DollarImg} alt="Dollar"/>
                  <span>
                    {t(
                      'pages.newRfq.steps.secondStep.compressedComponent.startingPrice'
                    ).toUpperCase()}
                    :
                  </span>
                </Flex>
              </S.Label>
              <S.Description hasIcon>
                {props.staringPrice ? `$${formatValueToCash(Number(props.staringPrice))}` : '-'}
              </S.Description>
            </S.Sumary>
          </S.Content>
        );
    }
  };

  return (
    <S.Container status={props.status}>
      <S.Header>
        <Row style={{ gap: '1.5rem' }} align="middle">
          {props.isOpen ? (
            <UpOutlined
              style={{
                color: theme.colorsDesignSystem.primary,
                transform: `rotate (${props.isOpen ? 180 : 0})deg`,
                transition: 'all 0.5s',
                cursor: 'pointer',
              }}
              onClick={props.onClose}
            />
          ) : (
            <DownOutlined
              style={{
                color: theme.colorsDesignSystem.primary,
                transform: `rotate (${props.isOpen ? 180 : 0})deg`,
                transition: 'all 0.5s',
                cursor: 'pointer',
              }}
              onClick={props.onEdit}
            />
          )}
          <S.Title>
            <b>{props.index?.toString().padStart(2, '0')} </b>
            {props.status ? props.status === 'OK' ? <S.StatusOK /> : <S.StatusWarning /> : null}
            {props.title || '-'}
          </S.Title>
        </Row>

        <Row style={{ gap: '1rem' }} align="middle" justify="end">
          {!props.isOpen && (
            <StyledButton variant="slim" onClick={props.onEdit} disabled={props.disableEdit}>
              <EditOutlined /> {t('pages.newRfq.steps.secondStep.compressedComponent.edit')}
            </StyledButton>
          )}
          {props.step !== '5' && (
            <>
              <Divider type="vertical" />
              <StyledButton variant="slim" onClick={props.onDelete} disabled={props.disableDelete}>
                <DeleteOutlined /> {t('pages.newRfq.steps.secondStep.compressedComponent.delete')}
              </StyledButton>
            </>
          )}
        </Row>
      </S.Header>
      {!props.isOpen ? renderSummary() : props.children}
    </S.Container>
  );
}

import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Row } from 'antd';
import styled from 'styled-components';

interface StatusProps {
  status?: 'OK' | 'WARNING' | null;
}

export const Container = styled.div<StatusProps>`
  width: 100%;
  border: 1px solid
    ${({ status }) =>
      status === 'WARNING'
        ? ({ theme }) => theme.colorsDesignSystem.tagColorYellow
        : ({ theme }) => theme.colorsDesignSystem.border};

  border-radius: 0.75rem;
  border-radius: 0.378rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled(Row)`
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
`;

export const Title = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  gap: 0.5rem;

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const Content = styled(Row)`
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 6.25rem;
`;

export const Sumary = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};

  svg {
    font-size: 1rem;
  }
`;

export const Description = styled.span<{hasIcon?: boolean}>`
  font-size: 0.875rem;
  margin-left: ${({ hasIcon }) => hasIcon && '1.25rem'};
`;

export const StatusOK = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-size: 0.91rem;
`;

export const StatusWarning = styled(ExclamationCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
  font-size: 0.91rem;
`;

import { useEffect, useState } from 'react';
import { Form, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { HttpStatusCode } from 'axios';

import * as S from './styles';
import { ItemProp, Step3Data } from './types';

import { ActionButton } from '../../../../components/ActionButton';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import StyledButton from '../../../../components/Common/StyledButton';
import { api } from '../../../../services/api';
import { CompressedComponent } from '../components/CompressedComponents';
import { EditableTableCellRFQ } from '../components/EditableTableCellRFQ';
import { handleFormatValueFields, oneAlert } from '../../../../helpers/nUtils';
import { IError } from '../../types';
import { services } from '../../../../services/services';

export function useStep3(bidId?: string) {
  const { t } = useTranslation();
  const ExpandCategories: { [key: string]: boolean } = {};
  const [form] = Form.useForm();
  const [idEdited, setIdEdited] = useState('');
  const [expandableItems, setExpandableItems] = useState(ExpandCategories);
  const [step3data, setStep3Data] = useState<Array<Step3Data>>([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [idDeletedItem, setIdDeletedItem] = useState<number | null>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('bidID', JSON.stringify(bidId));
  }, []);

  useEffect(() => {
    if (step3data.length > 0) {
      localStorage.setItem('step3data', JSON.stringify(step3data));
    }
  }, [step3data]);

  const handleFetchStep3Data = async () => {
    setLoading(true);
    try {
      const { data, status }: { data: Array<Step3Data>; status: HttpStatusCode } = await api.get(
        `${services.rfq}/bid/step/3/${bidId}`
      );
      if (status === 200) {
        setStep3Data(data);
        return;
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableItems((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableItems[value],
      };
    });
  };

  const prepareToEdit = (record: ItemProp) => {
    form.setFieldsValue({ ...record });
    setIdEdited(record.demandProjectId);
  };

  const isEditing = (record: ItemProp) => {
    return record.demandProjectId === idEdited;
  };

  const handleFindDemand = (record: ItemProp) => {
    return step3data.find((item: Step3Data) => {
      item.quantitiesData.some(
        (quantitiesItem: ItemProp) => quantitiesItem.demandProjectId === record.demandProjectId
      );
      return true;
    });
  };

  const handleUpdateDemand = (record: ItemProp, value: string) => {
    setIdEdited('');
    if (handleFindDemand(record)) {
      setStep3Data((oldData: Array<Step3Data>) => {
        return oldData.map((itemold: Step3Data) => {
          return {
            ...itemold,
            quantitiesData: itemold.quantitiesData.map((item: ItemProp) => {
              if (item.demandProjectId === record.demandProjectId) {
                return {
                  ...item,
                  demand: Number(value),
                };
              }
              return item;
            }),
          };
        });
      });
    }
  };

  // salva os dados do grupo
  const saveQuantitiesItem = async (quantitiesItem: Step3Data) => {
    try {
      setLoading(true);
      const response = await api.put(
        `${services.rfq}/bid/step/3/${bidId}/component/${quantitiesItem.componentId}`,
        quantitiesItem.quantitiesData
      );
      if (response.status === 200) {
        oneAlert({
          type: 'success',
          message: t('toast.successOnSave'),
        });
        handleFetchStep3Data();
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnSave'),
      });
    } finally {
      setLoading(true);
      handleUpdateExpandableViews(quantitiesItem.index.toString());
    }
  };

  // apaga um Projeto
  const deleteProjectItem = async (quantitiesItem: ItemProp, event: any) => {
    const componentID = step3data.find((item) => {
      return item.quantitiesData.some(
        (currentItem) => quantitiesItem.specification === currentItem.specification
      );
    })?.componentId;

    try {
      event.stopPropagation();
      const response = await api.delete(
        `${services.rfq}/bid/step/3/${bidId}/component/${componentID}/demandProject/${quantitiesItem.demandProjectId}`
      );
      if (response.status === 200) {
        oneAlert({
          type: 'success',
          message: t('toast.successOnDelete'),
        });
        handleFetchStep3Data();
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    }
  };

  // chama modal para deletar um grupo
  const HandleDeleteGroupModal = (id: number) => {
    setIdDeletedItem(id);
    setVisibleDeleteModal(true);
  };

  // apaga um grupo
  const handleDeleteGroupQuantitiesItem = async () => {
    try {
      const response = await api.delete(
        `${services.rfq}/bid/step/3/${bidId}/component/${idDeletedItem}`
      );
      if (response.status === 200) {
        oneAlert({
          type: 'success',
          message: t('toast.successOnDelete'),
        });
        handleFetchStep3Data();
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    } finally {
      setVisibleDeleteModal(false);
    }
  };

  const getComponentsWithDifferentStatus = () => {
    const storedData = localStorage.getItem('step3data');
    if (storedData) {
      return JSON.parse(storedData).filter((item: Step3Data) => item.status !== 'OK');
    }
    return step3data.filter((item: Step3Data) => item.status !== 'OK');
  };

  //salva um rascunho
  const SaveDraftStep3 = async () => {
    const componentsWithDifferentStatus = getComponentsWithDifferentStatus();
    const IDBid = JSON.parse(localStorage.getItem('bidID') || '{}');
    try {
      const response = await api.put(
        `${services.rfq}/bid/step/3/${IDBid}`,
        componentsWithDifferentStatus
      );
      if (response.status === 200) {
        oneAlert({
          type: 'success',
          message: t('toast.successOnSave'),
        });
        return true;
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnSave'),
      });
      return false;
    }
  };

  const VerifyStatus = () => {
    const storedData = localStorage.getItem('step3data');
    if (storedData) {
      return JSON.parse(storedData).some((item: Step3Data) => item.status !== 'OK');
    }
    return true;
  };

  const columns: any = [
    {
      title: () => {
        return <S.HeaderTable>{t('pages.newRfq.steps.thirdStep.table.project')}</S.HeaderTable>;
      },
      dataIndex: 'project',
      key: 'project',
      render: (value: string, record: any) => {
        return <S.ValueTable>{value}</S.ValueTable>;
      },
    },
    {
      title: () => {
        return (
          <S.HeaderTable>{t('pages.newRfq.steps.thirdStep.table.specification')}</S.HeaderTable>
        );
      },
      dataIndex: 'specification',
      key: 'specification',
      align: 'center',
      render: (value: string, record: any) => {
        return <S.ValueTable>{value}</S.ValueTable>;
      },
    },
    {
      title: () => {
        return <S.HeaderTable>{t('pages.newRfq.steps.thirdStep.table.demand')}</S.HeaderTable>;
      },
      dataIndex: 'demand',
      key: 'demand',
      align: 'right',
      editable: true,
      width: '10rem',
      render: (value: string, record: ItemProp) => {
        return (
          <S.DemandLink onClick={() => prepareToEdit(record)}>{`${handleFormatValueFields(
            record.demand,
            0,
            0
          )}`}</S.DemandLink>
        );
      },
      onCell: (record: ItemProp) => ({
        inputType: 'numberWithZero',
        record,
        editing: isEditing(record),
        dataIndex: 'demand',
        title: 'Demand',
        maxLength: 6,
        onExit: (recordData: ItemProp, value: string) => {
          handleUpdateDemand(recordData, value);
        },
      }),
    },
    {
      title: () => {
        return <S.HeaderTable />;
      },
      key: 'action',
      width: 70,
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <Popconfirm
            placement="left"
            title={() => (
              <S.RemovedNote>{t('pages.newRfq.steps.thirdStep.deleteItemMessage')}</S.RemovedNote>
            )}
            onConfirm={(e) => deleteProjectItem(record, e)}
            onCancel={(e) => e?.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <ActionButton icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  const RenderContent = (Data: Array<Step3Data>) => {
    return Data.map((item: Step3Data) => {
      return (
        <S.ContentCard key={item.index}>
          {expandableItems && (
            <CompressedComponent
              step="3"
              index={item.index}
              isOpen={expandableItems[item.index]}
              title={`${item.category ?? ''} | ${item.altGroup}`}
              suppliers={item.suppliers}
              demandPeriod={item.demandPeriod}
              staringPrice={item.startingPrice}
              status={item.status}
              onClose={() => {
                handleUpdateExpandableViews(item.index.toString());
              }}
              onDelete={() => {
                HandleDeleteGroupModal(item.componentId);
              }}
              onEdit={() => {
                handleUpdateExpandableViews(item.index.toString());
              }}
            >
              <S.StepConteinerTable>
                <S.Step3InfoConteiner>
                  <S.DestinyContainer>
                    <S.Label>{t('pages.newRfq.steps.thirdStep.label.from')}</S.Label>
                    <S.DestinyValue>{item.from}</S.DestinyValue>
                    <S.TruckIcon />
                    <S.Label>{t('pages.newRfq.steps.thirdStep.label.to')}</S.Label>
                    <S.DestinyValue>{item.to}</S.DestinyValue>
                  </S.DestinyContainer>
                  <S.DemandPeriodStartingPrice>
                    <S.ContainerPeriodStartingPrice>
                      <S.PeriodIcon />
                      <S.Label>{t('pages.newRfq.steps.thirdStep.label.periodDemand')}</S.Label>
                      <S.DemandPeriodStartingPriceValue>
                        {item.demandPeriod}
                      </S.DemandPeriodStartingPriceValue>
                    </S.ContainerPeriodStartingPrice>
                    <S.ContainerPeriodStartingPrice>
                      <S.StartPriceIcon />
                      <S.Label>{t('pages.newRfq.steps.thirdStep.label.startingPrice')}</S.Label>
                      <S.DemandPeriodStartingPriceValue>
                        $ {item.startingPrice}
                      </S.DemandPeriodStartingPriceValue>
                    </S.ContainerPeriodStartingPrice>
                  </S.DemandPeriodStartingPrice>
                </S.Step3InfoConteiner>

                <Form form={form} component={false}>
                  <SimpleTable
                    bordered={false}
                    pagination={false}
                    dataSource={item.quantitiesData}
                    rowKey="id"
                    columns={columns}
                    components={{
                      body: {
                        cell: EditableTableCellRFQ,
                      },
                    }}
                  />
                </Form>
              </S.StepConteinerTable>
              <S.CardFooter>
                <StyledButton variant="primary" onClick={() => saveQuantitiesItem(item)}>
                  {t('common.save')}
                </StyledButton>
              </S.CardFooter>
            </CompressedComponent>
          )}
        </S.ContentCard>
      );
    });
  };

  return {
    columns,
    form,
    expandableItems,
    step3data,
    visibleDeleteModal,
    loading,
    handleDeleteGroupQuantitiesItem,
    setIdDeletedItem,
    setVisibleDeleteModal,
    handleUpdateExpandableViews,
    RenderContent,
    handleFetchStep3Data,
    SaveDraftStep3,
    VerifyStatus,
  };
}

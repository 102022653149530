import { Button as antButton } from 'antd';
import styled from 'styled-components';

export const PrimaryButton = styled(antButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.dimensions.border_radius} !important;
  background-color: ${({ theme, disabled }) => theme.colors.prim};
  color: ${({ theme }) => theme.colors.white}!important;
  border: none;
  box-shadow: 0px 2px #0000000b;
  padding: 0.25rem 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor} !important;
    border: 1px solid ${({ theme }) => theme.colorsDesignSystem.primary};
    span {
      color: ${({ theme }) => theme.colorsDesignSystem.primaryHoverColor};
    }
  }

  &:hover:disabled {
    background-color: ${({ theme }) => theme.colorsDesignSystem.headerTitleTable} !important;
    border: none;
    span {
      color: ${({ theme }) => theme.colors.white}!important;
    }
  }
`;

export const SecondaryButton = styled(antButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.prim};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.prim};
  border-radius: ${({ theme }) => theme.dimensions.border_radius} !important;
  box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SlimButton = styled(antButton)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.prim};
  box-shadow: none;

  &:hover {
    background-color: 'transparent' !important;
  }
`;

export const DashedButton = styled(antButton)`
  border: 1px dashed ${({ theme }) => theme.colors.prim};
  background-color: ${({ theme }) => `${theme.colors.dashedButtonColor}60`};
  color: ${({ theme }) => theme.colors.prim};
  font-weight: 600;
  box-shadow: none;
`;

import { useTranslation } from 'react-i18next';
import { Steps } from 'antd';
import { useEffect } from 'react';
import * as S from './styles';
import { useNewRFQ } from './useNewRFQ';
import { ExitRFQModal } from './ExitRFQModal';
import { Step1, Step2, Step3, Step4, Step5 } from './ContentSteps';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function NewRFQ() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.newRfq.title'));
  const {
    formStep1,
    saveStep1,
    saveStep2,
    currentStep,
    setCurrentStep,
    openDialog,
    setOpenDialog,
    nextLocation,
    isLoadingSubmit,
    bidStatus,
    deleteBid,
    saveCurrentStepAsDraf,
    verifyValuesOnNavigate,
    fieldsHasValue,
    history,
    onChangeStep,
    bidId,
    isLoading,
  } = useNewRFQ();

  useEffect(() => {
    const onNavigateOut = history.listen(verifyValuesOnNavigate);

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (fieldsHasValue()) {
          setOpenDialog(true);
        }
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => {
      onNavigateOut();
      document.removeEventListener('keydown', handleEsc);
    };
  }, [openDialog]);

  const steps = [
    {
      title: t('pages.newRfq.steps.firstStep.title'),
      description: t('pages.newRfq.steps.firstStep.description'),
      content: (
        <Step1
          form={formStep1}
          handleSubmit={saveStep1}
          onCancel={() => {
            if (fieldsHasValue()) {
              setOpenDialog(true);
            } else history.push('/rfq');
          }}
          isLoadingSave={isLoadingSubmit}
          bidStatus={bidStatus}
          isLoadingData={isLoading}
        />
      ),
    },
    {
      title: t('pages.newRfq.steps.secondStep.title'),
      description: t('pages.newRfq.steps.secondStep.description'),
      content: (
        <Step2
          handleSubmit={saveStep2}
          nextStep={() => setCurrentStep(currentStep + 1)}
          previousStep={() => setCurrentStep(currentStep - 1)}
          //isLoadingSubmit={isLoadingSubmit}
          onCancel={() => setOpenDialog(true)}
          bidId={bidId || ''}
        />
      ),
    },
    {
      title: t('pages.newRfq.steps.thirdStep.title'),
      description: t('pages.newRfq.steps.thirdStep.description'),
      content: (
        <Step3
          bidId={bidId}
          currentStep={currentStep}
          previousStep={() => setCurrentStep(currentStep - 1)}
          nextStep={() => setCurrentStep(currentStep + 1)}
          onCancel={() => setOpenDialog(true)}
        />
      ),
    },
    {
      title: t('pages.newRfq.steps.fourthStep.title'),
      description: t('pages.newRfq.steps.fourthStep.description'),
      content: (
        <Step4
          nextStep={() => setCurrentStep(currentStep + 1)}
          previousStep={() => setCurrentStep(currentStep - 1)}
          onCancel={() => setOpenDialog(true)}
          bidId={bidId}
        />
      ),
    },
    {
      title: t('pages.newRfq.steps.fifthStep.title'),
      description: t('pages.newRfq.steps.fifthStep.description'),
      content: (
        <Step5
          bidId={bidId}
          onCancel={() => setOpenDialog(true)}
          previousStep={() => setCurrentStep(currentStep - 1)}
        />
      ),
    },
  ];

  return (
    <S.Container>
      <ExitRFQModal
        isOpen={openDialog}
        onSaveDraft={() => {
          saveCurrentStepAsDraf();
        }}
        onCloseModal={() => setOpenDialog(false)}
        onDelete={() => {
          deleteBid().then(() => {
            history.push(nextLocation || '/rfq');
          });
        }}
        loadingDelete={isLoading}
        loadingDraft={isLoadingSubmit}
      />
      <S.HeaderContainer>
        <S.Title>{t('pages.newRfq.title')}</S.Title>
      </S.HeaderContainer>
      <S.Content>
        <S.StepBox>
          <Steps direction="vertical" current={currentStep} items={steps} onChange={onChangeStep} />
        </S.StepBox>
        <S.ContentSteps>
          <S.StepTitle>{steps[currentStep].title}</S.StepTitle>
          {steps[currentStep].content}
        </S.ContentSteps>
      </S.Content>
    </S.Container>
  );
}

import { Col, Divider, Form, FormInstance, Input, Row, Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';

interface INewComponent {
  form: FormInstance;
  cmOptions: SelectProps['options'];
  productOptions: SelectProps['options'];
}

export function NewComponent({ form, cmOptions, productOptions }: INewComponent) {
  const { t } = useTranslation();
  return (
    <Col span={24}>
      <Divider orientation="left" orientationMargin="0">
        {t('pages.avlc.fields.dataRegistration')}
      </Divider>
      <Form
        name="add-component"
        layout="vertical"
        form={form}
        initialValues={{ cm: 'Foxconn', product: 'Notebook' }}
      >
        <Form.Item
          label={t('pages.avlc.fields.partNumber')}
          style={{ width: '25%' }}
          name="partNumber"
        >
          <Input size="large" disabled style={{ color: 'blue', backgroundColor: 'white' }} />
        </Form.Item>
        <Form.Item noStyle>
          <Form.Item
            label={t('pages.avlc.fields.cm')}
            style={{ display: 'inline-block', width: 'calc(20% - 0.5rem)' }}
            name="cm"
          >
            <Select
              size="large"
              disabled
              placeholder={t('pages.avlc.fields.cmPlaceholder')}
              options={cmOptions}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.avlc.fields.product')}
            style={{ display: 'inline-block', width: 'calc(20% - 1rem)', margin: '0 0 0 1rem' }}
            name="product"
            rules={[
              {
                required: true,
                message: `${t('pages.avlc.fields.product')} ${t(`common.validate.required`)}`,
              },
            ]}
          >
            <Select
              size="large"
              placeholder={t('pages.avlc.fields.productPlaceholder')}
              options={productOptions}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.avlc.fields.partType')}
            style={{ display: 'inline-block', width: 'calc(20% - 1rem)', margin: '0 0 0 1rem' }}
            name="partType"
          >
            <Select size="large" disabled />
          </Form.Item>
          <Form.Item
            label={t('pages.avlc.fields.spec')}
            style={{ display: 'inline-block', width: 'calc(40% - 0.5rem)', margin: '0 0 0 1rem' }}
            name="type"
          >
            <Input size="large" disabled />
          </Form.Item>
        </Form.Item>
        <Form.Item noStyle>
          <Form.Item
            label={t('pages.avlc.fields.description')}
            style={{ display: 'inline-block', width: 'calc(60% - 0.5rem)' }}
            name="description"
          >
            <Input size="large" disabled />
          </Form.Item>
          <Form.Item
            label={t('pages.avlc.fields.location')}
            style={{ display: 'inline-block', width: 'calc(20% - 0.5rem)', margin: '0 0 0 1rem' }}
            name="location"
          >
            <Select
              size="large"
              options={[
                { label: 'FOB', value: 'FOB' },
                { label: 'BR', value: 'BR' },
              ]}
              placeholder="Select Source"
            />
          </Form.Item>
          <Form.Item
            label={t('pages.avlc.fields.subComponents')}
            style={{ display: 'inline-block', width: 'calc(20% - 1rem)', margin: '0 0 0 1rem' }}
            name="subComponent"
          >
            <Input size="large" placeholder={t('pages.avlc.fields.subComponentsPlaceholder')} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Col>
  );
}

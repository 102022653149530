import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  LabelList,
} from 'recharts';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import theme from '../../../../../../styles/theme';
import * as S from './styles';
import { ChartData } from '../types';
import { capitalizeFirstLetter } from '../../../../../../helpers/utils';
import { handleFormatValueFields } from '../../../../../../helpers/nUtils';

const handleColorBars = (name: string) => {
  switch (name) {
    case 'MBA':
      return theme.colorsDesignSystem.grayBorder;
    case 'Received':
      return theme.colorsDesignSystem.chartColors.green;
    case 'Return':
      return theme.colorsDesignSystem.chartColors.blue;
    case 'Consumed':
      return theme.colorsDesignSystem.red_secondary;
    case 'Scrap':
      return theme.colorsDesignSystem.graphicThemeColors.orangeCurrentMonth;
    case 'Month Ending':
      return theme.colorsDesignSystem.graphicThemeColors.orangeCurrentMonth;
    default:
      theme.colorsDesignSystem.chartColors.blue;

      break;
  }
};

const formatTick = (number: string) => {
  const num = Number(number);
  if (num >= 1e3) {
    return `${num / 1e3}K`;
  }
  if (num >= 1e6) {
    return `${num / 1e6}M`;
  }
  return `${number}`;
};

const formatLabel = (number: string) => {
  return number ? handleFormatValueFields(number, 0, 0) : null;
};
const XtickFormatter = ({ x: positionX, y: positionY, payload }: any) => {
  const words = payload.value.split(' ');
  return (
    <g transform={`translate(${positionX},${positionY})`}>
      <text textAnchor="middle">
        {words.map((word: string, index: number) => (
          <tspan key={word} x={0} dy={index === 7 ? 5 : 12}>
            {word}
          </tspan>
        ))}
      </text>
    </g>
  );
};

export default function InOutChart(props: ChartData) {
  const { t } = useTranslation();
  const data = [
    {
      name: 'MBA',
      value: [0, props.mba],
      label: props.mba,
    },
    {
      name: 'Received',
      value: props.received === 0 ? [0, 0] : [props.mba, props.mba + props.received],
      label: props.received,
    },
    {
      name: 'Return',
      value:
        props.returned === 0
          ? [0, 0]
          : [props.mba + props.received, props.mba + props.received + props.returned],
      label: props.returned,
    },
    {
      name: 'Consumed',
      value:
        props.consumed === 0
          ? [0, 0]
          : [
              props.mba + props.received + props.returned,
              props.mba + props.received + props.returned + props.consumed,
            ],
      label: props.consumed,
    },
    {
      name: 'Scrap',
      value:
        props.scrap === 0
          ? [0, 0]
          : [
              props.mba + props.received + props.returned + props.consumed,
              props.mba + props.received + props.returned + props.consumed + props.scrap,
            ],
      label: props.scrap,
    },
    {
      name: 'Month Ending',
      value: [0, props.end],
      label: props.end,
    },
  ];
  return (
    <S.ChartContainer>
      <S.TitleContainer>
        <S.MonthContainer>
          {dayjs(capitalizeFirstLetter(props.date), 'MMMM/YYYY')
            .locale(t('common.dayjs'))
            .format('MMM/YY')}{' '}
          | QTY
        </S.MonthContainer>
      </S.TitleContainer>
      {props.hasData ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            key="name"
            margin={{
              top: 25,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={XtickFormatter} />
            <YAxis tickFormatter={formatTick} />
            <Bar barSize={30} dataKey="value">
              {data.map((entry) => (
                <Cell key={entry.name} fill={handleColorBars(entry.name)} />
              ))}
              <LabelList
                dataKey="label"
                position="top"
                fill={theme.colorsDesignSystem.grayAcer}
                formatter={formatLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Empty />
      )}
    </S.ChartContainer>
  );
}

import { ReactNode } from 'react';
import { DollarOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Row } from 'antd';
import * as S from './styles';
import theme from '../../../styles/theme';
import { formatNumberForTowDecimalPlaces, formatNumberToOrdinal } from '../../../helpers/FormatValues';
import { IBidCardItem } from '../types';
import exclamationBlue from '../../../assets/exclamation-circle.svg';
import { t } from 'i18next';

export function useBidCard() {
  const handleRenderItensAlGroup = (
    itens: Array<IBidCardItem>,
    bidOnSelect: (bidCardId: string, altGroup: string) => void
  ): ReactNode => {
    return itens.map((item, index) => {
      return !item.decline && (item.rankingPosition === 0 || !item.rankingPosition )? (
        <div key={item.id}>
          <S.ItemContainer
            selected={item.selected}
            onClick={() => {
              bidOnSelect(item.id, item.altGroup);
            }}
          >
            <ExclamationCircleFilled style={{ color: theme.colorsDesignSystem.tagColorYellow }} />
            <span className="blue">{String(index + 1).padStart(2, '0')}</span>
            <span>{item.altGroup}</span>
          </S.ItemContainer>
        </div>
      ) : (
        <S.ItemContainerAnswered
          key={item.id}
          selected={item.selected}
          onClick={() => {
            bidOnSelect(item.id, item.altGroup);
          }}
        >
          <S.HeaderContainerCardDubleLine>
            <Row style={{ gap: 4 }}>
              <img src={exclamationBlue} width={18} height={18} alt={t('pages.bidResponse.altImages.exclamationBlue')} />
              <span className="blue">{String(index + 1).padStart(2, '0')}</span>
              <span>{item.altGroup}</span>
            </Row>
            <Row style={{ gap: 4 }}>
              <span className="position">
                {item.decline ? 'Decline' : formatNumberToOrdinal(Number(item.rankingPosition))}
              </span>
            </Row>
          </S.HeaderContainerCardDubleLine>
          <Row style={{ gap: 8 }}>
            <DollarOutlined style={{ color: theme.colorsDesignSystem.grayBorderTooltip }} />
            <span className="gross">{t('pages.bidResponse.labels.gross').toUpperCase()}</span>
            <span className={item.rankingPosition > 0 ? 'blue' : 'gross'}>
              {item.rankingPosition > 0 ? `$ ${formatNumberForTowDecimalPlaces(item.grossPrice)}` : '$00.00'}
            </span>
          </Row>
        </S.ItemContainerAnswered>
      );
    });
  };

  return {
    handleRenderItensAlGroup,
  };
}

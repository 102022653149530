import { useTranslation } from 'react-i18next';
import { FolderViewOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { Checkbox, Form, Row } from 'antd';
import StyledSelect from '../../components/Common/StyledSelect';
import { Loading } from '../../components/Loading';
import * as S from './styles';
import { useNewForecast } from './useNewForecast';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import StyledButton from '../../components/Common/StyledButton';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import EditableTableCell from '../../components/Common/EditableTableCell';
import ContentModalNewForecast from './ContentModalNewForecast';
import { ForecastParams } from './types';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledModal from '../../components/Common/StyledModal';

export function NewForecastPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.newDemand.title'));

  const {
    isLoading,
    products,
    CMs,
    params,
    columnsWhitAdditionalProps,
    projectsSelect,
    forecasts,
    form,
    pageControl,
    showModal,
    skuSelected,
    skus,
    forecastMonth,
    handleUpdateFilters,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleFetchForecast,
    handleChangeTable,
    handleFetchProjects,
    setShowModal,
    handleFetchSkus,
    setSkus,
    setParams,
    setProjectsSelect,
    changePage,
  } = useNewForecast();

  useEffect(() => {
    handleFetchForecast();
  }, [params.page, params.orderBy]);

  useEffect(() => {
    params.page !== 0 &&
      setParams((prevState: ForecastParams) => {
        return {
          ...prevState,
          page: 0,
        };
      });
  }, [params.product, params.cm, params.project, params.sku, params.year]);

  useEffect(() => {
    if (params.cm) {
      handleFetchProjects();
      return;
    }
    setProjectsSelect([]);
  }, [params.product, params.cm, params.year, params.withDemands]);

  useEffect(() => {
    skuSelected && setShowModal(true);
  }, [skuSelected]);

  useEffect(() => {
    setParams((prevState: ForecastParams) => {
      return {
        ...prevState,
        sku: null,
      };
    });
  }, [params.project]);

  useEffect(() => {
    setParams((prevState: ForecastParams) => {
      return {
        ...prevState,
        project: null,
        sku: null,
      };
    });
  }, [params.cm]);

  useEffect(() => {
    if (params.cm) {
      handleFetchSkus();
      return;
    }
    setSkus([]);
  }, [params.product, params.cm, params.project, params.year, params.withDemands]);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StyledHeader title={t('pages.newDemand.title')} items={pageControl.totalElements} />
          <S.ContainerFilters>
            <StyledSelect
              label={t('pages.newDemand.filters.cm')}
              placeholder={t('pages.newDemand.placeholders.cm')}
              onChange={(value: any) => {
                handleUpdateFilters('cm', value);
              }}
              options={CMs}
              value={params.cm}
              styles={{ minWidth: '7.25rem' }}
            />
            <StyledSelect
              options={products}
              label={t('pages.newDemand.filters.product')}
              placeholder={t('pages.newDemand.placeholders.product')}
              onChange={(value: any) => {
                handleUpdateFilters('product', value);
              }}
              styles={{ minWidth: '7.25rem' }}
              value={params.product}
            />
            <StyledSelect
              options={projectsSelect}
              label={t('pages.newDemand.filters.project')}
              placeholder={t('pages.newDemand.placeholders.project')}
              showSearch
              onChange={(value: any) => {
                handleUpdateFilters('project', value);
              }}
              styles={{ minWidth: '7.25rem' }}
              value={params.project}
            />
            <StyledSelect
              label={t('pages.newDemand.filters.sku')}
              placeholder={t('pages.newDemand.placeholders.sku')}
              options={skus}
              showSearch
              onChange={(value: any) => {
                handleUpdateFilters('sku', value);
              }}
              styles={{ minWidth: '8.25rem' }}
              value={params.sku}
            />
            <StyledDatePicker
              label={t('pages.newDemand.placeholders.year')}
              picker="year"
              onChange={(date, _) => {
                handleUpdateFilters('year', date);
              }}
              defaultValue={params.year}
              styles={{ minWidth: '7.25rem' }}
            />
            <Checkbox
              checked={params.withDemands}
              style={{ padding: '0.375rem' }}
              onChange={() => {
                handleUpdateFilters('withDemands', !params.withDemands);
              }}
            >
              {t('pages.quotes.labels.withDemand')}
            </Checkbox>
            <StyledButton
              small
              onClick={handleFetchForecast}
              variant="primary"
              text={t('pages.newDemand.titleButtons.apply')}
            />
          </S.ContainerFilters>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.TableLabelsContainer>
            <Row style={{ gap: '4px' }} align="middle">
              <FolderViewOutlined style={{ fontSize: '0.875rem' }} />
              <span>{t('pages.newDemand.tableName')}</span>
              <S.QuoteFilesLabel>{forecastMonth}</S.QuoteFilesLabel>
            </Row>
          </S.TableLabelsContainer>
          <S.TableContainer>
            <Form form={form} component={false}>
              <SimpleTable
                onChange={handleChangeTable}
                components={{
                  body: {
                    cell: EditableTableCell,
                  },
                }}
                pagination={false}
                scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                columns={columnsWhitAdditionalProps}
                dataSource={forecasts}
                rowKey="id"
                rowClassName="editable-row"
              />
            </Form>
          </S.TableContainer>
        </>
      )}
      {showModal && (
        <StyledModal
          key="modal-avlc"
          title={t('pages.newDemand.titleModal')}
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          width={'64rem'}
          footer={null}
          body={<ContentModalNewForecast sku={skuSelected} />}
          centered
        />
      )}
      {pageControl.totalPages > 1 && (
        <StyledPagination
          size={params.limit}
          totalElements={pageControl.totalElements}
          pageNumber={params.page + 1}
          onChange={changePage}
        />
      )}
    </S.Container>
  );
}

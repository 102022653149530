import { Table, Row, Col } from 'antd';
import styled from 'styled-components';

export const ContainerFilters = styled(Row)`
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
  margin-bottom: 1rem;
`;

export const ActionEye = styled.div`
  button svg {
    width: 24px;
    height: 24px;
  }
`;

export const NoProjectCol = styled(Col)`
  width: 100%;

  .col-no-results {
    max-width: 100%;
    margin-top: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray_border};
  }
`;

export const SearchFilter = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .col-filters {
    display: flex;
    justify-content: flex-end;
  }

  .search-line {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_border};

    .ant-select-selector {
      border: none;
      background-color: transparent;
    }
  }
`;

export const QStatusField = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

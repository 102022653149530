import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { ChartTableForMonthProps, CustomTooltipProps } from './types';
import * as S from './styles';
import { useChartPriceTableForMonth } from './useChartPriceTableForMonth';
import { handleFormatValueFields } from '../../../../helpers/nUtils';

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.TooltipContainer>
        <p>{`${label}`}</p>
        {payload.map((entry) => {
          return (
            <p key={entry.payload.name} style={{ color: entry.color }}>
              {handleFormatValueFields(entry.value, 0, 0)}
            </p>
          );
        })}
      </S.TooltipContainer>
    );
  }
  return null;
};

export default function PriceTableChart({ data }: ChartTableForMonthProps) {
  const { handleRenderColorByMonth, handleRenderLegend } = useChartPriceTableForMonth();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value: number) => handleFormatValueFields(value, 0, 0)} />
        <Tooltip content={<CustomTooltip />} />
        <Legend align="right" content={handleRenderLegend} />
        <Bar
          barSize={40}
          dataKey="value"
          label={{
            position: 'top',
            formatter: (value: number) => handleFormatValueFields(value, 0, 0),
          }}
        >
          {data.map((entry, _) => (
            <Cell key={entry.month} fill={handleRenderColorByMonth(entry.month)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

/* eslint-disable no-undefined */
import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../../components/Common/StyledButton';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import StyledIndicationTag from '../../../../components/IndicationTag';
import { formatDataToSelect } from '../../../../helpers/formatDataToSelect';
import { oneAlert } from '../../../../helpers/nUtils';
import { getSortOrder, getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError } from '../../types';
import {
  IModalDeletePartNumber,
  IModalDeleteSupplier,
  ISupplierInformation,
  ITable,
} from './types';

export function useStep4(bidId?: string) {
  const { t } = useTranslation();
  const [suppliersInformations, setSuppliersInformations] = useState<ISupplierInformation[]>([]);
  const [tableInformations, setTableInformations] = useState<ITable[]>([]);
  const [supplierOpen, setSupplierOpen] = useState<ISupplierInformation>();
  const [openDeleteModal, setOpenDeleteModal] = useState<IModalDeletePartNumber>({
    isModalOpen: false,
  });
  const [openDeleteSupplierModal, setOpenDeleteSupplierModal] = useState<IModalDeleteSupplier>({
    isModalOpen: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [categories, setCategories] = useState<StyledSelectOptions[]>([]);
  const [specs, setSpecs] = useState<StyledSelectOptions[]>([]);
  const initialParams = {
    orderByField: '' as Key,
    category: null,
    specification: null,
    orderBy: 'ASC',
  };
  const [params, setParams] = useState(initialParams);

  const updateSupplierOpenedData = (bidData: ISupplierInformation[]) => {
    const copySupplierData = bidData.find(
      (supplierInfo: ISupplierInformation) => supplierInfo.supplier === supplierOpen?.supplier
    );
    setSupplierOpen(copySupplierData);
  };

  const fetchSuppliers = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(`${services.rfq}/bid/step/4/${bidId}`);
      status === 200 && setSuppliersInformations(data.bidStep4List);
      status === 204 && setSuppliersInformations([]);
      supplierOpen && updateSupplierOpenedData(data.bidStep4List);
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse?.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTableInformation = async (supplier: string) => {
    try {
      setIsLoadingTable(true);
      const { data } = await api.get(
        getURI(`${services.rfq}/bid/step/4/filter/${bidId}`, {
          supplier,
          ...params,
          specifications: params.specification && [params.specification],
        })
      );
      setTableInformations(data);
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoadingTable(false);
    }
  };

  const fetchFilters = async () => {
    try {
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/step/4/header/${bidId}`, {
          supplier: supplierOpen?.supplier,
        })
      );
      setCategories(status === 204 ? [] : formatDataToSelect(data.categoryList, false));
      setSpecs(status === 204 ? [] : formatDataToSelect(data.specList, false));
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    }
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ITable>
  ) => {
    setParams({
      ...params,
      orderByField: sorter.field as Key,
      orderBy: getSortOrder(sorter.order),
    });
  };

  const handleFilter = (type: 'category' | 'specification', value: string | null) => {
    setParams({
      ...params,
      [type]: value,
    });
  };

  const clearFilters = () => {
    setParams(initialParams);
  };

  const saveSummary = async () => {
    try {
      setIsLoading(true);
      const body = {
        reviewStatus: true,
      };
      const { status } = await api.put(
        `${services.rfq}/bid/step/4/${bidId}/supplier/${supplierOpen?.supplier}`,
        body
      );

      if (status === 200 || status === 201) {
        fetchSuppliers();
        setSupplierOpen(undefined);
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deletePartNumber = async () => {
    try {
      setIsLoading(true);
      const { status } = await api.delete(
        `${services.rfq}/bid/step/4/${bidId}/supplier/${openDeleteModal.componentInformation?.supplier}/partNumber/${openDeleteModal.componentInformation?.partNumber}/projeto/${openDeleteModal.componentInformation?.project}`
      );

      if (status === 200 || status === 204) {
        fetchSuppliers();
        supplierOpen && fetchTableInformation(supplierOpen.supplier);
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSupplier = async () => {
    try {
      setIsLoading(true);
      const { status } = await api.delete(
        `${services.rfq}/bid/step/4/${bidId}/supplier/${openDeleteSupplierModal.supplier}`
      );

      if (status === 200 || status === 204) {
        if (openDeleteSupplierModal.supplier === supplierOpen?.supplier) {
          setSupplierOpen(undefined);
        }
        fetchSuppliers();
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<ITable> = [
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.project'),
      dataIndex: 'project',
      width: '7rem',
      sorter: true,
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.cm'),
      dataIndex: 'cm',
      width: '7rem',
      sorter: true,
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.altGroup'),
      dataIndex: 'altGroup',
      width: '9rem',
      sorter: true,
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.specification'),
      dataIndex: 'specification',
      width: '9.75rem',
      sorter: true,
      align: 'center',
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.partNumber'),
      dataIndex: 'partNumber',
      width: '9.75rem',
      sorter: true,
      align: 'center',
    },

    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.qStatus'),
      dataIndex: 'qstatus',
      width: '6rem',
      sorter: true,
      align: 'center',
      render: (_, record) => {
        return <StyledIndicationTag text={record.qstatus} />;
      },
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.from'),
      dataIndex: 'from',
      width: '3.5rem',
      align: 'center',
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.to'),
      dataIndex: 'to',
      width: '3.5rem',
      align: 'center',
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.startingPrice'),
      dataIndex: 'startingPrice',
      width: '3.5rem',
      align: 'center',
    },
    {
      title: t('pages.newRfq.steps.fourthStep.fields.table.demand'),
      dataIndex: 'demand',
      width: '6.75rem',
      className: 'remove-tip',
      align: 'right',
      render: (_, record) => {
        return <span>{record.demand?.toLocaleString(t('common.localeMoney'))}</span>;
      },
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: '2rem',
      className: 'border-left',
      render: (_, record) => {
        return (
          <StyledButton
            variant="slim"
            icon={<DeleteOutlined />}
            onClick={() => {
              if (supplierOpen) {
                setOpenDeleteModal({
                  isModalOpen: true,
                  componentInformation: {
                    partNumber: record.partNumber,
                    project: record.project,
                    supplier: supplierOpen?.supplier,
                  },
                });
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (supplierOpen) {
      fetchTableInformation(supplierOpen.supplier);
    }
  }, [params]);

  useEffect(() => {
    fetchFilters();
  }, [supplierOpen]);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return {
    suppliersInformations,
    columns,
    supplierOpen,
    setSupplierOpen,
    saveSummary,
    deletePartNumber,
    deleteSupplier,
    setOpenDeleteModal,
    openDeleteModal,
    setOpenDeleteSupplierModal,
    openDeleteSupplierModal,
    handleChangeTable,
    isLoading,
    fetchSuppliers,
    isLoadingTable,
    tableInformations,
    categories,
    specs,
    handleFilter,
    params,
    clearFilters,
  };
}

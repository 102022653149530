import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';

export const SupplierResponseCard = styled.div`
  width: 19rem;
  display: flex;
  height: auto;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const Classification = styled.div`
  display: flex;
  align-items: center;
  min-width: 4rem;
  max-height: 2rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.25rem;
`;

export const Title = styled.span`
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin-bottom: 0.5rem;
`;

export const PartNumber = styled.span<{ qStatus: string }>`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: ${({ theme, qStatus }) =>
    qStatus === 'Qg' ? theme.colorsDesignSystem.tagColorYellow : theme.colorsDesignSystem.text};
`;

export const Divider = styled(AntDivider)`
  margin: 1rem 0;
`;

export const GapGross = styled.span<{ value: number }>`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ value, theme }) =>
    value > 0
      ? theme.colorsDesignSystem.red_secondary
      : value < 0
      ? theme.colorsDesignSystem.rfqIndicationTagColors.greenFont
      : theme.colorsDesignSystem.text};
`;

export const Percentage = styled.span`
  line-height: 1.375rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

import { Flex } from 'antd';
import * as S from './styles';
import { IVerticalCard } from './types';

export function VerticalCard({
  title,
  value,
  valueBlue = false,
  font = 'MD',
  fontTitle = 'MD',
  gap = 12,
  revertRow = false,
}: IVerticalCard) {
  if (revertRow) {
    return (
      <Flex vertical gap={gap}>
        <S.Value isBlue={valueBlue} font={font}>
          {value}
        </S.Value>
        <S.Title font={fontTitle}>{title}</S.Title>
      </Flex>
    );
  }

  return (
    <Flex vertical gap={gap}>
      <S.Title font={fontTitle}>{title}</S.Title>
      <S.Value isBlue={valueBlue} font={font}>
        {value}
      </S.Value>
    </Flex>
  );
}

/* eslint-disable no-undefined */
import { Col, Divider, Flex, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, DollarOutlined, TrophyOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import ExclamationCircle from '../../assets/exclamation-circle.svg';
import { useViewRFQ } from './useViewRFQ';
import * as S from './styles';
import { Summary } from './Summary';
import StyledButton from '../../components/Common/StyledButton';
import { VerticalCard } from './components/VerticalCard';
import { SupplierResponseCard } from './components/SupplierResponseCard';
import StyledModal from '../../components/Common/StyledModal';
import { CommentsModal } from './components/CommentsModal';
import { handleFormatValueFields } from '../../helpers/nUtils';
import { StyledLoading } from '../../components/Common/StyledLoading';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function ViewRFQ() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.viewRfq.title'));
  const {
    bidId,
    steps,
    loading,
    bidInformations,
    isOpenCommentsModal,
    setIsOpenCommentsModal,
    handleSelectAltGroup,
    selectedAltGroup,
    tabProjects,
    altGroupDetail,
    isLoadingDetails,
    setSelectedProject,
    selectedProject,
  } = useViewRFQ();
  const history = useHistory();

  return (
    <Col span={24}>
      {loading && <Spin fullscreen />}
      <Row>
        <S.BackButton type="link" icon={<ArrowLeftOutlined />} onClick={() => history.push('/rfq')}>
          {t('pages.viewRfq.backButton')}
        </S.BackButton>
      </Row>
      <StyledHeader title={t('pages.viewRfq.title')} />
      <Flex vertical gap={30}>
        <Summary steps={steps} summaryInformations={bidInformations?.overview} />{' '}
        <S.ComponentsContent>
          {bidInformations?.items.map((categories) => {
            return (
              <>
                <S.Title>{categories.category}</S.Title>
                {categories.altGroups.map((altGroup, index) => {
                  return (
                    <S.Content key={altGroup}>
                      <S.HeaderContent
                        isOpen={
                          selectedAltGroup.altGroup === altGroup &&
                          selectedAltGroup.category === categories.category
                        }
                        onClick={() => handleSelectAltGroup(altGroup, categories.category)}
                      >
                        <Flex gap={8}>
                          <img src={ExclamationCircle} alt="Exclamation Circle Icon" />
                          <b>{index + 1}</b>
                          <span>{altGroup}</span>
                        </Flex>
                        {selectedAltGroup.altGroup === altGroup &&
                          selectedAltGroup.category === categories.category && (
                            <StyledButton
                              disabled
                              variant="primary"
                              iconLeft={<TrophyOutlined />}
                              text={t('pages.viewRfq.openCard.award')}
                            />
                          )}
                      </S.HeaderContent>

                      {selectedAltGroup.altGroup === altGroup &&
                        selectedAltGroup.category === categories.category && (
                          <S.AltGroupContent>
                            {isLoadingDetails ? (
                              <StyledLoading height={40} />
                            ) : (
                              <>
                                <Tabs
                                  tabBarGutter={16}
                                  items={tabProjects}
                                  animated
                                  onChange={(value) => setSelectedProject(value)}
                                  activeKey={selectedProject}
                                />
                                <Flex vertical gap={30}>
                                  <Flex justify="space-between" gap={16}>
                                    <S.AltGroupCard project>
                                      <S.Value fontLG>{altGroupDetail?.currentProject}</S.Value>
                                    </S.AltGroupCard>
                                    <S.AltGroupCard>
                                      <VerticalCard
                                        title={t('pages.viewRfq.openCard.specification')}
                                        value={
                                          altGroupDetail?.specifications &&
                                          altGroupDetail?.specifications.length > 0 ? (
                                            <>
                                              {altGroupDetail.specifications.map(
                                                (spec, specIndex) => {
                                                  return (
                                                    <>
                                                      {spec}{' '}
                                                      {specIndex <
                                                        altGroupDetail.specifications.length -
                                                          1 && <Divider type="vertical" />}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            '-'
                                          )
                                        }
                                      />
                                      <VerticalCard
                                        title={t('pages.viewRfq.openCard.cm')}
                                        value={
                                          altGroupDetail?.cm && altGroupDetail?.cm.length > 0 ? (
                                            <>
                                              {altGroupDetail.cm.map((cmName, cmIndex) => {
                                                return (
                                                  <>
                                                    {cmName}{' '}
                                                    {cmIndex < altGroupDetail.cm.length - 1 && (
                                                      <Divider type="vertical" />
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </>
                                          ) : (
                                            '-'
                                          )
                                        }
                                      />
                                      <VerticalCard
                                        title={t('pages.viewRfq.openCard.from')}
                                        value={altGroupDetail?.from || '-'}
                                      />
                                      <VerticalCard
                                        title={t('pages.viewRfq.openCard.to')}
                                        value={altGroupDetail?.to || '-'}
                                      />
                                    </S.AltGroupCard>
                                    <S.AltGroupCard>
                                      <VerticalCard
                                        title={
                                          <>
                                            <DollarOutlined />{' '}
                                            {t('pages.viewRfq.openCard.startingPrice')}
                                          </>
                                        }
                                        value={
                                          altGroupDetail
                                            ? `$ ${handleFormatValueFields(
                                                altGroupDetail?.startingPrice
                                              )}`
                                            : '-'
                                        }
                                      />
                                      <VerticalCard
                                        title={t('pages.viewRfq.openCard.totalDemand')}
                                        value={
                                          altGroupDetail
                                            ? ` ${handleFormatValueFields(
                                                altGroupDetail?.totalDemand,
                                                0,
                                                0
                                              )}`
                                            : '-'
                                        }
                                        valueBlue
                                      />
                                    </S.AltGroupCard>
                                  </Flex>
                                  <Flex gap={16} justify="center" wrap>
                                    {altGroupDetail?.suppliers.map((supplier) => {
                                      return (
                                        <SupplierResponseCard
                                          key={supplier.partNumber}
                                          capacity={supplier.capacity}
                                          gapGross={supplier.gapGross}
                                          gapGrossPercentage={supplier.gapGrossPercentage}
                                          grossPrice={supplier.grossPrice}
                                          lastGross={supplier.lastGross}
                                          leadTime={supplier.leadTime}
                                          netPrice={supplier.netPrice}
                                          position={supplier.position}
                                          totalNet={supplier.totalNet}
                                          supplier={supplier.supplier}
                                          partNumber={{
                                            partNumber: supplier.partNumber,
                                            qstatus: supplier.qstatus,
                                          }}
                                          showCommentsButton={!!supplier.comments?.length}
                                          handleDetailsModal={() =>
                                            setIsOpenCommentsModal({
                                              isOpen: true,
                                              comments: supplier.comments || [],
                                              altGroup,
                                              supplier: supplier.supplier,
                                            })
                                          }
                                        />
                                      );
                                    })}
                                  </Flex>
                                </Flex>
                              </>
                            )}
                          </S.AltGroupContent>
                        )}
                    </S.Content>
                  );
                })}
              </>
            );
          })}

          <StyledModal
            body={
              <CommentsModal
                altGroup={isOpenCommentsModal.altGroup || ''}
                comments={isOpenCommentsModal.comments || []}
                supplier={isOpenCommentsModal.supplier || ''}
              />
            }
            footer={null}
            onClose={() =>
              setIsOpenCommentsModal({
                isOpen: false,
                comments: undefined,
              })
            }
            title={t('pages.viewRfq.openCard.comments')}
            centered
            open={isOpenCommentsModal.isOpen}
            width="54rem"
          />
        </S.ComponentsContent>
      </Flex>
    </Col>
  );
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Row } from 'antd';
import dayjs from 'dayjs';
import { oneAlert } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { StyledSelectOptions } from '../../../ComponentsPrices/PricePage/types';
import {
  ChartConfig,
  ChartDataItens,
  LegendConfig,
  ThemeGraphic,
} from '../../../../components/Common/BarLineGraphic/types';
import { CostTrendPayloadItem, DataConstTrendItem } from './types';
import * as S from './styles';
import { BarlineGraphic } from '../../../../components/Common/BarLineGraphic';
import { dataType } from '../types';
import theme from '../../../../styles/theme';

export const useCostTrend = () => {
  const [open, setOpen] = useState(true);
  const [dataChart, setDataChart] = useState<Array<CostTrendPayloadItem>>([]);
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [isLoadingDataGraphic, setIsLoadingDataGraphic] = useState<boolean>(false);

  const initialParams = {
    category: null,
  };

  const configChart: ChartConfig = {
    dataKeyBar: 'barValue',
    dataKeyLine: 'lineValue',
    labelColumn: 'labelColumn',
  };

  const themesGraphic: Array<ThemeGraphic> = [
    {
      colorCurrentMonth: theme.colorsDesignSystem.graphicThemeColors.aquaCurrentMonths,
      colorOthersMonths: theme.colorsDesignSystem.graphicThemeColors.aquaOtherMonths,
      colorLineChart: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
    },
    {
      colorCurrentMonth: theme.colorsDesignSystem.graphicThemeColors.blueCurrentMonths,
      colorOthersMonths: theme.colorsDesignSystem.graphicThemeColors.blueOtherMonths,
      colorLineChart: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
    },
    {
      colorCurrentMonth: theme.colorsDesignSystem.graphicThemeColors.orangeCurrentMonth,
      colorOthersMonths: theme.colorsDesignSystem.graphicThemeColors.orangeOthersMonth,
      colorLineChart: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
    },
  ];

  const allLabels: Array<Array<LegendConfig>> = [
    [
      {
        text: 'Average',
        color: theme.colorsDesignSystem.graphicThemeColors.aquaCurrentMonths,
      },
      {
        text: 'Price Book',
        color: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
      },
    ],
    [
      {
        text: 'Average',
        color: theme.colorsDesignSystem.graphicThemeColors.blueCurrentMonths,
      },
      {
        text: 'Price Book',
        color: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
      },
    ],
    [
      {
        text: 'Average',
        color: theme.colorsDesignSystem.graphicThemeColors.orangeCurrentMonth,
      },
      {
        text: 'Price Book',
        color: theme.colorsDesignSystem.graphicThemeColors.blueLineChart,
      },
    ],
  ];

  const [params, setParams] = useState(initialParams);

  const updateParams = (field: string, value: any) => {
    setParams((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleGenerateDateForFetchFilters = (date: dayjs.Dayjs): string => {
    const lastMonth = dayjs(date).subtract(1, 'month').format('MMMM/YYYY').toUpperCase();
    const nextMonth = dayjs(date).add(1, 'month').format('MMMM/YYYY').toUpperCase();
    const currentMonth = dayjs(date).format('MMMM/YYYY').toUpperCase();
    return `${lastMonth},${currentMonth},${nextMonth}`;
  };

  const handleConvertSkusToString = (skus: Array<dataType>): string => {
    return skus.map((skuFull) => skuFull.sku).join(',');
  };

  const handleConvertCategoriesDataToStyledSelectOptions = (
    data: Array<string>
  ): Array<StyledSelectOptions> => {
    return data.map((category) => {
      return {
        label: category,
        value: category,
      };
    });
  };

  const handleFetchFiltersData = async (
    cm: string | undefined,
    level: string | undefined,
    date: dayjs.Dayjs | undefined,
    skus: Array<dataType> | undefined
  ) => {
    try {
      if (level && date && skus) {
        const { data, status } = await api.get('dashboard/finance/skucomponents/category', {
          params: {
            cm: cm === '' ? 'null' : cm,
            date: handleGenerateDateForFetchFilters(date),
            level,
            skus: handleConvertSkusToString(skus),
          },
        });

        if (status === 200) {
          data && setCategories(handleConvertCategoriesDataToStyledSelectOptions(data));
          data.length === 0 ? updateParams('category', null) : updateParams('category', data[0]);
        } else {
          setCategories([]);
          updateParams('category', null);
        }
        return;
      }

      setCategories([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const handleRenderTitleCard = (selectedSkus: Array<string>, date: dayjs.Dayjs | undefined) => {
    return (
      <Row style={{ gap: '0.188rem' }}>
        {selectedSkus.map((sku, index) => {
          return selectedSkus.length === index + 1 ? (
            <S.BlueText>{sku}</S.BlueText>
          ) : (
            <>
              <S.BlueText>{sku}</S.BlueText>
              <span style={{ marginInline: '0.313rem' }}>X</span>
            </>
          );
        })}
        <span>
          <b> {`| Quarter ${dayjs(date).format('Q')} |`}</b>
        </span>
        <S.BlueText style={{ marginInline: '0.313rem' }}>{` ${dayjs(date).format(
          'MMM YY'
        )}`}</S.BlueText>
      </Row>
    );
  };

  const handleFetchData = async (
    cm: string | undefined,
    level: string | undefined,
    date: dayjs.Dayjs | undefined,
    skus: Array<dataType> | undefined
  ) => {
    try {
      if (level && date && skus && params.category) {
        setIsLoadingDataGraphic(true);
        setDataChart([]);
        const { data, status } = await api.get('dashboard/finance/costtrend', {
          params: {
            cm,
            date: handleGenerateDateForFetchFilters(date),
            level,
            skus: handleConvertSkusToString(skus),
            category: params.category,
            period: dayjs(date).format('MMMM/YYYY').toUpperCase(),
          },
        });
        if (status === 200) {
          data && setDataChart(data);
        } else {
          setDataChart([]);
        }
        return;
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoadingDataGraphic(false);
    }
  };

  const handleConvertDataReceivedInChartDataItens = (
    dataReceived: DataConstTrendItem
  ): Array<ChartDataItens> => {
    const dataConverted: Array<ChartDataItens> = [
      {
        labelColumn: dataReceived?.month1 || t('common.emptyTooltip'),
        barValue: dataReceived?.month1AverageValue || 0,
        lineValue: dataReceived?.month1PriceBookValue || 0,
      },
      {
        labelColumn: dataReceived?.month2 || t('common.emptyTooltip'),
        barValue: dataReceived?.month2AverageValue || 0,
        lineValue: dataReceived?.month2PriceBookValue || 0,
      },
      {
        labelColumn: dataReceived?.month3 || t('common.emptyTooltip'),
        barValue: dataReceived?.month3AverageValue || 0,
        lineValue: dataReceived?.month3PriceBookValue || 0,
      },
    ];

    return dataConverted;
  };

  const handleRenderGraphic = (selectedSkus: Array<string>) => {
    return dataChart.length === 0
      ? selectedSkus.map((sku) => {
          return (
            <S.GraphicsContainer key={sku}>
              <S.TitleCardContainer>
                <Row>
                  <S.PrimaryTitle>{sku}</S.PrimaryTitle>
                </Row>
                <Empty />
              </S.TitleCardContainer>
            </S.GraphicsContainer>
          );
        })
      : dataChart.map((data, index) => {
          return (
            <BarlineGraphic
              key={data.sku}
              themeGraphic={themesGraphic[index]}
              labels={allLabels[index]}
              showTooltip
              titleChart={data.sku}
              subTitleChart={data.component.replace('[', ' ').replace(']', '')}
              toolTip={
                data.hintSkuComponent ? data.hintSkuComponent.join(', ') : t('common.emptyTooltip')
              }
              config={configChart}
              chartData={handleConvertDataReceivedInChartDataItens(data.values[0])}
            />
          );
        });
  };

  return {
    open,
    params,
    categories,
    setOpen,
    updateParams,
    handleFetchData,
    handleRenderGraphic,
    handleRenderTitleCard,
    handleFetchFiltersData,
    isLoadingDataGraphic,
  };
};

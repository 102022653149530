import { Popconfirm, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import { FileOutlined, SyncOutlined } from '@ant-design/icons';
import * as S from './styles';

import { TableActionButton } from '../../components/Button/styles';
import {
  UploadsFilesParams,
  ColumnsTypes,
  FilterData,
  UploadSheetData,
  UploadedFiles,
} from './types';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import StyledStatusIcon from '../../components/Common/StyledStatusIcon';
import { LinkTable } from './styles';
import { api } from '../../services/api';
import {
  getFilterURL,
  oneAlert,
  removeEmptyFilter,
  getErrorMessage,
  getSortOrder,
} from '../../helpers/utils';
import { Months, ListTypeOptions } from '../../helpers/enums';

export function useUplodFiles() {
  const initialParams = {
    cm: '',
    date: '',
    responsible: '',
    listType: '',
    page: 0,
    limit: 10,
  } as UploadsFilesParams;
  let dataSearch = {
    key: '',
    value: '',
  } as FilterData;

  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
  });

  const checkBoxInitialOptions = ['Compal', 'Foxconn'];

  const { t } = useTranslation();
  const [initialModal, setInitialModal] = useState(initialParams);
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [filterListType, setFilterListType] = useState<any>();
  const [filterResponsible, setfilterResponsible] = useState('');
  const [filterStatus, setFilterStatus] = useState<any>();
  const [checkBoxValue, setCheckBoxValue] = useState(checkBoxInitialOptions);
  const [loading, setLoading] = useState(false);
  const [isTableActionsVisible, setIsTableActionsVisible] = useState({
    rowId: '',
    state: false,
  });
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isPollingEnable, setIsPollingEnable] = useState(true);
  const [processingIds, setPorcessingIds] = useState<string[]>([]);
  const timeId = useRef<ReturnType<typeof setInterval> | null>(null);

  const ProcessingErrorTitle = [
    t('pages.finance.uploadFiles.layoutErrorTitle'),
    t('pages.finance.uploadFiles.formatErrorTitle'),
  ];

  const ProcessingErrorBody = [
    t('pages.finance.uploadFiles.layoutErrorBody'),
    t('pages.finance.uploadFiles.formatErrorBody'),
  ];

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: String(page) });
    } else {
      setPageControl({ ...pageControl, pageLabel: String(page) });
    }
  };

  const [file, setFile] = useState<any>();
  const MAX_FILE_SIZE = 10 * 1024 * 1024;

  const formatDate = (data: any) => {
    const newFormatDate = data.map((response: any) => {
      const firstLetter = response.date.charAt(0);
      const dateFormat = response.date.slice(1).toLowerCase();
      const ndate = `${firstLetter}${dateFormat}`;
      return {
        ...response,
        date: dayjs(ndate, 'MMMM/YYYY', 'en', true),
        uploadDate: dayjs(response.uploadDate),
      };
    });
    return newFormatDate;
  };

  /**
   *
   * @returns params without empty values
   */
  const ignoreEmptyParams = (parameter: any) => {
    const updatedParams: any = parameter;

    const keys = Object.keys(updatedParams);
    const paramsWithoutEmpty: any = {};
    keys.forEach((key) => {
      if (updatedParams[key] !== '') {
        paramsWithoutEmpty[key] = updatedParams[key];
      }
    });
    return paramsWithoutEmpty;
  };
  const fetchFiles = async () => {
    setIsTableLoading(true);
    setIsPollingEnable(false);
    try {
      const paramsDateFormat = params.date
        ? {
            ...params,
            date: params.date?.format('MMMM/YYYY').toUpperCase(),
          }
        : params;
      const newParams = ignoreEmptyParams(paramsDateFormat);
      const { data } = await api.get('fileupload/filter', {
        params: newParams,
      });

      const dataContent = data.content || [];

      setContent(formatDate(dataContent));
      setPageControl({
        pageLabel: String(params.page + 1),
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        pageSize: data.numberOfElements,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Error on the Fileupload fetch', error);
    } finally {
      setIsTableLoading(false);
      setIsPollingEnable(true);
    }
  };

  const deleteItem = async (itemId: string) => {
    setIsLoading(true);
    try {
      await api.delete(`fileupload/${itemId}`);

      await fetchFiles();
      oneAlert('success', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    } finally {
      setIsLoading(false);
    }
  };

  const uploadAlert = (title: string, msg: string) => {
    message.open({
      content: (
        <S.MessageContainer>
          <S.MessageIcon />
          <S.MessageText>
            <S.MessageTitle>{title}</S.MessageTitle>
            <S.MessageBody>{msg}</S.MessageBody>
          </S.MessageText>
        </S.MessageContainer>
      ),
      duration: 10,
    });
  };

  const handleCloseModal = () => {
    setInitialModal({
      ...params,
      cm: '',
      listType: '',
      date: '',
      responsible: '',
    });
    setFile(null);
    setShowModal(false);
  };
  const formatDatePost = (date: string) => {
    const splitDate = date?.split('/');
    const newDate = `${Months[Number(splitDate[0])]}/${splitDate[1]}`;
    return newDate;
  };

  const handleValidateMaxFileSize = (file: any): boolean => {
    return file.size > MAX_FILE_SIZE;
  };

  const handleGetMaxSizeFileInMb = () => {
    return MAX_FILE_SIZE / 1024 / 1024;
  };

  const uploadFile = async (dataUpload: UploadSheetData) => {
    setLoading(true);
    setIsPollingEnable(false);

    dataUpload.params.listType = ListTypeOptions[dataUpload.params.listType];
    dataUpload.params.date = formatDatePost(dataUpload.params.date);

    const formData = new FormData();
    formData.append('file', file);

    const valueUpload = {
      ...dataUpload.params,
    };

    if (!file) {
      message.error(t('components.modals.uploadSheets.dragAndDrop.requiredFile'));
      setLoading(false);
      return;
    }

    if (handleValidateMaxFileSize(file)) {
      message.error(
        t('components.modals.uploadSheets.dragAndDrop.maxFileSizeError', {
          maxSize: `${handleGetMaxSizeFileInMb()}MB`,
        })
      );
      setLoading(false);
      return;
    }

    try {
      const queryParams = getFilterURL(removeEmptyFilter(valueUpload));
      const response = await api.post(`fileupload?${queryParams}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        uploadAlert(t('toast.uploadSuccessTitle'), t('toast.uploadSuccessBody'));
        handleCloseModal();
        fetchFiles();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnUpload'));
    } finally {
      setLoading(false);
      setIsPollingEnable(true);
    }
  };

  const filterData = (value: FilterData) => {
    const dataFilter = value;
    let filterParams = { ...params };
    if (dataFilter.key === 'listType' && dataFilter.value !== '') {
      filterParams = {
        ...params,
        page: 0,
        listType: dataFilter.value,
        tag_listType: dataFilter.value,
      };
      setParams(filterParams);
      return;
    }
    if (dataFilter.key === 'responsible' && dataFilter.value !== '') {
      filterParams = {
        ...params,
        page: 0,
        responsible: dataFilter.value,
        tag_responsible: dataFilter.value,
      };
      setfilterResponsible('');
      setParams(filterParams);
      return;
    }
    if (dataFilter.key === 'cm') {
      filterParams = {
        ...params,
        page: 0,
        cm: dataFilter.value,
      };
      setParams(filterParams);
      return;
    }
    if (dataFilter.key === 'date' && dataFilter.value !== '') {
      filterParams = {
        ...params,
        page: 0,
        date: dataFilter.value,
        tag_date: dataFilter.value.format('MM/YYYY'),
      };
      setParams(filterParams);
      return;
    }
    if (dataFilter.key === 'status' && dataFilter.value !== '') {
      filterParams = {
        ...params,
        page: 0,
        status: dataFilter.value,
        tag_status: dataFilter.value,
      };
      setFilterStatus(null);
      setParams(filterParams);
    }
  };
  const handleChangeFilter = (key: string, inputValue: string) => {
    if (key === 'listType') {
      setFilterListType(inputValue);
      return;
    }
    if (key === 'responsible') {
      setfilterResponsible(inputValue);
      return;
    }
    if (key === 'status') {
      setFilterStatus(inputValue);
    }
    setParams({ ...params, page: 0 });
  };

  const handleChangeDate = (date: dayjs.Dayjs) => {
    dataSearch = {
      key: 'date',
      value: date,
    };
    filterData(dataSearch);
  };

  const handleSearch = (key: string, value: string) => {
    dataSearch = {
      key,
      value,
    };
    filterData(dataSearch);
  };
  const clearFilters = () => {
    const clearParams = {
      ...params,
      date: '',
      responsible: '',
      listType: '',
      status: '',
      tag_listType: '',
      tag_date: '',
      tag_responsible: '',
      tag_status: '',
    };
    setFilterListType(null);
    setFilterStatus(null);
    setParams(clearParams);
  };

  const closeTag = (value: FilterData) => {
    const dataTag = value;
    let closeTagParams = { ...params };
    if (dataTag.key === 'listType' && dataTag.value === '') {
      closeTagParams = {
        ...params,
        listType: dataTag.value,
        tag_listType: dataTag.value,
      };
      setFilterListType(null);
      setParams(closeTagParams);
      return;
    }
    if (dataTag.key === 'responsible' && dataTag.value === '') {
      closeTagParams = {
        ...params,
        responsible: dataTag.value,
        tag_responsible: dataTag.value,
      };
      setParams(closeTagParams);
      return;
    }
    if (dataTag.key === 'date' && dataTag.value === '') {
      closeTagParams = {
        ...params,
        date: dataTag.value,
        tag_date: dataTag.value,
      };
      setParams(closeTagParams);
      return;
    }
    if (dataTag.key === 'status' && dataTag.value === '') {
      closeTagParams = {
        ...params,
        status: dataTag.value,
        tag_status: dataTag.value,
      };
      setFilterStatus(null);
      setParams(closeTagParams);
    }
  };

  const handleCloseTag = (key: string, value: string) => {
    dataSearch = {
      key,
      value,
    };
    closeTag(dataSearch);
  };

  const newUpload = () => {
    setShowModal(true);
  };

  const uploadAgain = (record: UploadsFilesParams) => {
    setShowModal(true);

    setInitialModal({
      ...params,
      cm: record.cm || '',
      listType: record.listType || '',
      date: record.date || '',
      responsible: record.responsible || '',
    });
  };

  const handleChangeCheckbox = (value: Array<string>) => {
    switch (value.length) {
      case 1:
        dataSearch = {
          key: 'cm',
          value: value[0],
        };
        filterData(dataSearch);
        setCheckBoxValue(value);
        break;
      case 2:
        dataSearch = {
          key: 'cm',
          value: '',
        };
        filterData(dataSearch);
        setCheckBoxValue(['Foxconn', 'Compal']);
        break;
      default:
        setCheckBoxValue(checkBoxInitialOptions);
        break;
    }
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: String(page), pageSize });
  };

  /**
   *  Handle the change of the table, like pagination, filters and sorter
   * @param pagination number of page if has one
   * @param filters filter if has one on the table header
   * @param sorter object with sorter details, like ASC or column key
   */
  const handleChangeTable = async (pagination: number, filters: any, sorter: any) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
    });
  };

  const columns: ColumnsType<UploadedFiles> = [
    {
      title: t('pages.finance.uploadFiles.fields.cm'),
      dataIndex: 'cm',
      key: 'name',
      width: '1rem',
      sorter: true,
      render: (_, record) => {
        if (record.cm === 'ambos') {
          return `${t(`components.modals.uploadSheets.CmOption`)}`;
        }
        return record.cm;
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.listType'),
      dataIndex: 'listType',
      key: 'listType',
      width: '10rem',
      sorter: true,
      render: (_, record) => {
        if (record.listType === 'Forecast') return 'Demands';
        if (record.listType === 'Packaging Prices') return 'Packing Prices';
        return record.listType;
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.period'),
      dataIndex: 'date',
      key: 'date',
      width: '2rem',
      sorter: true,
      render: (_, record) => {
        return record.date.format('MM/YYYY');
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.importedDate'),
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      width: '8rem',
      sorter: true,
      render: (_, record) => {
        return record.uploadDate.format(t('common.dateFormat'));
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.hour'),
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      width: '4.7rem',
      sorter: false,
      render: (_, record) => {
        return record.uploadDate.format('HH:mm A');
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.responsible'),
      dataIndex: 'responsible',
      key: 'responsible',
      width: '1rem',
      sorter: true,
    },
    {
      title: t('pages.finance.uploadFiles.fields.uploadFile'),
      dataIndex: 'fileName',
      align: 'left',
      key: 'fileName',
      sorter: false,
      width: '21rem',
      render: (_, record) => {
        return (
          <a href={record.pathFileS3}>
            <LinkTable>
              <FileOutlined />
              {record.fileName}
            </LinkTable>
          </a>
        );
      },
    },
    {
      title: t('pages.finance.uploadFiles.fields.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      align: 'center',
      width: '4rem',
      render: (_, record) => {
        if (record.status.message === 'success') {
          return (
            <StyledStatusIcon
              arrow={false}
              placement="bottomRight"
              module="finance"
              Fstatus={record.status.message}
              title={t('pages.finance.uploadFiles.fileSuccessTitle')}
              content={t('pages.finance.uploadFiles.fileSuccessTBody')}
            />
          );
        }
        if (record.status.message === 'processing') {
          return (
            <StyledStatusIcon
              arrow={false}
              placement="bottomRight"
              module="finance"
              title={t('pages.finance.uploadFiles.fileProcessingTitle')}
              Fstatus={record.status.message}
              content={t('pages.finance.uploadFiles.fileProcessingBody')}
            />
          );
        }
        if (record.status.message === 'error') {
          if (record.status.detail === 'layout') {
            return (
              <StyledStatusIcon
                arrow={false}
                placement="bottomRight"
                module="finance"
                title={ProcessingErrorTitle[0]}
                Fstatus={record.status.message}
                content={ProcessingErrorBody[0]}
              />
            );
          }
          if (record.status.detail === 'format') {
            return (
              <StyledStatusIcon
                arrow={false}
                placement="bottomRight"
                module="finance"
                title={ProcessingErrorTitle[1]}
                Fstatus={record.status.message}
                content={ProcessingErrorBody[1]}
              />
            );
          }
        }
      },
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'actions',
      width: '4rem',
      placement: 'bottom',

      render: (_: any, record: any) => {
        const actions = [
          {
            key: '1',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onClick={(e) => e.stopPropagation()}
                placement="bottom"
                onConfirm={() => deleteItem(record.id)}
              >
                <Button type="link" danger data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];
        if (record?.status.message === 'error') {
          actions.push({
            key: '2',
            label: (
              <TableActionButton
                type="text"
                onClick={() => uploadAgain(record)}
                data-cy="Upload_Again"
              >
                {t('pages.finance.uploadFiles.uploadAgain')}
              </TableActionButton>
            ),
          });
        }

        return (
          <TableAction
            placement="bottom"
            dropdownItems={actions}
            onVisibleChange={(visible: any) =>
              setIsTableActionsVisible({ rowId: record.id, state: visible })
            }
            visible={isTableActionsVisible.rowId === record.id && isTableActionsVisible.state}
          />
        );
      },
    },
  ];

  const pollingData = async () => {
    const { data: uploadData } = await api.get<{ id: string; statusMessage: string }[]>(
      `/fileupload/processing`
    );
    const ids = uploadData.map((data) => data.id);

    const verifyCurrentIds = processingIds.every((data) => ids.includes(data));

    setPorcessingIds(ids);

    if (!verifyCurrentIds) {
      fetchFiles();
    }
  };

  const startPolling = () => {
    pollingData();
    timeId.current = setInterval(pollingData, 10000);
  };

  const stopPolling = () => {
    clearInterval(timeId.current as ReturnType<typeof setInterval>);
  };

  const updatePolling = () => {
    if (isPollingEnable) {
      startPolling();
    } else {
      stopPolling();
    }
  };

  useEffect(() => {
    updatePolling();
    return () => {
      stopPolling();
    };
  }, [content, isPollingEnable]);

  return {
    columnsWithAction,
    isLoading,
    content,
    params,
    filterListType,
    filterResponsible,
    filterStatus,
    initialModal,
    showModal,
    checkBoxInitialOptions,
    checkBoxValue,
    loading,
    pageControl,
    isTableLoading,

    handleChangeTable,
    fetchFiles,
    clearFilters,
    handleChangeFilter,
    handleSearch,
    handleCloseTag,
    handleCloseModal,
    handleChangeCheckbox,
    newUpload,
    handleChangeDate,
    setCheckBoxValue,
    uploadFile,
    setFile,
    changePageValue,
    changePage,
    // statusProcessing,
  };
}

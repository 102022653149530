import { HeaderCardProps } from './types';
import * as S from './styles';

export default function HeaderCard({ cbom, componentName }: HeaderCardProps) {
  return (
    <S.Container>
      <S.CbomLabel>{`${cbom} |`}</S.CbomLabel>
      <S.ComponentName>{componentName}</S.ComponentName>
    </S.Container>
  );
}

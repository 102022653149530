import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../services/api';
import { oneAlert } from '../../../../helpers/utils';
import { dataType } from '../types';
import { DataGraphicProps } from './types';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';

export const useStartvsEnd = (
  dateProps: dayjs.Dayjs,
  skusProps: dataType[],
  level: string,
  cm?: string
) => {
  const { t } = useTranslation();
  const [data, setData] = useState<DataGraphicProps[]>();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [params, setParams] = useState({
    category: '',
  });
  const [isLoadingDataGraphic, setIsLoadingDataGraphic] = useState<boolean>(false);

  const colors = {
    endStock: '#DBDBDB',
  };

  const toogleView = () => {
    setIsOpen(!isOpen);
  };

  const updateDateByExternalPicker = (date: dayjs.Dayjs) => {
    return {
      date: [
        dayjs(date).subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
        dayjs(date).format('MMMM/YYYY').toUpperCase(),
        dayjs(date).add(1, 'month').format('MMMM/YYYY').toUpperCase(),
      ].join(','),
    };
  };

  const fetchCategories = async () => {
    try {
      const newDate = updateDateByExternalPicker(dateProps);
      const { data: categoriesData, status: categoriesStatus } = await api.get<string[]>(
        '/dashboard/finance/skucomponents/category',
        {
          params: {
            ...newDate,
            skus: skusProps.map((item) => item.sku).join(','),
            level,
            cm: cm === '' ? 'null' : cm,
          },
        }
      );
      if (categoriesStatus === 204) {
        setCategories([]);
        setParams({ ...params, category: '' });
      } else if (categoriesStatus === 200) {
        if (categoriesData.length > 0) {
          const formattedCategories = categoriesData.map((category) => {
            return {
              label: category,
              value: category,
            };
          });
          setParams({ ...params, category: formattedCategories[0].value });
          setCategories(formattedCategories);
        } else {
          setCategories([]);
          setParams({ ...params, category: '' });
        }
      }
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const formatValue = (value: string | number) => {
    const formattedValue = value.toString().replace(',', '');
    return Number(formattedValue);
  };

  const fetchData = async () => {
    try {
      setIsLoadingDataGraphic(true);
      setData([]);
      const newDate = updateDateByExternalPicker(dateProps);

      const { data: graphicData, status } = await api.get<DataGraphicProps[]>(
        '/dashboard/finance/startend',
        {
          params: {
            category: params.category,
            //Foxconn está fixo porque ainda não temos arquivo para estoque Compal
            //Quando existir, deve ser alterado para receber o CM do header principal
            cm: 'Foxconn',
            ...newDate,
            level,
            skus: skusProps.map((item) => item.sku).join(','),
          },
        }
      );
      if (status === 204) {
        setData([]);
      } else {
        const formttedGraphic: DataGraphicProps[] = graphicData.map((item) => {
          return {
            ...item,
            graphic: item.graphic.map((graphic) => {
              return {
                start: formatValue(graphic.start),
                end: formatValue(graphic.end),
                name: graphic.name,
              };
            }),
          };
        });
        setData(formttedGraphic);
      }
    } catch (error) {
      setData([]);
      oneAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingDataGraphic(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [dateProps, level, skusProps]);

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, category: '' };
    });
  }, [level]);

  useEffect(() => {
    params.category && fetchData();
  }, [dateProps, level, skusProps, params]);

  return {
    data,
    fetchData,
    isOpen,
    toogleView,
    colors,
    categories,
    setParams,
    params,
    isLoadingDataGraphic,
  };
};

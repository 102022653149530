import { useState } from 'react';
// import { StepProps } from 'antd';
import {
  FlagOutlined,
  FolderViewOutlined,
  LockOutlined,
  SendOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { oneAlert } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { BidSupplierViewData, ITimeLine, StepsStatus } from './types';

export const useBidSupplierView = () => {
  const initialSteps: StepsStatus[] = [
    {
      title: 'Open Bid',
      status: 'wait',
      icon: <UnlockOutlined />,
      key: 'OPEN',
    },
    {
      title: 'Round Open',
      status: 'wait',
      icon: <FlagOutlined />,
      key: 'ROUND_OPEN',
    },
    {
      title: 'Quotation Sent',
      status: 'wait',
      icon: <SendOutlined />,
      key: 'QUOTATION_SENT',
    },
    {
      title: 'Bid Closed',
      status: 'wait',
      icon: <LockOutlined />,
      key: 'CLOSED',
    },
    {
      title: 'In Review',
      status: 'wait',
      icon: <FolderViewOutlined />,
      key: 'IN_REVIEW',
    },
    {
      title: 'Result',
      status: 'wait',
      icon: <TrophyOutlined />,
      key: 'CONCLUDED',
    },
  ];

  const { t } = useTranslation();
  const [idBid, setIdBid] = useState<string>('');
  const [bidOptions, setBidOption] = useState<StyledSelectOptions[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [bidData, setBidData] = useState<BidSupplierViewData>({
    rfq: '',
    supplier: '',
    user: '',
    potentialRevenue: '',
    respondDate: '',
    itemsQuotation: [],
  });
  const [steps, setSteps] = useState<StepsStatus[]>(initialSteps);

  const GetSupplierUserName = () => {
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
      const userToken: any = jwtDecode(idToken);
      return {
        user: userToken.nickname,
        supplier: userToken['custom:empresa'],
      };
    }
  };

  const formatIdOptions = (data: Array<any>) => {
    if (data.length > 0) {
      const formattedData: StyledSelectOptions[] = data.map((item) => {
        return {
          label: item.bidName,
          value: item.id,
        };
      });

      return formattedData;
    }
    return [];
  };

  const getBidId = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/filter`, {
          supplier: GetSupplierUserName()?.supplier || '',
          allowSupplierView: true,
        })
      );
      if (status === 200) {
        setBidOption(formatIdOptions(data?.content));
        setIdBid(data?.content[0].id);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateStatus = (data: ITimeLine[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = steps.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateTime).format('MM.DD.YYYY')} - {dayjs(item.dateTime).format('HH:mm')}
            </span>
          </div>
        );
      }
    });

    setSteps(stepsCopy);
  };

  const FetchStatus = async () => {
    if (!idBid) return;
    setIsLoading(true);
    try {
      const { data, status }: { data: Array<ITimeLine>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/status`, {
          idBid,
          nameSupplier: GetSupplierUserName()?.supplier || '',
        })
      );

      if (status === 200) {
        updateStatus(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const FetchData = async () => {
    if (!idBid) return;
    setIsLoading(true);
    try {
      const { data, status }: { data: BidSupplierViewData; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/${idBid}/supplier`)
      );

      if (status === 200) {
        setBidData(data);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    FetchStatus,
    updateStatus,
    getBidId,
    FetchData,
    setIdBid,
    GetSupplierUserName,
  };
};

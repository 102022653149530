import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { useToggle } from '../../../hooks/useToggle';
import {
  PageData,
  Project,
  ProjectResponse,
  ShareProportionsResponse,
  VerticalBarGraphType,
} from './types';
import { api } from '../../../services/api';
import {
  formatDecimalPlaces,
  formatScoreData,
  getErrorMessage,
  getURI,
  oneAlert,
} from '../../../helpers/utils';
import { FormattedData, TableDataType } from '../types';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import { StyledSelectOptions } from '../../ComponentsPrices/PricePage/types';

const INITIAL_VALUES: PageData = {
  tableScore: [],
  shareProportionsGraphData: [],
  verticalBarChartData: [],
};

export function useDashboardSKU() {
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();

  const INITIAL_PARAMS = {
    limit: 5,
    projectName: '',
    targetYear: localTargetYear,
  };

  const years: Array<StyledSelectOptions> = [
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ];

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(INITIAL_VALUES);
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [selectedProject, setSelectedProject] = useState<TableDataType>();
  const [sliderValue, setSliderValue] = useState(INITIAL_PARAMS.limit);
  const [projects, setProjects] = useState<Project[]>([]);

  function handleSelectProject(project: any) {
    const projectData: TableDataType = formatScoreData(project);
    setSelectedProject(projectData);
    toggleModal();
  }

  function generateLabel(quarter: string, total: number) {
    if (total > 0) {
      return `${quarter} ${total}`;
    }

    return quarter;
  }

  function formatShareProportionsData(graphData: ShareProportionsResponse[]) {
    if (!graphData) return [];
    const formatted = graphData.map((item) => {
      const { q1, q2, q3, q4 } = item;

      return {
        ...item,
        name: item.sku,
        q1: {
          ...q1,
          label: 'Q1',
          occurredPercentage: formatDecimalPlaces(q1.occurredPercentage),
          forecastPercentage: formatDecimalPlaces(q1.forecastPercentage),
        },
        q2: {
          ...q2,
          label: 'Q2',
          occurredPercentage: formatDecimalPlaces(q2.occurredPercentage),
          forecastPercentage: formatDecimalPlaces(q2.forecastPercentage),
        },
        q3: {
          ...q3,
          label: 'Q3',
          occurredPercentage: formatDecimalPlaces(q3.occurredPercentage),
          forecastPercentage: formatDecimalPlaces(q3.forecastPercentage),
        },
        q4: {
          ...q4,
          label: 'Q4',
          occurredPercentage: formatDecimalPlaces(q4.occurredPercentage),
          forecastPercentage: formatDecimalPlaces(q4.forecastPercentage),
        },
      };
    });

    return formatted.splice(0, 5);
  }

  async function fetchData() {
    try {
      setIsLoading(true);

      const [projectList] = await Promise.all([
        api.get<ProjectResponse[]>('/dashboard/projectnames'),
      ]);

      const formattedProjects = projectList.data.map((item, index) => ({
        key: `${item.project}-${index}`,
        value: item.project,
        label: item.project,
      }));

      setProjects([
        { value: '', label: `${t('common.all')} ${t('pages.dashboard.fields.projects')}` },
        ...formattedProjects,
      ]);

      const [verticalBarData, shareProportionData, tableScoreData] = await Promise.all([
        api.get<VerticalBarGraphType[]>(getURI(`/dashboard/findSkuForecastOccurred`, params)),
        api.get(getURI('/dashboard/findDashboardShareProportionSku', params)),
        api.get(getURI('/dashboard/findDashboardForecastSku', params)),
      ]);

      const apiData = {
        verticalBarChartData: verticalBarData.data || [],
        shareProportionsGraphData: formatShareProportionsData(shareProportionData.data),
        tableScore: tableScoreData.data,
      };

      setData(apiData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnList'));
    }
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  function updateSliderValue(value: number) {
    setParams((oldParams) => ({ ...oldParams, limit: value }));
  }

  function handleFilterProject(project: string) {
    setParams((oldParams) => ({ ...oldParams, projectName: project }));
  }

  function handleFilterDate(targetYear: string) {
    setParams((oldParams) => ({ ...oldParams, targetYear }));
  }

  const columns: ColumnsType<FormattedData> = [
    {
      title: t('pages.dashboard.fields.projectName'),
      dataIndex: 'projectName',
      key: 'project name',
      render: (projectName, record) => {
        const isActive = !record.finished;

        return (
          <S.ProjectName
            $isActive={isActive}
            {...(isActive ? { onClick: () => handleSelectProject(record) } : {})}
          >
            {projectName}
          </S.ProjectName>
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: t('pages.dashboard.fields.modelName'),
      dataIndex: 'modelName',
      key: 'model name',
    },
    {
      title: t('pages.dashboard.fields.demands'),
      dataIndex: 'forecast',
      children: [
        {
          title: t('common.months.january'),
          dataIndex: ['january', 'skuForecast'],
          key: 'january.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.january.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.february'),
          dataIndex: ['february', 'skuForecast'],
          key: 'february.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.february.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.march'),
          dataIndex: ['march', 'skuForecast'],
          key: 'march.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.march.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.april'),
          dataIndex: ['april', 'skuForecast'],
          key: 'april.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.april.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.may'),
          dataIndex: ['may', 'skuForecast'],
          key: 'may.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.may.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.june'),
          dataIndex: ['june', 'skuForecast'],
          key: 'june.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.june.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.july'),
          dataIndex: ['july', 'skuForecast'],
          key: 'july.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.july.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.august'),
          dataIndex: ['august', 'skuForecast'],
          key: 'august.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.august.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.september'),
          dataIndex: ['september', 'skuForecast'],
          key: 'september.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.september.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.october'),
          dataIndex: ['october', 'skuForecast'],
          key: 'october.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.october.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.november'),
          dataIndex: ['november', 'skuForecast'],
          key: 'november.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.november.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.months.december'),
          dataIndex: ['december', 'skuForecast'],
          key: 'december.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.december.skuForecast, 0, 2)}</span>;
          },
        },
        {
          title: t('common.total'),
          dataIndex: ['total', 'skuForecast'],
          key: 'total.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return <span>{handleFormatValueFields(record.total.skuForecast, 0, 2)}</span>;
          },
        },
      ],
    },
  ];

  return {
    isLoading,
    data,
    params,
    columns,
    isModalOpen,
    selectedProject,
    sliderValue,
    projects,
    years,
    generateLabel,
    toggleModal,
    setSelectedProject,
    handleSelectProject,
    setSliderValue,
    updateSliderValue,
    handleFilterProject,
    handleFilterDate,
  };
}
